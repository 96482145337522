import React, { useEffect, useState, useCallback} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
  Button, CircularProgress, IconButton, TextField, Dialog,
  DialogActions, DialogContent, DialogContentText, DialogTitle,
  Typography, Snackbar, Alert, Tab, Tabs, Badge, Box
} from '@mui/material';
import {
  Edit as EditIcon, Delete as DeleteIcon,
  Save as SaveIcon, Check as CheckIcon, Refresh as RefreshIcon
} from '@mui/icons-material';
import SmartToyOutlinedIcon from '@mui/icons-material/SmartToyOutlined';
import AutosaveIndicator from './AutoSaveIndicator';
import useSaveOperation from './useSaveOperation';
import {
  fetchSectionSpecificRisks, discardRisk,
  moveRiskToSaved, updateSavedRisk, discardSavedRisk, updateRiskName, fetchNewRisks
} from './redux/actions/riskActions';
//fetchRisksForModel


const RiskCatalogue = ({ modelId, sectionName, assessmentUuid, narratives }) => {
  const dispatch = useDispatch();
  const risks = useSelector(state => state.risks.models[modelId]?.riskFormData[sectionName]?.sectionRisks || []);
  const [editingRisk, setEditingRisk] = useState(null);
  const [editedRiskName, setEditedRiskName] = useState('');
  const [confirmDiscardRisk, setConfirmDiscardRisk] = useState(null);
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('info');
  const { isSaving, isSaved, handleSaveOperation, setIsSaved } = useSaveOperation();
  const [selectedRiskTab, setSelectedRiskTab] = useState(0); 
  const sectionData = useSelector(state => state.risks.models[modelId]?.riskFormData[sectionName] || {});
  

  
  useEffect(() => {
    // Check if there are any risks already loaded for the section
    const hasRisks = risks.length > 0;
        // Fetch risks only if there are no existing risks for this section
    if (modelId && sectionName && !hasRisks  ) {
      setLoading(true);
      dispatch(fetchSectionSpecificRisks(modelId, sectionName, sectionData, narratives))
   
        .finally(() => setLoading(false));
    }
  

  }, [dispatch, modelId, sectionName, sectionData,risks.length, narratives]); 

  

  const handleSaveRisk = useCallback(async (risk) => {
    if (!risk.riskId) {
      handleSaveOperation(async () => {
        const response = await fetch('/.netlify/functions/saveRisk', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            name: risk.riskName,
            section: risk.section,
            modelUuid: modelId,
            assessmentUuid: assessmentUuid,
          }),
        });
        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.error || 'Failed to save risk');
        }
        const savedRisk = await response.json();
        dispatch(moveRiskToSaved(modelId, sectionName, { ...risk, riskId: savedRisk.riskId, status: 'Saved' }));
      }).catch(error => {
        console.error('Failed to save risk:', error);
        setSnackbarMessage(error.message);
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      });
    }
  }, [handleSaveOperation, dispatch, modelId, sectionName, assessmentUuid]);


  const handleDiscardRisk = useCallback(async (risk) => {
    console.log ('risk to discard', risk)
    if (risk.riskId) {
      // Risk is saved, so we need to remove it from the database
      const response = await fetch('/.netlify/functions/discardRisk', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ riskId: risk.riskId }),
      });
      if (!response.ok) {
        throw new Error('Failed to discard the risk');
      }
      dispatch(discardSavedRisk(risk.riskId, modelId, sectionName));
    } else {
      // Risk is not saved, just update the state
      dispatch(discardRisk(risk, modelId, sectionName));
    }
  }, [dispatch, modelId, sectionName]);

  const handleEditRisk = useCallback((risk) => {
    console.log('Initiating edit for risk:', risk);
    setEditingRisk(risk);
    setEditedRiskName(risk.name);
}, []);

const handleConfirmEdit = useCallback(async () => {
    console.log('Confirming edit for risk:', editingRisk);
    if (!editingRisk) return;

    // Based on the risk's status, decide whether to update the backend or just the local state
    console.log('Risk details found for edit:', editingRisk);
    handleSaveOperation(async () => {
        if (editingRisk.status === 'Saved') {
            // Risk is saved, update it in the backend
            const response = await fetch('/.netlify/functions/saveRisk', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    riskId: editingRisk.riskId,
                    name: editedRiskName,
                    section: sectionName,
                    modelUuid: modelId,
                    assessmentUuid: assessmentUuid,
                }),
            });
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || 'Failed to update risk');
            }
            // Update state with the new risk name
            dispatch(updateSavedRisk(modelId, editingRisk.riskId,sectionName, { ...editingRisk, name: editedRiskName }));
        } else {
            // Risk is generated, just update locally
            dispatch(updateRiskName(modelId, sectionName, editingRisk.name, editedRiskName));
        }
    }).catch(error => {
        console.error('Error updating risk:', error);
        setSnackbarMessage(error.message);
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
    }).finally(() => {
        setEditingRisk(null);
        setEditedRiskName('');
    });
}, [modelId, sectionName, editingRisk, editedRiskName, handleSaveOperation, dispatch, assessmentUuid]);

  
   
 
  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };



const handleRefreshRisks = useCallback(() => {
  const existingRisks = risks.map(risk => risk.name);
  console.log('existing risks to filter', existingRisks);
  setLoading(true);

  dispatch(fetchNewRisks(modelId, sectionName, sectionData, existingRisks))
    .then(() => {
      // Handle successful refresh
      console.log('Risks refreshed successfully.');
    })
    .catch(error => {
      // Handle errors if the action is set up to reject promises on failures
      console.error('Failed to refresh risks:', error);
      setSnackbarOpen(true);  // Assuming you have a Snackbar state set up for errors
      setSnackbarMessage(error.message || 'Failed to refresh risks.');
    })
    .finally(() => setLoading(false));
}, [dispatch, risks, modelId, sectionName, sectionData]);

  
  const toTitleCase = (str) => {
    // Split the string into words and handle camelCase by inserting spaces before capital letters
    return str
      .replace(/([A-Z])/g, ' $1') // Adds space before capital letters
      .replace(/([A-Z]+)/g, (match) => match.toUpperCase()) // Ensure each word is capitalized
      .trim()
      .split(/\s+/) // Split based on whitespace
      .map(word => word.charAt(0).toUpperCase() + word.substr(1).toLowerCase())
      .join(' '); // Join words back with space
  };
  

const renderRisksByStatus = (status) => {
  const filteredRisks = risks.filter(risk => risk.status === status);
  return filteredRisks.map(risk => (
      <TableRow key={risk.riskId || risk.riskName}>
          <TableCell component="th" scope="row">
              {editingRisk === risk ? (
                  <TextField
                      value={editedRiskName}
                      onChange={(e) => setEditedRiskName(e.target.value)}
                      fullWidth
                  />
              ) : (
                  risk.riskName
              )}
          </TableCell>
          <TableCell>{risk.status}</TableCell>
          <TableCell>
              {editingRisk === risk ? (
                  <IconButton onClick={handleConfirmEdit}><CheckIcon /></IconButton>
              ) : (
                  <>
                      <IconButton onClick={() => handleEditRisk(risk)}>
                          <EditIcon />
                      </IconButton>
                      <IconButton onClick={() => handleSaveRisk(risk)}>
                          <SaveIcon />
                      </IconButton>
                      <IconButton onClick={() => setConfirmDiscardRisk(risk)} style={{ color: 'red' }}>
                          <DeleteIcon />
                      </IconButton>
                  </>
              )}
          </TableCell>
      </TableRow>
  ));
};

  const countSavedRisks = () => {
    return risks.filter(risk => risk.status === 'Saved').length;
  };
  const countDiscardedRisks = () => {
    return risks.filter(risk => risk.status === 'Discarded').length;
  };
  
 
 return (
    
     <div style={{ margin: '16px' }}>
<Paper elevation={2} style={{ padding: '10px 20px', marginBottom: '16px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
  <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
    {/* Title and Autosave Indicator */}
    <Typography variant="h6">
      Risk Identification
    </Typography>
    <AutosaveIndicator isSaving={isSaving} isSaved={isSaved} setIsSaved={setIsSaved} />
  </div>
  {/* Refresh Risks Button and CircularProgress with Icon */}
  <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
    {risks.length > 0 && (
      <IconButton onClick={handleRefreshRisks}>
        <RefreshIcon />
      </IconButton>
    )}
    {loading && (
      <div style={{ position: 'relative', display: 'inline-flex' }}>
        <CircularProgress
          size={24}
          thickness={5}
          sx={{ color: 'primary.main' }}
        />
        <SmartToyOutlinedIcon
          style={{
            color: 'secondary.light',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            fontSize: '1rem'
          }}
        />
      </div>
    )}
  </div>
</Paper>

  <Tabs value={selectedRiskTab} onChange={(e, newValue) => setSelectedRiskTab(newValue)} aria-label="Risk Types">
  <Tab label={toTitleCase("Generated Risks")} />
  {/* <Tab label={toTitleCase("Saved Risks")} /> */}
  <Tab
    label={
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {toTitleCase("Saved Risks")}
        <Badge color="primary" badgeContent={countSavedRisks()} sx={{ ml: 2 }} />
      </Box>
    }
  />
  <Tab
    label={
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {toTitleCase("Discarded Risks")}
        <Badge badgeContent={countDiscardedRisks()} sx={{ ml: 2, '& .MuiBadge-badge': { backgroundColor: '#f44336' } }} />  {/* Using hex code for red color */}
      </Box>
    }
  />
</Tabs>


      <TableContainer component={Paper}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Risk Name</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          
            {!loading && (
              selectedRiskTab === 0 ? renderRisksByStatus('Generated') :
              selectedRiskTab === 1 ? renderRisksByStatus('Saved') :
              renderRisksByStatus('Discarded')
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={!!confirmDiscardRisk} onClose={() => setConfirmDiscardRisk(null)}>
        <DialogTitle>Confirm Discard</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure you want to discard this risk?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmDiscardRisk(null)}>Cancel</Button>
          <Button onClick={() => {
            if (confirmDiscardRisk) {
              handleDiscardRisk(confirmDiscardRisk);
              setConfirmDiscardRisk(null);
            }
          }}>Discard</Button>
        </DialogActions>
      </Dialog>

      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default RiskCatalogue;
