import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import { resetRisksState } from './redux/actions/riskActions.js';
import { resetControlsState } from './redux/actions/controlActions.js';
import { resetSecurityState } from './redux/actions/securityActions.js';
import { persistor } from './redux/store.js';

function RiskFormManager() {
  const [currentSchema, setCurrentSchema] = useState([]);
  const [changes, setChanges] = useState(null);
  const [sqlScript, setSqlScript] = useState(''); // State to hold the generated SQL script
  const [editableSql, setEditableSql] = useState(''); // State to hold the editable SQL script
  const dispatch = useDispatch();

  useEffect(() => {
    fetchCurrentSchema();
  }, []);

  const handleResetAllStates = async () => {
    try {
        await persistor.purge();
        persistor.pause();
        localStorage.clear(); // Consider selectively clearing items if needed

        // Ensure all state resets complete if they are async
        dispatch(resetControlsState()); 
        dispatch(resetRisksState());
        dispatch(resetSecurityState());

        // Redirect or refresh page as needed
        window.location.reload(true);
    } catch (error) {
        console.error('Failed to clear storage:', error);
        // Optionally handle the error in a user-visible way
    }
};

  




  const fetchCurrentSchema = async () => {
    const response = await fetch('/.netlify/functions/riskFormEditor', { method: 'POST' });
    const data = await response.json();
    setCurrentSchema(data.fields);
  };

  const handleCompareSchema = async () => {
    const jsonData = require('./riskForm.json'); // Assume JSON is served as static asset if not importable
    const jsonSections = Object.keys(jsonData).map(key => key.replace(/([A-Z])/g, '_$1').toLowerCase());
    const changes = compareAndUpdateSchema(jsonSections, currentSchema);
    setChanges(changes);
    handleGenerateSQL(changes); // Automatically generate SQL after comparison
  };

  const compareAndUpdateSchema = (jsonSections, currentSchema) => {
    const changes = {
      addedColumns: _.difference(jsonSections, currentSchema),
      removedColumns: _.difference(currentSchema, jsonSections),
      renamedColumns: detectRenamedColumns(jsonSections, currentSchema)
    };

    return changes;
  };

  const detectRenamedColumns = (jsonSections, currentSchema) => {
    const renamedColumns = [];
    currentSchema.forEach(colName => {
      const match = jsonSections.find(jsonName => 
        colName && jsonName && colName.startsWith(jsonName.substring(0, Math.min(5, jsonName.length)))
      );
      if (match && !jsonSections.includes(colName) && !currentSchema.includes(match)) {
        renamedColumns.push({ from: colName, to: match });
      }
    });
    return renamedColumns;
  };

  const handleGenerateSQL = (changes) => {
    const { addedColumns, removedColumns, renamedColumns } = changes;
    const sqlParts = [];

    if (addedColumns.length > 0) {
      addedColumns.forEach(column => {
        sqlParts.push(`ALTER TABLE risk_cards ADD COLUMN ${column} TEXT;`);
      });
    }

    if (removedColumns.length > 0) {
      removedColumns.forEach(column => {
        sqlParts.push(`ALTER TABLE risk_cards DROP COLUMN ${column};`);
      });
    }

    if (renamedColumns.length > 0) {
      renamedColumns.forEach(({ from, to }) => {
        sqlParts.push(`ALTER TABLE risk_cards CHANGE COLUMN ${from} ${to} TEXT;`);
      });
    }

    const script = sqlParts.join('\n');
    setSqlScript(script); // Update the state with the generated SQL
    setEditableSql(script); // Also set the editable SQL initially to the generated SQL
  };

  const handleExecuteScript = () => {
    // Placeholder function for executing SQL
    console.log('Executing SQL script:', editableSql);
    alert('Executing SQL script...'); // Placeholder action
  };

  return (
    <div>
      <button onClick={handleCompareSchema}>Check Schema Changes</button>
      <button onClick={handleResetAllStates}>Reset Entire State</button>
      {changes && (
        <div>
          <h2>Proposed Changes:</h2>
          <pre>{JSON.stringify(changes, null, 2)}</pre>
        </div>
      )}
      {sqlScript && (
        <div>
          <h2>Editable SQL Script:</h2>
          <textarea
            rows="10"
            cols="70"
            value={editableSql}
            onChange={(e) => setEditableSql(e.target.value)}
          />
          <button onClick={handleExecuteScript}>Execute SQL Script</button>
        </div>
      )}
    </div>
  );
}

export default RiskFormManager;
