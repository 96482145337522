import React,{useEffect, useState, useMemo} from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow, Paper, TableContainer, Select, MenuItem,Snackbar, Alert } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { debounce } from 'lodash';
import evaluationCriteria from './evaluationForm.json';
import { fetchRiskEvaluations,updateRiskEvaluation } from './redux/actions/riskActions.js';

  const RiskEvaluationForm = ({modelId, riskName,riskId, assessmentUuid,sectionName, handleSaveOperation }) => {
    console.log("Received props at riskeval:", { modelId, riskName, riskId, assessmentUuid, sectionName });
    const themes = useSelector((state) => state.themes);
    const savedRisk = useSelector(state => state.risks.models[modelId]?.riskFormData[sectionName]?.sectionRisks.find(risk => risk.riskId === riskId));
    const dispatch = useDispatch();
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('info');

 
    
    useEffect(() => {
      dispatch(fetchRiskEvaluations(modelId, assessmentUuid, sectionName));
    }, [dispatch, modelId, assessmentUuid, sectionName]);
   
   

  const debouncedSaveEvaluation = useMemo(() => debounce(async (themeId, property, value, priority, rationale) => {
   
    
    if (!savedRisk || !savedRisk.evaluationData) {
      console.error('No evaluation data found for this risk');
      setSnackbarMessage('No evaluation data found for this risk');
      setSnackbarSeverity('error');
      setSnackbarOpen(true); // Show error when no evaluation data is available
      return;
       
    }

    try {
      const response = await fetch('/.netlify/functions/saveRiskEvaluation', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          modelUuid: modelId,
          assessmentUuid,
          riskName,
          riskId,
          themeId,
          property,
          value,
          priority,
          rationale
        })
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      
      //setSnackbarMessage('Risk evaluation saved successfully');
      //setSnackbarSeverity('success');
    } catch (error) {
      setSnackbarMessage('Failed to save risk evaluation: ' + error.message);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
    
  }, 400), [modelId, assessmentUuid, riskName, riskId,savedRisk]);

  const handleChange = (e, themeId, attribute, property) => {
    const value = e.target.value;
    const priority = savedRisk.priority;
    const rationale = savedRisk.rationale;
    dispatch(updateRiskEvaluation(modelId, riskId, sectionName, themeId, attribute, property,value, priority,rationale));

    handleSaveOperation(() => debouncedSaveEvaluation(themeId, property, value, priority, rationale));

  };




  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };


  const titleCase = (str) => {
    // Split camelCase string into words
    const words = str.replace(/([A-Z])/g, ' $1').trim();
      return words.replace(/\w\S*/g, (txt) => {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };
  
  console.log("Saved Risk in RiskEvaluationForm:", savedRisk);
 

return (
  <>

  <TableContainer component={Paper} style={{ overflowX: 'auto' }}>
    <Table>
      <TableHead style={{ backgroundColor: '#e0e0e0' }}>
        <TableRow>
          <TableCell style={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>Theme</TableCell>
          {evaluationCriteria[0].properties.map(({ name }) => (
            <TableCell key={name} style={{ fontWeight: 'bold' }}>{titleCase(name)}</TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {themes.map((theme) => {
          const themeEvaluationData = savedRisk?.evaluationData?.[theme.theme_id]?.impact || {};

          return (
            <TableRow key={theme.theme_id}>
              <TableCell style={{ fontWeight: 'bold', backgroundColor: '#f0f0f0', textAlign: 'center' }}>
                {theme.name}
              </TableCell>
              {evaluationCriteria[0].properties.map(({ name, options }) => (
                <TableCell key={name}>
                  <Select
                    size="small"
                    name={`${theme.theme_id}-${name}`}
                    value={themeEvaluationData[name] || ''}
                    onChange={(e) => handleChange(e, theme.theme_id, evaluationCriteria[0].attribute, name)}
                    displayEmpty
                  >
                    <MenuItem value="" disabled>Select {titleCase(name)}</MenuItem>
                    {options.map((option) => (
                      <MenuItem key={option} value={option}>{option}</MenuItem>
                    ))}
                  </Select>
                </TableCell>
              ))}
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  </TableContainer>
  
  <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
       </>
);

};

export default RiskEvaluationForm;