
import React from 'react';
import { Stepper, Step, StepLabel } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';  // For completed steps.
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';  // For non-completed steps.

const ProgressStepper = ({ steps, activeStep, handleStepChange }) => {
    return (
        <Stepper activeStep={activeStep} orientation="vertical" sx={{ width: '100%' }}>
            {steps.map((label, index) => (
                <Step key={label}>
                    <StepLabel 
                      icon={activeStep > index ? <CheckCircleOutlineIcon /> : <CircleOutlinedIcon />} 
                      onClick={() => handleStepChange(index)}>
                        {label}
                    </StepLabel>
                </Step>
            ))}
        </Stepper>
    );
};

export default ProgressStepper;
