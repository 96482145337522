
import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FormControl, Grid, TextField, InputLabel, Select, MenuItem, Chip, Typography, CircularProgress, Paper } from '@mui/material';
import { setFormData, setSection,  fetchRiskCardData,setStep } from './redux/actions/riskActions';
import { Snackbar, Alert, Tooltip, IconButton } from '@mui/material';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import SmartToyOutlinedIcon from '@mui/icons-material/SmartToyOutlined'
import AutosaveIndicator from './AutoSaveIndicator.js';
import debounce from 'lodash/debounce';
import RiskCatalogue from './RiskCatalogue.js';
import RiskEvaluation from './RiskEvaluation';
import ActionPlan from './ActionPlan.js';
import TabStepper from './TabStepper.js';
import ProgressStepper from './progressStepper.js';
import AssessmentCompleted from './AssessmentCompleted.js';



const RiskCard = ({ modelId, assessmentUuid, narratives }) => {
    const [isLoading, setIsLoading] = useState(true);
    const dispatch = useDispatch();
    const riskFormData = useSelector(state => state.risks.models[modelId]?.riskFormData || {});
    
    const [selectedSection, setSelectedSection] = useState(0);
    const tabNames = ['riskCard', 'riskIdentification', 'riskEvaluation', 'actionPlan', 'completed'];
    const [selectedTab, setSelectedTab] = useState(0); 
    const sectionName=Object.keys(riskFormData)[selectedSection]

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('info');
    const [hoverStates, setHoverStates] = useState({});
    const [isSaving, setIsSaving] = useState(false);
    const [isSaved, setIsSaved] = useState(false);


    
    const formatSectionName = useCallback((name) => {
        return name.replace(/([A-Z])/g, ' $1')
                   .trim()
                   .replace(/\b([Aa]nd)\b/g, '&')
                   .replace(/\b\w/g, char => char.toUpperCase());
    }, []);


    const sections = useMemo(() => {
        return Object.keys(riskFormData).map(key => ({
            original: key,
            formatted: formatSectionName(key)
        }));
    }, [riskFormData, formatSectionName]);
    
    
    useEffect(() => {
        if ( modelId && assessmentUuid) {
            setIsLoading(true);
            dispatch(fetchRiskCardData(modelId, assessmentUuid))
                .then(() => {
                    setIsLoading(false);
                })
                .catch((error) => {
                    console.error('Failed to fetch data:', error);
                    setIsLoading(false);
                });
        } else {
            setIsLoading(false);
        }
    }, [dispatch, modelId,assessmentUuid]);
    
   
    // useEffect(() => {
    //     if (modelId && assessmentUuid) {
    //       dispatch(fetchRisksForModel({ modelId, assessmentUuid, sectionName }));
    //     }
    //   }, [dispatch, modelId, assessmentUuid, sectionName]);


    const debouncedSave = useMemo(() => debounce(async (formData) => {
        setIsSaving(true);
        try {
            const response = await fetch('/.netlify/functions/SaveRiskCard', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    model_uuid: modelId,
                    assessment_uuid: assessmentUuid,
                    formData
                })
            });
            if (response.ok) {
                setIsSaving(false);
                setIsSaved(true);
                setTimeout(() => setIsSaved(false), 2000);
            } else {
                const errorData = await response.json();
                throw new Error(errorData.error || 'Network response was not ok');
            }
        } catch (error) {
            console.error('Error:', error);
            setSnackbarMessage(error.message);
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
        } finally {
            setIsSaving(false);
        }
    }, 1500), [modelId, assessmentUuid]);

    const handleFieldChange = (section, fieldType, fieldName, event) => {
       
        //console.log ('section in field change func', section)
        let value;
        switch (fieldType) {
            case 'text':
            case 'dropdown':
                value = event.target.value;
                break;
            case 'checkbox':
                value = event.target.checked;
                break;
            case 'multiselect':
                value = Array.isArray(event.target.value) ? event.target.value : [event.target.value];
                break;
            default:
                value = event.target.value;
        }

        const updatedFormData = {
            ...riskFormData,
            [section]: {
                ...riskFormData[section],
                [fieldName]: {
                    ...riskFormData[section][fieldName],
                    value
                }
            }
        };
        console.log('Dispatching setFormData with:', { sectionName: section, fieldName, value });
        dispatch(setFormData({
            sectionName: section,
            fieldName, 
            value,
            description: riskFormData[section][fieldName]?.description || ""
          }, modelId));

          
          debouncedSave(updatedFormData);
       
    };

    useEffect(() => {
        const handleBeforeUnload = (event) => {
            debouncedSave.flush();
            event.preventDefault();
            event.returnValue = '';
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            debouncedSave.cancel();
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [debouncedSave]);


    const handleSectionChange = (index) => {
        setSelectedSection(index);
        dispatch(setSection(sections[index], modelId));
    };
    
    const handleStepChange = (tabName) => {

        setSelectedTab(tabName);
        dispatch (setStep(modelId,sectionName,tabName))
    };
    

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };



    const toTitleCase = (str) => {
        // First, handle known acronyms
        str = str.replace(/\b(EU|ROC|AUC)\b/g, match => match.toUpperCase());
      
        // Next, format camelCase and PascalCase
        return str
          .replace(/([a-z])([A-Z])/g, '$1 $2') // Insert space before each capital in camelCase
          .replace(/([A-Z]+)([A-Z][a-z])/g, '$1 $2') // Handle sequences of capitals followed by lowercase
          .replace(/\b([Aa]nd)\b/g, '&')
          .split(/\s+/) // Split the string by spaces to capitalize each word
          .map(word => {
            if (['EU', 'ROC', 'AUC'].includes(word)) {
              return word; // Return the acronym unchanged
            } else {
              return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase(); // Capitalize each word
            }
          })
          .join(' ')
          .trim(); // Trim any extra spaces
      };
      
     
      

    const handleMouseEnter = (fieldName) => {
        setHoverStates(prev => ({ ...prev, [fieldName]: true }));
    };

    const handleMouseLeave = (fieldName) => {
        setHoverStates(prev => ({ ...prev, [fieldName]: false }));
    };


    const paperStyle = {
        padding: '20px',
        margin: '10px 0',
        boxShadow: '0px 3px 6px rgba(0,0,0,0.1)' // Soft shadow for depth
    };

    return (
           
        <div>
            <Paper elevation={1} style={{ padding: '20px', marginBottom: '16px' }}>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '16px' }}>
                                        {/* Title and Loading Indicator */}
                                        <div style={{ display: 'flex', alignItems: 'center', gap: '10px', flex: 1 }}>
                                            <Typography variant="h5">{toTitleCase(sectionName)} Risks</Typography>
                                            {isLoading && (
                                                <div style={{ position: 'relative', display: 'inline-flex' }}>
                                                    <CircularProgress
                                                        size={24}
                                                        thickness={5}
                                                        sx={{ color: 'primary.main' }}
                                                    />
                                                    <SmartToyOutlinedIcon
                                                        style={{
                                                            color: 'secondary.light',
                                                            position: 'absolute',
                                                            top: '50%',
                                                            left: '50%',
                                                            transform: 'translate(-50%, -50%)',
                                                            fontSize: '1rem'
                                                        }}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                        {/* Autosave Indicator */}
                                        <div style={{ marginLeft: '10px' }}>
                                            <AutosaveIndicator isSaving={isSaving} isSaved={isSaved} />
                                        </div>
                                    </div>
                                   
                                    <TabStepper
                                         
                                        labels={tabNames.map(name => toTitleCase(name))}
                                        activeTab={selectedTab}
                                        onStepChange={handleStepChange}
                                        style={{ padding: '0 10px' }} // Add padding around the TabStepper
                                    />
                                </Paper>
           
            <Grid container spacing={2}>
                <Grid item xs={3} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
                     <div style={{
                        border: '1px solid #e0e0e0',
                        padding: '8px',
                        display: 'flex',
                        boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.1)'
                    }}> 
                         <ProgressStepper
                    //steps={sectionNames}
                    steps={sections.map(section => section.formatted)}
                    activeStep={selectedSection}
                    handleStepChange={handleSectionChange}
                />
                   </div> 
                </Grid>
                   
                <Grid item xs={9} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
                   
                      <div>
                      
                        {Object.keys(riskFormData).map((section, index) => selectedSection === index && (
                            
                            <Paper key={section} style={paperStyle}>
                              
                                {selectedTab === 0 && (
                                    <div>
                                        {Object.keys(riskFormData[section]).filter(key => key !== 'riskForm').map((key) => {
                                            const field = riskFormData[section][key];
                                            
                                            switch (field.type) {
                                                case 'multiselect':
                                                    return (
                                                        <FormControl fullWidth margin="normal" variant="outlined" key={key}>
                                                            <InputLabel>{toTitleCase(field.name)}</InputLabel>
                                                            <Select
                                                                multiple
                                                                label={toTitleCase(field.name)}
                                                                id={`${section}_${key}`}
                                                                name={`${section}_${key}`}
                                                                value={field.value || []}
                                                                onChange={(e) => handleFieldChange(section, field.type, field.name, e)}
                                                                //onChange={(e) => debouncedFieldChange(section, field.type, field.name, e)}
                                                                renderValue={(selected) => (
                                                                    <div>{selected.map((value) => (
                                                                        <Chip key={value} label={value} />
                                                                    ))}</div>
                                                                )}
                                                                onMouseEnter={() => handleMouseEnter(field.name)}
                                                                onMouseLeave={() => handleMouseLeave(field.name)}
                                                                endAdornment={
                                                                    hoverStates[field.name] && (
                                                                        <Tooltip title={field.description || 'No additional information available'}>
                                                                            <IconButton edge="end" style={{ color: '#1976d2', marginRight: '12px' }}>
                                                                                <HelpOutlineOutlinedIcon />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                    )
                                                                }
                                                            >
                                                                {field.options?.map((option, idx) => (
                                                                    <MenuItem key={idx} value={option}>{option}</MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                    );
                                                case 'dropdown':
                                                    return (
                                                        <FormControl fullWidth margin="normal" variant="outlined" key={key}>
                                                            <InputLabel>{toTitleCase(field.name)}</InputLabel>
                                                            <Select
                                                                label={toTitleCase(field.name)}
                                                                id={`${section}_${key}`}
                                                                name={`${section}_${key}`}
                                                                value={field.value || ''}
                                                                onChange={(e) => handleFieldChange(section, field.type, field.name, e)}
                                                                onMouseEnter={() => handleMouseEnter(field.name)}
                                                                onMouseLeave={() => handleMouseLeave(field.name)}
                                                                endAdornment={
                                                                    hoverStates[field.name] && (
                                                                        <Tooltip title={field.description || 'No additional information available'}>
                                                                            <IconButton edge="end" style={{ color: '#1976d2', marginRight: '12px' }}>
                                                                                <HelpOutlineOutlinedIcon />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                    )
                                                                }
                                                            >
                                                                {field.options?.map((option, idx) => (
                                                                    <MenuItem key={idx} value={option}>{option}</MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                    );
                                                case 'text':
                                                    return (
                                                        <FormControl fullWidth margin="normal" variant="outlined" key={key}>
                                                            <TextField
                                                                id={`${section}_${key}`}
                                                                name={`${section}_${key}`}
                                                                value={field.value || ''}
                                                                label={toTitleCase(field.name)}
                                                                onChange={(e) => handleFieldChange(section, field.type, field.name, e)}
                                                                variant="outlined"
                                                                multiline
                                                                onMouseEnter={() => handleMouseEnter(field.name)}
                                                                onMouseLeave={() => handleMouseLeave(field.name)}
                                                                InputProps={{
                                                                    endAdornment: hoverStates[field.name] && (
                                                                        <Tooltip title={field.description || 'No additional information available'}>
                                                                            <IconButton edge="end" style={{ color: '#1976d2', marginRight: '12px' }}>
                                                                                <HelpOutlineOutlinedIcon />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                    )
                                                                }}
                                                            />
                                                        </FormControl>
                                                    );
                                                    
                                                default:
                                                    return null;
                                            }
                                            
                                        })}
                                
                                    </div>
                                
                                )}
                        
             
                    {selectedTab === 1 && (
                        <RiskCatalogue modelId={modelId} sectionName={sectionName}  assessmentUuid={assessmentUuid} narratives={narratives} />
                )}
                
                
                     {selectedTab === 2 && (
                        <RiskEvaluation modelId={modelId} assessmentUuid={assessmentUuid} sectionName={sectionName} />  
                    )}
                {selectedTab === 3 && (
                    <ActionPlan riskFormData={riskFormData} modelId={modelId} assessmentUuid={assessmentUuid} sectionName={sectionName}/>
                    )}
                    {selectedTab === 4  && (
                   < AssessmentCompleted  modelId={modelId} assessmentUuid={assessmentUuid} sectionName={sectionName}/>
                    )}
                        
                        </Paper>
                                ) )}
                        </div>
                                                 
                        </Grid>    
            </Grid>
            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
                <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </div>
        
    );
};

export default RiskCard;

