import React, { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {  ExpandLess as ExpandLessIcon, Delete as DeleteIcon, Edit as EditIcon, Check as CheckIcon , LoopSharp as LoopSharpIcon, Save as SaveIcon} from '@mui/icons-material';

import AnalyticsOutlinedIcon from '@mui/icons-material/AnalyticsOutlined';
import {  TableContainer, Collapse, IconButton, Typography, Snackbar } from '@mui/material';
import {
    CircularProgress,
    Alert,
    Paper,
    Table,
    TableHead,
    TableBody,
    TableCell,
    TableRow,
    TextField
} from '@mui/material';

import { fetchControls, editControl,discardControl, refreshSingleRiskControls, setSavedControls, fetchSavedControlEvaluations, addSavedControl } from './redux/actions/controlActions';
import ControlEvaluationForm from './ControlEvaluation';
import AutosaveIndicator from './AutoSaveIndicator';
import useSaveOperation from './useSaveOperation';
import IconWithProgress from './IconWithProgress';


function ActionPlan ({riskFormData, modelId, assessmentUuid, sectionName }) {
    const controls = useSelector(state => state.controls.controls || {});
    const isLoading = useSelector(state => state.controls.loading);
    const error = useSelector(state => state.controls.error);
    const sectionRisks = useSelector(state => state.risks.models[modelId]?.riskFormData[sectionName]?.sectionRisks || []);
    const sectionState = riskFormData[sectionName];
    const discardedControls = useSelector(state => state.controls.discardedControls) || {};
    const [expandedCard, setExpandedCard] = useState(null); 
    const dispatch = useDispatch();
    const [editingControl, setEditingControl] = useState(null);
    //const [refreshingRisks, setRefreshingRisks] = useState({});
    const [expandedControl, setExpandedControl] = useState(null);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('info');
    const savedControls = useSelector(state => state.controls.savedControls || {});
    const [expandedSavedControls, setExpandedSavedControls] = useState(null);
    const fetchingControlsStatus = useSelector(state => state.controls.fetchingControlsStatus || {});
    const { isSaving, isSaved, handleSaveOperation } = useSaveOperation();

    const savedRisks = useMemo(() => {
      return sectionRisks.filter(risk => risk.status === 'Saved');
    }, [sectionRisks]);
   


  useEffect(() => {
  if (modelId && assessmentUuid) {
    dispatch(setSavedControls(modelId, assessmentUuid));
  }
}, [modelId, assessmentUuid, dispatch]);

useEffect(() => {
  dispatch(fetchControls({
    sectionName,
    sectionState,
    sectionRisks: savedRisks
  }));
}, [dispatch, sectionName, sectionState, savedRisks]);

 
  useEffect(() => {
    if (modelId && assessmentUuid) {
      dispatch(fetchSavedControlEvaluations(modelId, assessmentUuid));
    }
  }, [modelId, assessmentUuid, dispatch]);

  if (error) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <Alert severity="error">{error}</Alert>
            </div>
        );
    }

    
    const handleCardClick = (riskName, type) => {
      if (type === 'generated') {
        setExpandedCard(expandedCard === riskName ? null : riskName);
        setExpandedSavedControls(null); // Reset saved controls expansion
      } else if (type === 'saved') {
        setExpandedSavedControls(expandedSavedControls === riskName ? null : riskName);
        setExpandedCard(null); // Reset generated controls expansion
      }
    };

    const handleEditClick = (control, index) => {
        setEditingControl({ control, index });
      };
    
      const handleControlClick = (riskName, controlName) => {
        if (expandedControl === `${riskName}-${controlName}`) {
            setExpandedControl(null);
        } else {
            setExpandedControl(`${riskName}-${controlName}`);
        }
    };

      const handleEditSave = async (control, index, risk) => {
        
      
        if (!risk) {
          console.error(`No risk found or provided.`);
          return;
        }
      
        const riskName = risk.riskName;
        const riskId = risk.riskId;

        
      
        if (control.controlId) {
          // Control is being updated
          await onSaveControlClick({ riskId, name: riskName }, control);
        } else {
          // New control, dispatch editControl to update in the Redux store
          dispatch(editControl(control, index, riskId));
        }
      
        setEditingControl(null);
      };
      
     


      const handleDiscardControl = (control, riskName) => {
        dispatch(discardControl(control, riskName));
      };

      const refreshControls = async (risk) => {
        const riskName=risk.riskName;
        const riskId=risk.riskId
        try {
            dispatch(refreshSingleRiskControls(sectionName, sectionState, riskName, riskId)); 
        } catch (error) {
            console.error("Error refreshing controls: ", error);
            // Error handling...
        }
    };

        const handleSaveControl = async (risk, control) => {
          const { riskId, name: riskName } = risk;
          const modelUuid = modelId;
          const controlName = control.name;
          const controlUuid = control.controlId; // This might be undefined if it's a new control
      
          const body = JSON.stringify({
            modelUuid,
            assessmentUuid,
            riskUuid: riskId,
            controlName,
            ...(controlUuid ? { controlUuid } : {}) // Include controlId if it exists
          });
      
          try {
            const response = await fetch('/.netlify/functions/saveControl', {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body
            });
      
            if (!response.ok) {
              throw new Error('Failed to save the control');
            }
      
            const responseData = await response.json();
            // Dispatch any redux actions that update the store based on the save
            dispatch(addSavedControl(riskId, {
              name: controlName,
              controlId: responseData.controlUuid || controlUuid, // Handle new controlId from response
              modelUuid,
              riskName
            }));
      
            return responseData; // This will be used by .then() after handleSaveOperation
          } catch (error) {
            console.error('Error saving control:', error);
            throw error; // Ensures the error is caught by handleSaveOperation
          }
        };
      
        // Function to call handleSaveOperation with handleSaveControl
        const onSaveControlClick = async (risk, control) => {
          try {
            // Ensure handleSaveOperation returns a Promise
            await handleSaveOperation(() => handleSaveControl(risk, control));
            setSnackbarMessage('Control saved successfully!');
            setSnackbarSeverity('success');
          } catch (error) {
            setSnackbarMessage(error.message || 'Error saving control');
            setSnackbarSeverity('error');
          } finally {
            setSnackbarOpen(true);
          }
        };
        
        
      

          const handleSnackbarClose = (event, reason) => {
            if (reason === 'clickaway') {
              return;
            }
            setSnackbarOpen(false);
          };  
function FetchingIndicator() {
  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <CircularProgress size={20} style={{ marginRight: '10px' }} />
      <div style={{ fontSize: '16px', display: 'flex', alignItems: 'center' }}>
        Fetching controls
        <span style={{ display: 'inline-block', marginLeft: '5px' }}>
          <span className="dot">.</span>
          <span className="dot">.</span>
          <span className="dot">.</span>
        </span>
      </div>
    </div>
  );
}


          return (
            <div style={{ margin: '16px' }}>
              <Paper elevation={2} style={{ padding: '20px', marginBottom: '16px' }}>
              <Typography variant="h6" style={{ flexGrow: 0 }}>Risk Handling Plan</Typography>
                  <AutosaveIndicator isSaving={isSaving} isSaved={isSaved} />
              </Paper>
          
              {Object.keys(savedControls).length === 0 && !isLoading ? (
                <Typography variant="h6" style={{ textAlign: 'center', marginTop: '20px', fontWeight: 'bold' }}>
                  No controls saved yet.
                </Typography>
              ) : null}
                
 
          
              <TableContainer component={Paper} style={{ width: '100%' }}>
                <Table style={{ width: '100%' }}>
                  <TableHead style={{ backgroundColor: '#e0e0e0' }}>
                    <TableRow>
                      <TableCell style={{ fontWeight: 'bold' }}>Risk Name</TableCell>
                      <TableCell style={{ fontWeight: 'bold' }}>
                      {Object.values(fetchingControlsStatus).some(status => status) ? <FetchingIndicator /> : 'Generated Controls'}
                      </TableCell>
                      <TableCell style={{ fontWeight: 'bold' }}>Saved Controls</TableCell>
                      <TableCell style={{ fontWeight: 'bold' }}>Discarded Controls</TableCell>
                      <TableCell style={{ fontWeight: 'bold' }}>Refresh Controls</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                  
          
                    {savedRisks.map((risk, index) => {
                      const isFetchingControls = fetchingControlsStatus[risk.riskId];

                      
                      const controlCount = controls[risk.riskId]?.length || 0;
                      return (
                        <React.Fragment key={index}>
                          <TableRow hover>
                            <TableCell align="left">
                              <Typography variant="body1" style={{ fontWeight: 'normal' }}>{risk.riskName}</Typography>
                            </TableCell>
                            <TableCell align="center">
                                {isFetchingControls ? (
                                  <IconWithProgress isLoading={isFetchingControls} />
                                ) : (
                                  <Typography 
                                    style={{ cursor: 'pointer', textDecoration: 'underline', color: '#007BFF' }}
                                    onClick={() => handleCardClick(risk.riskId, 'generated')}
                                  >
                                    {controlCount}
                                  </Typography>
                                )}
                              </TableCell>
                            <TableCell align="center" style={{ cursor: 'pointer', textDecoration: 'underline', color: '#007BFF' }} onClick={() => handleCardClick(risk.riskId, 'saved')}>
                              {savedControls[risk.riskId]?.length || 0}
                            </TableCell>
                            <TableCell align="center">
                              {(discardedControls[risk.riskId] || []).length}
                            </TableCell>
                            <TableCell align="center">
                              <IconButton onClick={(e) => { e.stopPropagation(); refreshControls(risk); }}>
                                <LoopSharpIcon />
                              </IconButton>
                            </TableCell>
                          </TableRow>
          
                          {(expandedCard === risk.riskId || expandedSavedControls === risk.riskId) && (
                            <TableRow>
                              <TableCell colSpan={5}>
                                <Collapse in={true} timeout="auto" unmountOnExit>
                                  <Paper elevation={0} sx={{ overflow: 'hidden', width: '100%' }}>
                                    <Table style={{ width: '100%', marginLeft: '10px', backgroundColor: '#f9f9f9' }}>
                                      <TableHead style={{ backgroundColor: '#f5f5f5' }}>
                                        <TableRow>
                                       
                                          <TableCell style={{ fontWeight: 'bold' }}>Action Name</TableCell>
                                          <TableCell style={{ fontWeight: 'bold' }}>Actions</TableCell>
                                        </TableRow>
                                      </TableHead>
                                      <TableBody>
                                        {(expandedCard === risk.riskId ? controls[risk.riskId]|| [] : savedControls[risk.riskId]|| []).map((control, controlIndex) => (
                                          <React.Fragment key={`control-${controlIndex}`}>
                                            <TableRow>
                                                 <TableCell>
                                                {editingControl && editingControl.index === controlIndex ? (
                                                  <TextField
                                                    
                                                    fullWidth
                                                    variant="outlined"
                                                    size="small"
                                                    value={editingControl.control.name}
                                                    onChange={(e) => setEditingControl({ ...editingControl, control: { ...editingControl.control, name: e.target.value }})}
                                                  />
                                                ) : (
                                                  <Typography>{control.name}</Typography>
                                                )}
                                              </TableCell>
                                              <TableCell>
                                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around' }}>
                                                  {editingControl && editingControl.index === controlIndex ? (
                                                    <IconButton onClick={() => handleEditSave(editingControl.control, editingControl.index, risk)}>
                                                      <CheckIcon />
                                                    </IconButton>
                                                  ) : (
                                                    <IconButton onClick={() => handleEditClick(control, controlIndex)}>
                                                      <EditIcon />
                                                    </IconButton>
                                                  )}
          
                                                 
                                                    <span>
                                                      <IconButton onClick={() => handleDiscardControl(control, risk.riskId)}>
                                                        <DeleteIcon style={{ color: 'red' }} />
                                                      </IconButton>
                                                    </span>
                                                 
                                                  {expandedSavedControls === risk.riskId && (
                                                    <IconButton onClick={() => handleControlClick(risk.riskId, control.name)}>
                                                      {expandedControl === `${risk.riskId}-${control.name}` ? <ExpandLessIcon /> : <AnalyticsOutlinedIcon />}
                                                    </IconButton>
                                                  )}
                                                  {expandedCard === risk.riskId && (
                                                    <IconButton onClick={() => onSaveControlClick(risk, control) }>
                                                      <SaveIcon />
                                                    </IconButton>
                                                  )}
                                                </div>
                                              </TableCell>
                                            </TableRow>
          
                                            {expandedSavedControls === risk.riskId && expandedControl === `${risk.riskId}-${control.name}` && (
                                              <TableRow>
                                                <TableCell colSpan={5} style={{ paddingBottom: 0, paddingTop: 0 }}>
                                                  <ControlEvaluationForm
                                                  riskId={risk.riskId} 
                                                  controlId={control.controlId} 
                                                  modelId={modelId} 
                                                  assessmentUuid={assessmentUuid}
                                                  handleSaveOperation={handleSaveOperation}
                                                   />
                                                </TableCell>
                                              </TableRow>
                                            )}
                                          </React.Fragment>
                                        ))}
                                      </TableBody>
                                    </Table>
                                  </Paper>
                                </Collapse>
                              </TableCell>
                            </TableRow>
                          )}
                        </React.Fragment>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
          
                      
              <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
                <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
                  {snackbarMessage}
                </Alert>
              </Snackbar>
            </div>
          );


 }

export default ActionPlan;