// Control action types
export const FETCH_CONTROLS_START = 'FETCH_CONTROLS_START';
export const FETCH_CONTROLS_SUCCESS = 'FETCH_CONTROLS_SUCCESS';
export const FETCH_CONTROLS_ERROR = 'FETCH_CONTROLS_ERROR';
export const EDIT_CONTROL = 'EDIT_CONTROL';
export const DISCARD_CONTROL = 'DISCARD_CONTROL';
export const ADD_RISK_TO_FETCHED = 'ADD_RISK_TO_FETCHED';
export const REMOVE_RISK_FROM_FETCHED = 'REMOVE_RISK_FROM_FETCHED';
export const FETCH_CONTROLS_PER_RISK='FETCH_CONTROLS_PER_RISK'
export const UPDATE_CONTROL_EVALUATION_FIELD = 'UPDATE_CONTROL_EVALUATION_FIELD';
export const SET_SAVED_CONTROLS_START = 'FETCH_SAVED_CONTROLS_START';
export const SET_SAVED_CONTROLS_SUCCESS = 'FETCH_SAVED_CONTROLS_SUCCESS';
export const SET_SAVED_CONTROLS_ERROR = 'FETCH_SAVED_CONTROLS_ERROR';
export const FETCH_SAVED_CONTROL_EVALUATIONS_START='FETCH_SAVED_CONTROL_EVALUATIONS_START'
export const FETCH_SAVED_CONTROL_EVALUATIONS_SUCCESS='FETCH_SAVED_CONTROL_EVALUATIONS_SUCCES'
export const FETCH_SAVED_CONTROL_EVALUATIONS_ERROR='FETCH_SAVED_CONTROL_EVALUATIONS_ERROR'
export const ADD_SAVED_CONTROL = 'ADD_SAVED_CONTROL';
export const SET_FETCHING_CONTROLS_STATUS = 'SET_FETCHING_CONTROLS_STATUS';
export const DISCARD_SAVED_CONTROL='DISCARD_SAVED_CONTROL'
export const UPDATE_SAVED_CONTROL_EVALUATION_FIELD = 'UPDATE_SAVED_CONTROL_EVALUATION_FIELD';
export const RESET_CONTROLS_STATE = 'RESET_CONTROLS_STATE';
export const REFRESH_CONTROLS_START ='REFRESH_CONTROLS_START';
export const REFRESH_CONTROLS_SUCCESS='REFRESH_CONTROLS_SUCCESS'
export const REFRESH_CONTROLS_ERROR='REFRESH_CONTROLS_ERROR'

export const resetControlsState = () => ({
  type: RESET_CONTROLS_STATE
});
  
export const fetchControls = ({ sectionName, sectionState, sectionRisks }) => async (dispatch, getState) => {
  const { controls, savedControls } = getState().controls;

  // Filter risks that need control data fetched
  const risksToFetch = sectionRisks.filter(risk =>
    (!controls[risk.riskId] || controls[risk.riskId].length === 0) &&
    (!savedControls[risk.riskId] || savedControls[risk.riskId].length === 0)
  );

  console.log ('risks to fetch controls for', risksToFetch)

  if (risksToFetch.length === 0) {
    return; // Exit if all risks have controls or savedControls
  }

  risksToFetch.forEach(risk => {
    dispatch({
      type: FETCH_CONTROLS_START,
      payload: { riskId: risk.riskId }
    });
  });

  try {
    const promises = risksToFetch.map(async (risk) => {
      const response = await fetch('/.netlify/functions/generateControls', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ sectionName, sectionState, riskName: risk.riskName, riskId: risk.riskId }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      return await response.json();
    });

    const responseControls = await Promise.allSettled(promises);
    const newControls = responseControls.reduce((acc, result) => {
      if (result.status === 'fulfilled') {
        result.value.forEach(control => {
          const { riskId } = control;
          acc[riskId] = acc[riskId] || [];
          acc[riskId].push(control);
        });
      }
      return acc;
    }, {});

    dispatch({
      type: FETCH_CONTROLS_SUCCESS,
      payload: newControls
    });

  } catch (err) {
    console.error('An error occurred:', err);
    risksToFetch.forEach(risk => {
      dispatch(setFetchingControlsStatus(risk.riskId, false));
    });
    dispatch({
      type: FETCH_CONTROLS_ERROR,
      payload: err.message,
    });
  }
};


export const refreshSingleRiskControls = (sectionName, sectionState, riskName, riskId) => async (dispatch) => {
 

  dispatch({
    type: REFRESH_CONTROLS_START,
    payload: { riskId }
  });

  try {
    const response = await fetch('/.netlify/functions/generateControls', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ sectionName, sectionState, riskName, riskId })
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const controls = await response.json();
    const newControls = {
      [riskId]: controls
    };

    dispatch({
      type: REFRESH_CONTROLS_SUCCESS,
      payload: newControls
    });
  } catch (error) {
    dispatch({
      type: REFRESH_CONTROLS_ERROR,
      payload: error.message
    });
  }
};

export const updateControlEvaluationField = (riskId, controlId, fieldName, value) => ({
  type: UPDATE_CONTROL_EVALUATION_FIELD,
  payload: { riskId, controlId, fieldName, value }
});

// In your actions file
export const updateSavedControlEvaluationField = (riskId, controlId, fieldName, value) => ({
  type: UPDATE_SAVED_CONTROL_EVALUATION_FIELD,
  payload: { riskId, controlId, fieldName, value },
});



  
export const editControl = (controlData, index, riskId) => ({
  type: EDIT_CONTROL,
  payload: { controlData, index, riskId },
});

  
  export const discardControl = (control, riskId) => ({
    type: DISCARD_CONTROL,
    payload: { ...control, riskId }
  });
  export const addRiskToFetched = (riskId) => ({
    type: ADD_RISK_TO_FETCHED,
    payload: riskId
  });
  
  export const removeRiskFromFetched = (riskId) => ({
    type: REMOVE_RISK_FROM_FETCHED,
    payload: riskId
  });

  export const setSavedControls = (modelId, assessmentUuid) => async (dispatch) => {
    dispatch({ type: SET_SAVED_CONTROLS_START });
  
    try {
      const response = await fetch('/.netlify/functions/fetchSavedControls', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ modelId, assessmentUuid })
      });
  
      if (!response.ok) throw new Error('Failed to fetch saved controls');
  
      const savedControls = await response.json();
    console.log ('savedcontrols sent to reducer', savedControls)
      dispatch({
        type: SET_SAVED_CONTROLS_SUCCESS,
        payload: savedControls
      });
    } catch (error) {
      dispatch({
        type: SET_SAVED_CONTROLS_ERROR,
        payload: error.message
      });
    }
  };

  export const fetchSavedControlEvaluations = (modelId, assessmentUuid) => async (dispatch) => {
    dispatch(fetchSavedControlEvaluationsStart());
  
    try {
      const response = await fetch('/.netlify/functions/fetchSavedControlEvaluations', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ modelId, assessmentUuid }),
      });
  
      if (!response.ok) throw new Error('Failed to fetch saved control evaluations');
  
      const savedControlEvaluations = await response.json();
      dispatch(fetchSavedControlEvaluationsSuccess(savedControlEvaluations));
    } catch (error) {
      dispatch(fetchSavedControlEvaluationsError(error.message));
    }
  };
  
  export const fetchSavedControlEvaluationsStart = () => ({
    type: FETCH_SAVED_CONTROL_EVALUATIONS_START,
  });
  
  export const fetchSavedControlEvaluationsSuccess = (savedControlEvaluations) => ({
    type: FETCH_SAVED_CONTROL_EVALUATIONS_SUCCESS,
    payload: savedControlEvaluations,
  });
  
  export const fetchSavedControlEvaluationsError = (error) => ({
    type: FETCH_SAVED_CONTROL_EVALUATIONS_ERROR,
    payload: error,
  });
  // Action creator to add a saved control
export const addSavedControl = (riskId, control) => ({
  
  type: ADD_SAVED_CONTROL,
  payload: { riskId, control }
});

export const setFetchingControlsStatus = (riskId, isFetching) => ({
  type: SET_FETCHING_CONTROLS_STATUS,
  payload: { riskId, isFetching },
});


export const discardSavedControl = (controlId, riskId) => async dispatch => {
  try {
    // Call serverless function to delete the control from the database
    const response = await fetch('/.netlify/functions/discardControl', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ controlId })
    });

    if (!response.ok) {
      throw new Error('Failed to delete the control');
    }

    // Dispatch an action to remove the control from Redux state
    dispatch({
      type: 'DISCARD_SAVED_CONTROL',
      payload: { controlId, riskId }
    });

    // Optionally, show a success message
    console.log('Control discarded successfully');

  } catch (error) {
    console.error('Error discarding control:', error);
    // Optionally, handle the error (e.g., show an error message)
  }
};
