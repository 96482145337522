import { 
    FETCH_CONTROLS_START,
    FETCH_CONTROLS_SUCCESS,
    FETCH_CONTROLS_ERROR,
    EDIT_CONTROL,
    DISCARD_CONTROL,
    ADD_RISK_TO_FETCHED,
    REMOVE_RISK_FROM_FETCHED,
    UPDATE_CONTROL_EVALUATION_FIELD,
    SET_SAVED_CONTROLS_START,
    SET_SAVED_CONTROLS_SUCCESS,
    SET_SAVED_CONTROLS_ERROR,
    FETCH_SAVED_CONTROL_EVALUATIONS_START,
    FETCH_SAVED_CONTROL_EVALUATIONS_SUCCESS,
    FETCH_SAVED_CONTROL_EVALUATIONS_ERROR,
    ADD_SAVED_CONTROL,
    SET_FETCHING_CONTROLS_STATUS,
    RESET_CONTROLS_STATE,
    REFRESH_CONTROLS_START,
    REFRESH_CONTROLS_SUCCESS,
    REFRESH_CONTROLS_ERROR
    //UPDATE_SAVED_CONTROL_EVALUATION_FIELD

} from '../actions/controlActions.js'; 

const initialState = {
  controls: {},
  controlsLoading: {},
  fetchingControlsStatus: {},
  controlEvaluations: {},
  discardedControls: {},
  savedControlEvaluations: {},
  fetchedRisks: [],
  loading: false,
  error: null
};


const controlReducer = (state = initialState, action) => {
    switch (action.type) {

      case RESET_CONTROLS_STATE:
      return initialState;
      
      case FETCH_CONTROLS_START:
        return {
            ...state,
            fetchingControlsStatus: {
                ...state.fetchingControlsStatus,
                [action.payload.riskId]: true,
            },
            loading: true,
            error: null
        };


        case FETCH_CONTROLS_SUCCESS:

        const newFetchingStatus = { ...state.fetchingControlsStatus };
        console.log ('payload in fetch control success', action.payload)
        Object.keys(action.payload).forEach(riskId => {
            newFetchingStatus[riskId] = false;
        });
          return {
              ...state,
              controls: {
                  ...state.controls,
                  ...action.payload
              },
              fetchingControlsStatus: newFetchingStatus,
              loading: false
          };
    
          case FETCH_CONTROLS_ERROR:
            return {
                ...state,
                error: action.payload,
                fetchingControlsStatus: {
                    ...state.fetchingControlsStatus,
                    [action.payload.riskId]: false,
                },
                loading: false
            };
            case REFRESH_CONTROLS_START:
              return {
                  ...state,
                  fetchingControlsStatus: {
                      ...state.fetchingControlsStatus,
                      [action.payload.riskId]: true,
                  },
                  loading: true,
                  error: null
              };
              case REFRESH_CONTROLS_SUCCESS:{
                const newFetchingStatus = { ...state.fetchingControlsStatus };
                Object.keys(action.payload).forEach(riskId => {
                    newFetchingStatus[riskId] = false;
                });
                return {
                    ...state,
                    controls: {
                        ...state.controls,
                        ...action.payload
                    },
                    fetchingControlsStatus: newFetchingStatus,
                    loading: false
                };
              }

              case REFRESH_CONTROLS_ERROR:
                return {
                    ...state,
                    error: action.payload,
                    fetchingControlsStatus: {
                        ...state.fetchingControlsStatus,
                        [action.payload.riskId]: false,
                    },
                    loading: false
                };
 
      case EDIT_CONTROL:{
        const { riskId, index, controlData } = action.payload;
        const editedControls = { ...state.controls };
        editedControls[riskId][index] = controlData;
        return {
          ...state,
          controls: editedControls,
        };
      }
        case DISCARD_CONTROL:
          if (state.discardedControls[action.payload.riskId]?.some(existingControl => existingControl.name === action.payload.name)) {
            return state;
          }
          return {
            ...state,
            discardedControls: {
              ...state.discardedControls,
              [action.payload.riskId]: [...(state.discardedControls[action.payload.riskId] || []), action.payload],
            },
            controls: {
              ...state.controls,
              [action.payload.riskId]: state.controls[action.payload.riskId].filter(existingControl => existingControl.name !== action.payload.name),
            }
          };
          case ADD_RISK_TO_FETCHED:
            return {
              ...state,
              fetchedRisks: [...state.fetchedRisks, action.payload]
            };

          case REMOVE_RISK_FROM_FETCHED:
            const refreshedRisks = state.fetchedRisks.filter(risk => risk !== action.payload);
            return {
              ...state,
              fetchedRisks: refreshedRisks
            };

    

case UPDATE_CONTROL_EVALUATION_FIELD: {
  const { riskId, controlId, fieldName, value } = action.payload;

  // Retrieve the current evaluations for the controlId, defaulting to an empty array if not present
  const currentEvaluations = state.savedControlEvaluations[riskId]?.[controlId] || [];

  // Assuming we're updating the first evaluation in the array
  const updatedEvaluation = {
    // Spread the existing fields from the first evaluation
    ...(currentEvaluations[0] || {}),
    // Update the specified field, converting the fieldName to snake_case
    [fieldName.replace(/([A-Z])/g, '_$1').toLowerCase()]: value,
  };

  return {
    ...state,
    savedControlEvaluations: {
      ...state.savedControlEvaluations,
      [riskId]: {
        ...state.savedControlEvaluations[riskId],
        // Update the array of evaluations for the controlId, with the updated evaluation at the first position
        [controlId]: [updatedEvaluation, ...currentEvaluations.slice(1)],
      },
    },
  };
}

 

case SET_SAVED_CONTROLS_START:
  return {
    ...state,
    loading: true
  };


  
//   const updatedSavedControls = { ...state.savedControls };

//   Object.keys(action.payload).forEach(riskId => {
//     if (!updatedSavedControls[riskId]) {
//       updatedSavedControls[riskId] = action.payload[riskId];
//     } else {
//       // Merge the controls, ensuring new controls are added and existing ones are updated
//       const updatedControls = [...updatedSavedControls[riskId]];
//       action.payload[riskId].forEach(control => {
//         const existingControlIndex = updatedControls.findIndex(c => c.name === control.name);
//         if (existingControlIndex > -1) {
//           updatedControls[existingControlIndex] = control; // Update existing control
//         } else {
//           updatedControls.push(control); // Add new control
//         }
//       });
//       updatedSavedControls[riskId] = updatedControls;
//     }
//   });

//   return {
//     ...state,
//     savedControls: updatedSavedControls,
//     loading: false,
//   };

case SET_SAVED_CONTROLS_SUCCESS:
  const updatedSavedControls = { ...state.savedControls };

  Object.keys(action.payload).forEach(riskId => {
    const controls = action.payload[riskId];
    if (!updatedSavedControls[riskId]) {
      updatedSavedControls[riskId] = controls;
    } else {
      // Merge controls ensuring new controls are added and existing ones are updated
      controls.forEach(control => {
        const index = updatedSavedControls[riskId].findIndex(c => c.controlId === control.controlId);
        if (index > -1) {
          updatedSavedControls[riskId][index] = control;
        } else {
          updatedSavedControls[riskId].push(control);
        }
      });
    }
  });

  return {
    ...state,
    savedControls: updatedSavedControls,
    loading: false
  };

case SET_SAVED_CONTROLS_ERROR:
  return {
    ...state,
    error: action.payload,
    loading: false
  };
  case FETCH_SAVED_CONTROL_EVALUATIONS_START:
  return {
    ...state,
    loading: true,
    error: null,
  };

  case FETCH_SAVED_CONTROL_EVALUATIONS_SUCCESS:
    return {
      ...state,
      savedControlEvaluations: {
        ...state.savedControlEvaluations,
        ...action.payload, // Assumes payload is structured with risk_id as top-level keys
      },
      loading: false,
    };
  

case FETCH_SAVED_CONTROL_EVALUATIONS_ERROR:
  return {
    ...state,
    loading: false,
    error: action.payload,
  };


case ADD_SAVED_CONTROL: {
  const { riskId, control } = action.payload;

  // First, access the existing controls for this risk, if any
  const existingControls = state.savedControls[riskId] || [];

  // Check if the control already exists
  const existingControlIndex = existingControls.findIndex(c => c.controlId === control.controlId);

  let updatedControls;
  if (existingControlIndex >= 0) {
    // If found, replace the old control with the updated one
    updatedControls = [...existingControls];
    updatedControls[existingControlIndex] = control;
  } else {
    // If not found, add the new control
    updatedControls = [...existingControls, control];
  }
  const remainingControls = state.controls[riskId]?.filter(c => c.name !== control.name) || [];
  // Update the state
  return {
    ...state,
    savedControls: {
      ...state.savedControls,
      [riskId]: updatedControls
    },
    controls: {
      ...state.controls,
      [riskId]: remainingControls
    }
  };
}

case 'DISCARD_SAVED_CONTROL':
  const { controlId, riskId } = action.payload;

  // Filter out the discarded control from savedControls
  const updatedSavedControlsWithDelete = state.savedControls[riskId].filter(control => control.controlId !== controlId);

  // Filter out evaluations linked to the discarded control
  const updatedSavedControlEvaluations = state.savedControlEvaluations[riskId].filter(evaluation => evaluation.controlId !== controlId);

  return {
    ...state,
    savedControls: {
      ...state.savedControls,
      [riskId]: updatedSavedControlsWithDelete
    },
    savedControlEvaluations: {
      ...state.savedControlEvaluations,
      [riskId]: updatedSavedControlEvaluations
    }
  };



case SET_FETCHING_CONTROLS_STATUS: {
  const { riskId, isFetching } = action.payload;
  return {
    ...state,
    fetchingControlsStatus: {
      ...state.fetchingControlsStatus,
      [riskId]: isFetching,
    },
  };
}


    default:
      return state;
  }
};



export default controlReducer;
