import React, { useEffect, useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Paper, Typography, FormControl, InputLabel, Select, MenuItem, Chip,
  Button, Box, CircularProgress, TextField, OutlinedInput, Snackbar, Alert,Card, IconButton, CardContent,Tooltip
} from '@mui/material';
import AutosaveIndicator from './AutoSaveIndicator';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import SmartToyOutlinedIcon from '@mui/icons-material/SmartToyOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
//import isEqual from 'lodash/isEqual';
import TabStepper from './TabStepper';
import { setContextData, updateContextData, generateNarrative, saveContextData,  fetchContextData } from './redux/actions/contextActions';
//
import contextForm from './contextForm.json';
import debounce from 'lodash/debounce';


const ContextAssessment = ({ modelId, assessmentUuid }) => {
  const dispatch = useDispatch();
  const contextData = useSelector(state => state.context.data[modelId] || {});
  const isLoading = useSelector(state => state.context.loading);
  const [activeTab, setActiveTab] = useState(0); 
  const [editMode, setEditMode] = useState({});
  const [isSaving, setIsSaving] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('info');
  const riskFormData = useSelector(state => state.risks.models[modelId]?.riskFormData || {});
  const [hoverStates, setHoverStates] = useState({});

useEffect(() => {
    // Initialize context data
    const initialData = Object.keys(contextForm).reduce((acc, section) => {
      acc[section] = contextForm[section].map(field => ({
        ...field,
        value: field.type === 'multiselect' ? [] : field.defaultValue || ''
      })).reduce((fieldsAcc, field) => {
        fieldsAcc[field.name] = field.value;
        return fieldsAcc;
      }, {});
      return acc;
    }, {});
    dispatch(setContextData({ modelId, data: initialData }));
    
    // Fetch data from the database
    dispatch(fetchContextData(modelId, assessmentUuid));
  }, [dispatch, modelId, assessmentUuid]);

  

  console.log ('context data to render', contextData)
  
  const debouncedSave = useMemo(() => debounce((section, sectionData) => {
    setIsSaving(true);
    dispatch(saveContextData(modelId, assessmentUuid,section, sectionData))
        .then(() => {
            setIsSaving(false);
            setIsSaved(true);
            setSnackbarMessage('Data saved successfully');
            setSnackbarSeverity('success');
            setSnackbarOpen(true);
            setTimeout(() => setIsSaved(false), 2000);
        })
        .catch((error) => {
            setIsSaving(false);
            setSnackbarMessage(`Error: ${error.message}`);
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
        });
}, 1500), [dispatch, modelId, assessmentUuid]);

  const handleChange = (section, fieldName, value) => {
    // Dispatch an action to update context data in Redux store
    dispatch(updateContextData(modelId, section, fieldName, value));
    const sectionData = { ...contextData[section], [fieldName]: value };
    debouncedSave(section, sectionData);
  };

   const handleStepChange = (index) => {
    setActiveTab(index); // Update the active tab index
  };

  const handleGenerateNarrative = (section) => {
    const narrativePrompt = Object.keys(riskFormData).map(key => {
      // Assume each key corresponds to a risk area
      return `- ${key}: ${riskFormData[key].description || 'No description available'}`;
    }).join('\n');
    const contextSectionData = contextData[section];
    
       dispatch(generateNarrative(modelId, assessmentUuid, section, contextSectionData,narrativePrompt));
  };



const handleNarrativeChange = (modelId,section, narrative) => {
    const updatedSectionData = { ...contextData[section], narrative };
    dispatch(updateContextData(modelId, section, 'narrative', narrative));
    debouncedSave(section, updatedSectionData);
  };
  const toggleEditMode = (section) => {
    setEditMode(prev => ({ ...prev, [section]: !prev[section] }));
  };
  const toTitleCase = (str) => {
    // First, handle known acronyms
    str = str.replace(/\b(EU|ROC|AUC)\b/g, match => match.toUpperCase());
  
    // Next, format camelCase and PascalCase
    return str
      .replace(/([a-z])([A-Z])/g, '$1 $2') // Insert space before each capital in camelCase
      .replace(/([A-Z]+)([A-Z][a-z])/g, '$1 $2') // Handle sequences of capitals followed by lowercase
      .replace(/\b([Aa]nd)\b/g, '&')
      .split(/\s+/) // Split the string by spaces to capitalize each word
      .map(word => {
        if (['EU', 'ROC', 'AUC'].includes(word)) {
          return word; // Return the acronym unchanged
        } else {
          return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase(); // Capitalize each word
        }
      })
      .join(' ')
      .trim(); // Trim any extra spaces
  };
  const sections = Object.keys(contextForm);
  const sectionLabels = sections.map(section => toTitleCase(section));

  const handleMouseEnter = (fieldName) => {
    setHoverStates(prev => ({ ...prev, [fieldName]: true }));
};

const handleMouseLeave = (fieldName) => {
    setHoverStates(prev => ({ ...prev, [fieldName]: false }));
};
  

 
    return (
      <Paper style={{ padding: '20px', margin: '20px' }}>
          <Paper elevation={2} style={{ padding: '20px', marginBottom: '16px' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Typography variant="h5" style={{ padding: '8px 16px', display: 'flex', alignItems: 'center' }}>
                      Risk & Control Environment
                  </Typography>
                  <AutosaveIndicator isSaving={isSaving} isSaved={isSaved} />
              </div>
              {isLoading ? (
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'right', height: '50px' }}>
                      <CircularProgress />
                  </div>
              ) : (
                <TabStepper labels={sectionLabels} activeTab={activeTab} onStepChange={handleStepChange}/>  
      
              )}
              
          </Paper>
      {/* <TabStepper labels={sectionLabels} activeTab={activeTab} onStepChange={(stepLabel) => handleStepChange(sections[sectionLabels.indexOf(stepLabel)])} /> */}
     
      <Box sx={{ mt: 3 }}>
        {sections.map((section,index) => (
          <Box key={section} sx={{ mb: 4, display: activeTab === index ? 'block' : 'none' }}>
           
            {contextForm[section].map((field) => {
              const fieldValue = contextData[section] && contextData[section][field.name] ? contextData[section][field.name] : (field.type === 'multiselect' ? [] : '');
              if (field.type === 'dropdown' || field.type === 'multiselect') {
                return (
                  <FormControl fullWidth margin="normal" variant="outlined" key={field.name}>
                    <InputLabel>{toTitleCase(field.name)}</InputLabel>
                    <Select
                    multiple={field.type === 'multiselect'}
                    value={fieldValue}
                    onChange={(e) => handleChange(section, field.name, e.target.value)}
                    input={<OutlinedInput label={toTitleCase(field.name)} id={field.name} />}
                    renderValue={selected => Array.isArray(selected) ? selected.map(item => (
                        <Chip key={item} label={item} />
                      )) : selected} // Only execute map if it's a multiselect to prevent type errors
                      onMouseEnter={() => handleMouseEnter(field.name)}
                      onMouseLeave={() => handleMouseLeave(field.name)}
                      endAdornment={
                          hoverStates[field.name] && (
                              <Tooltip title={field.description || 'No additional information available'}>
                                  <IconButton edge="end" style={{ color: '#1976d2', marginRight: '12px' }}>
                                      <HelpOutlineOutlinedIcon />
                                  </IconButton>
                              </Tooltip>
                          )
                      }
                 >
                    {field.options?.map((option) => (
                      <MenuItem key={option} value={option}>{option}</MenuItem>
                    ))}
                  </Select>
                  </FormControl>
                );
              } else {
                return (
                  <TextField
                    fullWidth
                    label={field.name}
                    variant="outlined"
                    margin="normal"
                    key={field.name}
                    value={fieldValue}
                    onChange={(e) => handleChange(section, field.name, e.target.value)}
                    multiline={field.type === 'textarea'} // changed from 'text' to 'textarea' for multiline capability
                    onMouseEnter={() => handleMouseEnter(field.name)}
                    onMouseLeave={() => handleMouseLeave(field.name)}
                    endAdornment={
                        hoverStates[field.name] && (
                            <Tooltip title={field.description || 'No additional information available'}>
                                <IconButton edge="end" style={{ color: '#1976d2', marginRight: '12px' }}>
                                    <HelpOutlineOutlinedIcon />
                                </IconButton>
                            </Tooltip>
                        )
                    }
                    />
                );
              }
            })}
                       
                       <Card variant="outlined" sx={{ mt: 2, position: 'relative' }}>
                        <CardContent sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Typography variant="h6" sx={{ flexGrow: 1 }}>
                            Narrative for {toTitleCase(section)}
                            </Typography>
                            <IconButton
                            onClick={() => toggleEditMode(section)}
                            sx={{ ml: 'auto', color: 'action.active' }}
                            >
                            {editMode[section] ? <CheckIcon /> : <EditIcon />}
                            </IconButton>
                        </CardContent>
                        {editMode[section] ? (
                            <TextField
                            fullWidth
                            multiline
                            variant="outlined"
                            minRows={5}
                            value={contextData[section]?.narrative || ''}
                            onChange={(e) => handleNarrativeChange(modelId, section, e.target.value)}
                            placeholder={`To generate a narrative, first fill out the ${toTitleCase(section)} form.`}
                            sx={{ margin: 2 }}
                            />
                        ) : (
                            <Typography sx={{ margin: 2 }}>
                            {contextData[section]?.narrative || `No narrative generated yet. Fill out the details to generate a narrative for ${section}.`}
                            </Typography>
                        )}
                        <Button
                            variant="outlined"
                            startIcon={<SmartToyOutlinedIcon />}
                            onClick={() => handleGenerateNarrative(section)}
                            sx={{ float: 'left', mt: 2, mb: 2, ml: 2 , mr:2}}
                        >
                            {contextData[section]?.narrative ? 'Re-Generate' : 'Generate Narrative'}
                        </Button>
                        </Card>




          </Box>
        ))}
      </Box>

      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)}>
                <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
    </Paper>
  );
  
};

export default ContextAssessment;

