import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
   Typography, IconButton, TextField, Accordion, AccordionSummary, AccordionDetails,Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, Snackbar, Alert
} from '@mui/material';

import {
  Edit as EditIcon, Delete as DeleteIcon,
   Check as CheckIcon,ExpandMore as ExpandMoreIcon, ExpandLess as ExpandLessIcon, Save as SaveIcon

} from '@mui/icons-material';
//import AutosaveIndicator from './AutoSaveIndicator';
//import useSaveOperation from './useSaveOperation';
import { generateSecurityRisks, saveSecurityRisk,fetchSecurityRisks, discardSecurityRisk } from './redux/actions/securityActions';
import loadSecurityRisks from './loadSecurityRisks';
import IconWithProgress from './IconWithProgress';

const SecurityRiskCatalogue = ({ modelId, assessmentUuid}) => {
  const dispatch = useDispatch();
  const securityRisks = useSelector(state => state.security.models[modelId]?.securityRisks || []);
  const assessmentData = useSelector(state => state.security.models[modelId]?.assessmentData || {});

  const [expanded, setExpanded] = useState(false);
  //const [loading, setLoading] = useState(false);
  const isLoading = useSelector(state => state.security.loading);
  const [editingRiskId, setEditingRiskId] = useState(null);
  const [editedRiskDetails, setEditedRiskDetails] = useState({});
  //const { isSaving, isSaved,  setIsSaved } = useSaveOperation();
//handleSaveOperation,

const [openDialog, setOpenDialog] = useState(false);
const [currentRiskId, setCurrentRiskId] = useState(null);
const [snackbarOpen, setSnackbarOpen] = useState(false);
const [snackbarMessage, setSnackbarMessage] = useState('');
const [snackbarSeverity, setSnackbarSeverity] = useState('info');




  const handleChangePanel = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  useEffect(() => {
    console.log("Initiating fetch of security risks...");
    dispatch(fetchSecurityRisks(modelId, assessmentUuid, true));
}, [dispatch, modelId, assessmentUuid]);




  const groupedRisks = securityRisks.length > 0 ? securityRisks.reduce((acc, risk) => {
    if (risk && risk.category) { // Make sure risk and risk.category are defined
      acc[risk.category] = acc[risk.category] || [];
      acc[risk.category].push(risk);
    }
    return acc;
  }, {}) : {};

  const handleEditRisk = useCallback((risk) => {
    setEditingRiskId(risk.risk_id);
    setEditedRiskDetails({
      risk_name: risk.risk_name,
      category: risk.category,
      threat_level: risk.threat_level,
      vulnerability_level: risk.vulnerability_level,
      asset_value: risk.asset_value
    });
  }, []);

  const handleChangeDetail = (field, value) => {
    setEditedRiskDetails(prev => ({ ...prev, [field]: value }));
  };

  const resetEditing = useCallback(() => {
    setEditingRiskId(null);
    setEditedRiskDetails({});
  }, []);



  const handleSaveRisk = useCallback((risk = null) => {
    const riskDetails = editingRiskId ? {
      // When editing, use edited fields
      risk_id: editingRiskId,
      risk_name: editedRiskDetails.risk_name,
      category: editedRiskDetails.category,
      threat_level: editedRiskDetails.threat_level,
      vulnerability_level: editedRiskDetails.vulnerability_level,
      asset_value: editedRiskDetails.asset_value,
      justification: editedRiskDetails.justification,
    } : {
      // When saving directly, use the provided risk object
      ...risk,
      risk_id: risk.risk_id || '' // Handle new risks without an ID
    };
  
    console.log(editingRiskId ? 'Updating existing risk:' : 'Saving new risk:', riskDetails);
  
    dispatch(saveSecurityRisk(modelId, assessmentUuid, riskDetails));
    resetEditing(); // Consider the logic of resetting here based on your requirements
  }, [modelId, assessmentUuid, editingRiskId, editedRiskDetails, resetEditing, dispatch]);
  




  const handleRefreshRisks = () => {
   // setLoading(true);
    const securityData = loadSecurityRisks();
    dispatch(generateSecurityRisks(modelId,assessmentData, securityData))
      //.finally(() => setLoading(false));
  };

  const handleOpenDialog = (riskId) => {
    setCurrentRiskId(riskId);
    setOpenDialog(true);
  };
  
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  
  const handleDiscardRisk = () => {
    if (currentRiskId) {
      dispatch(discardSecurityRisk(modelId, currentRiskId))
        .then(() => {
          setSnackbarMessage('Risk discarded successfully.');
          setSnackbarSeverity('success');
          setSnackbarOpen(true);
          handleCloseDialog();
        })
        .catch(error => {
          setSnackbarMessage(`Error discarding risk: ${error.message}`);
          setSnackbarSeverity('error');
          setSnackbarOpen(true);
        });
    }
  };
  
  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

const renderRisksByCategory = (categoryRisks) => (
  <TableContainer component={Paper}>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Risk Name</TableCell>
          <TableCell>Threat Level</TableCell>
          <TableCell>Vulnerability Level</TableCell>
          <TableCell>Asset Value</TableCell>
          <TableCell>Actions</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {categoryRisks.map(risk => (
          <TableRow key={risk.risk_id}>
            <TableCell>
              {editingRiskId === risk.risk_id ? (
                <TextField
                  value={editedRiskDetails.risk_name}
                  onChange={e => handleChangeDetail('risk_name', e.target.value)}
                  fullWidth
                />
              ) : risk.risk_name}
            </TableCell>
            <TableCell>
              {editingRiskId === risk.risk_id ? (
                <TextField
                  value={editedRiskDetails.threat_level}
                  onChange={e => handleChangeDetail('threat_level', e.target.value)}
                  fullWidth
                />
              ) : risk.threat_level}
            </TableCell>
            <TableCell>
              {editingRiskId === risk.risk_id ? (
                <TextField
                  value={editedRiskDetails.vulnerability_level}
                  onChange={e => handleChangeDetail('vulnerability_level', e.target.value)}
                  fullWidth
                />
              ) : risk.vulnerability_level}
            </TableCell>
            <TableCell>
              {editingRiskId === risk.risk_id ? (
                <TextField
                  value={editedRiskDetails.asset_value}
                  onChange={e => handleChangeDetail('asset_value', e.target.value)}
                  fullWidth
                />
              ) : (
                risk.asset_value || 'Choose'  // Providing a fallback display text
              )}
            </TableCell>
            <TableCell>
              {editingRiskId === risk.risk_id ? (
                <IconButton onClick={handleSaveRisk}><CheckIcon /></IconButton>
              ) : (
                <IconButton onClick={() => handleEditRisk(risk)}><EditIcon /></IconButton>
              )}
              <IconButton onClick={() => handleOpenDialog(risk.risk_id)} style={{ color: 'red' }}><DeleteIcon /></IconButton>
              <IconButton onClick={() => handleSaveRisk(risk)}><SaveIcon /></IconButton>

            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
);

return (
    <div style={{ margin: '16px' }}>
      <Paper elevation={2} style={{ padding: '20px', marginBottom: '16px' }}>
      <Typography variant="h6" style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
  Security Risks

   {isLoading ? (
            <IconWithProgress isLoading={false} />
          ) : (
            <IconWithProgress onClick={handleRefreshRisks} isLoading={false} />
          )}
</Typography>
 {/* <AutosaveIndicator isSaving={isSaving} isSaved={isSaved} setIsSaved={setIsSaved} /> */}
      </Paper>
        
      {Object.entries(groupedRisks).map(([category, categoryRisks]) => (
        <Accordion key={category} expanded={expanded === category} onChange={handleChangePanel(category)} style={{ marginBottom: '16px' }}>
           <AccordionSummary
            expandIcon={expanded === category ? <ExpandLessIcon /> : <ExpandMoreIcon />}
    >
           <Typography style={{ fontWeight: 'bold' }}>{category}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {renderRisksByCategory(categoryRisks)}
          </AccordionDetails>
        </Accordion>
      ))}
      <Dialog
      open={openDialog}
      onClose={handleCloseDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"Confirm Discard"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Are you sure you want to discard this security risk?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseDialog} color="primary">
          Cancel
        </Button>
        <Button onClick={handleDiscardRisk} color="primary" autoFocus>
          Discard
        </Button>
      </DialogActions>
    </Dialog>

    {/* Snackbar for Feedback */}
    <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
      <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
        {snackbarMessage}
      </Alert>
    </Snackbar>
    </div>
  );
};

export default SecurityRiskCatalogue;
