import { createAction } from '@reduxjs/toolkit';


export const setThemes = createAction('SET_THEMES');
export const addTheme = createAction('ADD_THEME');
export const updateTheme = createAction('UPDATE_THEME');
export const deleteTheme = createAction('DELETE_THEME');

export const fetchThemes = () => async (dispatch) => {
    try {
        const response = await fetch(`/.netlify/functions/riskThemesCrud`);
        const data = await response.json();
        console.log('themes from actions', data);
        dispatch(setThemes(data || []));
    } catch (error) {
        console.error("Error fetching risk themes:", error);
    }
};

export const createTheme = (themeName) => {
    return async (dispatch) => {
        const response = await fetch(`/.netlify/functions/riskThemesCrud`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ themeName }),
        });
    
        const data = await response.json();
        if (!response.ok) {
            throw new Error(data.message || 'Could not create theme.');
        }
       
        dispatch(fetchThemes());
    };
    };

    export const editTheme = (themeId, updatedThemeName) => async (dispatch) => {
        try {
            const response = await fetch(`/.netlify/functions/riskThemesCrud/${themeId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ updatedThemeName }),
            });
    
            if (!response.ok) {
                throw new Error('Could not edit theme.');
            }
            
            dispatch(updateTheme({ id: themeId, name: updatedThemeName }));
            dispatch(fetchThemes());
        } catch (error) {
            console.error('Error:', error);
            
        }
    };
    export const removeTheme = (themeId) => async (dispatch) => {
        try {
            const response = await fetch(`/.netlify/functions/riskThemesCrud/${themeId}`, {
                method: 'DELETE',
            });
    
            if (!response.ok) {
                throw new Error('Could not delete theme.');
            }
            
            dispatch(deleteTheme(themeId));
            dispatch(fetchThemes());
        } catch (error) {
            console.error('Error:', error);
            
        }
    };