
import {
    RESET_SECURITY_STATE,
  UPDATE_SECURITY_ASSESSMENT,
  //SAVE_SECURITY_ASSESSMENT_SUCCESS,
  SAVE_SECURITY_ASSESSMENT_FAILURE,
  LOAD_SECURITY_ASSESSMENT,
  SET_ACTIVE_IDS,
  GENERATE_SECURITY_RISKS_START,
  GENERATE_SECURITY_RISKS_SUCCESS,
  GENERATE_SECURITY_RISKS_FAILURE,
  FETCH_SECURITY_ASSESSMENT_START,
  FETCH_SECURITY_ASSESSMENT_SUCCESS,
  FETCH_SECURITY_ASSESSMENT_FAILURE, 
  SAVE_SECURITY_RISK_START, 
  SAVE_SECURITY_RISK_SUCCESS, 
  SAVE_SECURITY_RISK_FAILURE, 
  FETCH_SECURITY_RISKS_START,
  FETCH_SECURITY_RISKS_SUCCESS,
  FETCH_SECURITY_RISKS_FAILURE,
  DISCARD_SECURITY_RISK_START,
  DISCARD_SECURITY_RISK_SUCCESS,
  DISCARD_SECURITY_RISK_FAILURE,
  GENERATE_SECURITY_CONTROLS_START,
  GENERATE_SECURITY_CONTROLS_SUCCESS,
  GENERATE_SECURITY_CONTROLS_FAILURE,
  SAVE_SECURITY_CONTROL_START,
  SAVE_SECURITY_CONTROL_SUCCESS,
  SAVE_SECURITY_CONTROL_FAILURE,
  //FETCH_SECURITY_CONTROLS_START, 
  FETCH_SECURITY_CONTROLS_SUCCESS,
  //FETCH_SECURITY_CONTROLS_FAILURE

} from '../actions/actionTypes';

const initialState = {
  models: {},
  activeModelId: null,
  activeAssessmentUuid: null,
  loading: false,
  errorMessage: '',
};

const securityReducer = (state = initialState, action) => {
  switch (action.type) {

    case RESET_SECURITY_STATE:
        return initialState;
        
      case UPDATE_SECURITY_ASSESSMENT: {
          const { modelId, assessmentData } = action.payload;
          return {
              ...state,
              models: {
                  ...state.models,
                  [modelId]: {
                      ...state.models[modelId],
                      assessmentData: {
                          ...state.models[modelId]?.assessmentData,
                          ...assessmentData
                      }
                  }
              }
          };
      }

      case SAVE_SECURITY_ASSESSMENT_FAILURE:
          return {
              ...state,
              saveStatus: 'error',
              errorMessage: action.payload
          };
      case LOAD_SECURITY_ASSESSMENT: {
          const { modelId, assessmentData } = action.payload;
          return {
              ...state,
              models: {
                  ...state.models,
                  [modelId]: {
                      ...state.models[modelId],
                      assessmentData
                      
                  }
              }
          };
      }
      case SET_ACTIVE_IDS:
          return {
              ...state,
              activeModelId: action.payload.modelId,
              activeAssessmentUuid: action.payload.assessmentUuid
          };
      case GENERATE_SECURITY_RISKS_START:
          return {
              ...state,
              loading: true,
              errorMessage: ''
          };
      case GENERATE_SECURITY_RISKS_SUCCESS: {
          const { modelId, securityRisks } = action.payload;
          return {
              ...state,
              models: {
                  ...state.models,
                  [modelId]: {
                      ...state.models[modelId],
                      securityRisks
                      
                  }
              },
              loading: false
          };
      }
      case GENERATE_SECURITY_RISKS_FAILURE:
          return {
              ...state,
              loading: false,
              errorMessage: action.payload
          };
          case FETCH_SECURITY_ASSESSMENT_START:
  return { ...state, loading: true, errorMessage: '' };

case FETCH_SECURITY_ASSESSMENT_SUCCESS:

console.log ('payload at fetch sec asmt reducer',action.payload)
  return {
    ...state,
    models: {
      ...state.models,
      [action.payload.modelId]: {
        ...state.models[action.payload.modelId],
        assessmentData: action.payload.assessmentData
      }
    },
    loading: false
  };

case FETCH_SECURITY_ASSESSMENT_FAILURE:
  return { ...state, loading: false, errorMessage: action.payload };
  
  case SAVE_SECURITY_RISK_START:
    return {
      ...state,
      loading: true,
    };

    case SAVE_SECURITY_RISK_SUCCESS: {
        const { modelId, risk } = action.payload;
        const existingRisks = state.models[modelId]?.securityRisks || [];
    
        console.log('Current state risks:', existingRisks);
        console.log('Received risk to save/update:', risk);
    
        // Determine if this is a new risk or an update.
        let updatedRisks;
        if (risk.risk_id) {
          const riskIndex = existingRisks.findIndex(r => r.risk_id === risk.risk_id);
          if (riskIndex >= 0) {
            // Update existing risk with new details and set status to 'Updated'.
            updatedRisks = existingRisks.map((r, idx) =>
              idx === riskIndex ? { ...r, ...risk, status: 'Updated' } : r
            );
            console.log('Updating existing risk with ID:', risk.risk_id);
          } else {
            // Check if a risk with the same name exists but doesn't have an ID.
            const nameIndex = existingRisks.findIndex(r => r.risk_name === risk.risk_name && !r.risk_id);
            if (nameIndex >= 0) {
              updatedRisks = existingRisks.map((r, idx) =>
                idx === nameIndex ? { ...r, risk_id: risk.risk_id, ...risk, status: 'Saved' } : r
              );
              console.log('Adding ID to existing risk by name:', risk.risk_name);
            } else {
              // If no matching ID or name found, add the risk as new.
              updatedRisks = [...existingRisks, { ...risk, status: 'Saved' }];
              console.log('Adding new risk with new ID from server:', risk.risk_id);
            }
          }
        } else {
          // No ID provided, find by name to update or add as new.
          const nameIndex = existingRisks.findIndex(r => r.risk_name === risk.risk_name);
          if (nameIndex >= 0) {
            // Update the existing entry by name.
            updatedRisks = existingRisks.map((r, idx) =>
              idx === nameIndex ? { ...r, ...risk, status: 'Updated' } : r
            );
            console.log('Updating existing risk by name:', risk.risk_name);
          } else {
            // Completely new risk.
            updatedRisks = [...existingRisks, { ...risk, status: 'Saved' }];
            console.log('Adding completely new risk:', risk.risk_name);
          }
        }
    
        console.log('Updated risks array:', updatedRisks);
    
        return {
          ...state,
          models: {
            ...state.models,
            [modelId]: {
              ...state.models[modelId],
              securityRisks: updatedRisks,
              loading:false
            }
          }
        };
    }
    
      
  case SAVE_SECURITY_RISK_FAILURE:
    return {
      ...state,
      loading: false,
      error: action.payload,
    };
    case FETCH_SECURITY_RISKS_START:
      return { ...state, loading: true, error: null };
    
      // case FETCH_SECURITY_RISKS_SUCCESS:
      //  console.log ('security risks received from db at reducer', action.payload)
      // return {
      //   ...state,
      //   models: {
      //     ...state.models,
      //     [action.payload.modelId]: {
      //       ...state.models[action.payload.modelId],
      //       securityRisks: action.payload.risks,
      //       securityControls: state.models[action.payload.modelId]?.securityControls || {}
      //     }
      //   },
      //   loading: false
      // };

      case FETCH_SECURITY_RISKS_SUCCESS:
    const existingRisks = state.models[action.payload.modelId]?.securityRisks || [];
    const newRisks = action.payload.risks;
    const mergedRisks = [...existingRisks, ...newRisks.filter(nr => !existingRisks.find(er => er.risk_id === nr.risk_id))];
    console.log('Merging existing and fetched risks:', mergedRisks);

    return {
        ...state,
        models: {
            ...state.models,
            [action.payload.modelId]: {
                ...state.models[action.payload.modelId],
                securityRisks: mergedRisks,
                securityControls: state.models[action.payload.modelId]?.securityControls || {}
            }
        },
        loading: false
    };

    
      case FETCH_SECURITY_RISKS_FAILURE:
      return { ...state, loading: false, error: action.payload };
    
      case DISCARD_SECURITY_RISK_START:
        return { ...state, loading: true };
    
      case DISCARD_SECURITY_RISK_SUCCESS:
        const { modelId, riskId } = action.payload;
        const updatedRisks = state.models[modelId].securityRisks.filter(risk => risk.risk_id !== riskId);
        return {
          ...state,
          models: {
            ...state.models,
            [modelId]: {
              ...state.models[modelId],
              securityRisks: updatedRisks,
              
            }
          },
          loading: false
        };
      case DISCARD_SECURITY_RISK_FAILURE:
        return { ...state, loading: false, error: action.error };

        case GENERATE_SECURITY_CONTROLS_START:
  return { ...state, loading: true };
  
  case GENERATE_SECURITY_CONTROLS_SUCCESS:
    return {
      ...state,
      models: {
        ...state.models,
        [action.modelId]: {
          ...state.models[action.modelId],
          securityControls: {
            ...state.models[action.modelId].securityControls,
            ...action.controlsPerRisk.reduce((acc, curr) => ({
              ...acc,
              [curr.riskId]: curr.controls
            }), {})
          }
        }
      },
      loading: false
    };
  
  case GENERATE_SECURITY_CONTROLS_FAILURE:
    return { ...state, loading: false, error: action.error };

    case FETCH_SECURITY_CONTROLS_SUCCESS:{
      const { modelId, riskId, controls } = action.payload;
      return {
        ...state,
        models: {
          ...state.models,
          [modelId]: {
            ...state.models[modelId],
            securityControls: {
              ...state.models[modelId].securityControls,
              [riskId]: controls
            }
          }
        }
      };
    }

    case SAVE_SECURITY_CONTROL_START:
      return { ...state, loading: true };
    
      // case SAVE_SECURITY_CONTROL_SUCCESS: {
      //   const { control, modelId, riskId } = action.payload;

      //   console.log ('payload to save sec control reducer', action.payload)
      //   const existingControls = state.models[modelId]?.securityControls[riskId] || [];
        
      //   // Check if control is new or existing
      //   const index = existingControls.findIndex(c => c.controlId === control.controlId);
      //   let updatedControls;
        
      //   if (index !== -1) {
      //     // Update existing control in the array
      //     updatedControls = existingControls.map((c, i) => i === index ? {...c, ...control,  status: 'updated'} : c);
      //   } else {
      //     // Add new control to the array
      //     updatedControls = [...existingControls, {...control, status: 'saved'}];
      //   }
      
      //   return {
      //     ...state,
      //     models: {
      //       ...state.models,
      //       [modelId]: {
      //         ...state.models[modelId],
      //         securityControls: {
      //           ...state.models[modelId].securityControls,
      //           [riskId]: updatedControls
      //         }
      //       }
      //     },
      //     loading: false
      //   };
      // }

      case SAVE_SECURITY_CONTROL_SUCCESS: {
        const { control, modelId, riskId } = action.payload;
        const existingControls = state.models[modelId]?.securityControls[riskId] || [];
      
        // Find the control if it exists
        const index = existingControls.findIndex(c => c.controlId === control.controlId);
        let updatedControls;
      
        if (index !== -1) {
          // Control exists, check its current status and update accordingly
          updatedControls = existingControls.map((c, i) => {
            if (i === index) {
              if (c.status === 'generated') {
                return { ...c, ...control, status: 'saved' };  // Newly saved control
              } else if (c.status === 'saved') {
                return { ...c, ...control, status: 'updated' }; // Existing control updated
              }
            }
            return c; // No changes to other controls
          });
        } else {
          // Control doesn't exist, add as new with 'saved' status
          updatedControls = [...existingControls, {...control, status: 'saved'}];
        }
      
        return {
          ...state,
          models: {
            ...state.models,
            [modelId]: {
              ...state.models[modelId],
              securityControls: {
                ...state.models[modelId].securityControls,
                [riskId]: updatedControls
              }
            }
          },
          loading: false
        };
      }
      
      
    
    case SAVE_SECURITY_CONTROL_FAILURE:
      return { ...state, loading: false, error: action.payload };

      default:
          return state;
  }
};

export default securityReducer;
