import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  TableContainer, Table, TableHead, TableRow, TableCell,
  TableBody, Tab, Tabs,IconButton, Snackbar, Alert, Typography, Paper, Collapse, Badge, Box
} from '@mui/material';
import { Save as SaveIcon,Edit as EditIcon, Delete as DeleteIcon, ExpandMore as ExpandMoreIcon, ExpandLess as ExpandLessIcon } from '@mui/icons-material';
import { checkAndGenerateSecurityControls, saveSecurityControl, fetchSecurityControls } from './redux/actions/securityActions';
import IconWithProgress from './IconWithProgress';

function SecurityActionPlan({ modelId, assessmentUuid}) {
  const dispatch = useDispatch();
  const [expanded, setExpanded] = useState(null);
  const [activeTab, setActiveTab] = useState(0);
  const tabStatus = ['generated', 'saved', 'discarded'];
  const securityRisks = useSelector(state => state.security.models[modelId]?.securityRisks || []);
  const securityControls = useSelector(state => state.security.models[modelId]?.securityControls || {});
  const isLoading = useSelector(state => state.security.isLoading);

  



useEffect(() => {
  Promise.all(securityRisks.map(risk =>
    dispatch(fetchSecurityControls(modelId, risk.risk_id))
  )).then(() => {
    // After all fetches are done, check if any risk still lacks controls and needs generation
    dispatch(checkAndGenerateSecurityControls(modelId));
  });
}, [dispatch, modelId, securityRisks]);


  const handleExpand = (risk_id) => {
    setExpanded(expanded === risk_id ? null : risk_id);
  };
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleSaveControl = (control, riskId) => {
    const controlData=control;
    console.log ('ControlData to dispatch to save sec ctrls', control)
    dispatch(saveSecurityControl(controlData, modelId, riskId, assessmentUuid));
  };

  // const countSavedControls = (riskId) => {
  //   if (!securityControls[riskId]) return 0;  // Return 0 if no controls are found for this risk
  //   return securityControls[riskId].filter(control => control.status === 'saved').length;
  // };
  const countControls = (riskId, status) => {
    return (securityControls[riskId] || []).filter(control => control.status === status).length;
  };
  

return (
    <div style={{ margin: '16px' }}>
      <Paper elevation={2} style={{ padding: '20px', marginBottom: '16px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h6">Security Risk Action Plan</Typography>
        <IconWithProgress isLoading={isLoading} />
      </Paper>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight: 'bold' }}>Risk Name</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>Controls</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {/* {securityRisks.map(risk => (
              <React.Fragment key={risk.risk_id}>
                <TableRow>
                  <TableCell>{risk.risk_name}</TableCell>
                  <TableCell>
                    <IconButton onClick={() => handleExpand(risk.risk_id)}>
                      {expanded === risk.risk_id ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </IconButton>
                  </TableCell>
                  <TableCell>
                    {/* Placeholder for future actions
                  </TableCell>
                </TableRow>
                {expanded === risk.risk_id && (
                  <TableRow>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={3}>
                      <Collapse in={true} timeout="auto" unmountOnExit>
                        <Tabs value={activeTab} onChange={handleTabChange} aria-label="Control Tabs">
                          <Tab label="Generated" />
                          <Tab
                            label={
                              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                Saved
                                <Badge color="primary" badgeContent={countSavedControls(risk.risk_id)} sx={{ ml: 2 }} />
                              </Box>
                            }
                          />
                            
                          <Tab label="Discarded" />
                        </Tabs>
                        {activeTab === 0 && (
                          <Table size="small" aria-label="controls">
                            <TableHead>
                              <TableRow>
                                <TableCell>Control Name</TableCell>
                                <TableCell align="right">Actions</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                            {(securityControls[risk.risk_id] || []).filter(control => control.status === 'generated').map(control => (
                                <TableRow key={control.controlId}>
                                  <TableCell component="th" scope="row">
                                    {control.name}
                                  </TableCell>
                                  <TableCell align="right">
                                  <IconButton  onClick={() => handleSaveControl(control, risk.risk_id)}><SaveIcon /> </IconButton>
                                    <IconButton><EditIcon /></IconButton>
                                    <IconButton><DeleteIcon /></IconButton>
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                           )}
                           {activeTab === 1 && (
                            <Table size="small" aria-label="controls">
                              <TableHead>
                                <TableRow>
                                  <TableCell>Control Name</TableCell>
                                  <TableCell align="right">Actions</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {(securityControls[risk.risk_id] || []).filter(control => control.status === 'saved').map(control => (
                                  <TableRow key={control.controlId}>
                                    <TableCell component="th" scope="row">
                                      {control.name} 
                                    </TableCell>
                                    <TableCell align="right">
                                      <IconButton><EditIcon /></IconButton>
                                      <IconButton><DeleteIcon /></IconButton>
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          )}
                        
                      </Collapse>
                    </TableCell>
                  </TableRow>
                )}
              </React.Fragment>
            ))} */}

        {securityRisks.map(risk => (
          <React.Fragment key={risk.risk_id}>
            <TableRow>
              <TableCell>
                <IconButton onClick={() => handleExpand(risk.risk_id)}>
                  {expanded === risk.risk_id ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
                {risk.risk_name}
              </TableCell>
              <TableCell>
                {/* Placeholder for future actions */}
              </TableCell>
            </TableRow>
            {expanded === risk.risk_id && (
              <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={3}>
                  <Collapse in={true} timeout="auto" unmountOnExit>
                  {/* <Tabs value={activeTab} onChange={handleTabChange} aria-label="Control Tabs">
                      <Tab 

                        label={
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            Generated
                            <Badge color="primary" badgeContent={countControls(risk.risk_id, 'generated')} sx={{ ml: 2 }} />
                          </Box>
                        }
                      />
                      <Tab 
                        label={
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            Saved
                            <Badge color="secondary" badgeContent={countControls(risk.risk_id, 'saved')} sx={{ ml: 2 }} />
                          </Box>
                        }
                      />
                      <Tab 
                        label={
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            Discarded
                            <Badge color="error" badgeContent={countControls(risk.risk_id, 'discarded')} sx={{ ml: 2 }} />
                          </Box>
                        
                        } 
                      />
                      </Tabs> */}
                      <Tabs value={activeTab} onChange={handleTabChange} aria-label="Control Tabs">
                          {tabStatus.map((status, index) => (
                            <Tab key={status} label={
                              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                {status.charAt(0).toUpperCase() + status.slice(1)}
                                <Badge color="primary" badgeContent={countControls(risk.risk_id, status)} sx={{ ml: 2 }} />
                              </Box>
                            } />
                          ))}
                        </Tabs>
                    <Table size="small" aria-label="controls">
                      <TableHead>
                        <TableRow>
                          <TableCell>Control Name</TableCell>
                          <TableCell align="right">Actions</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {(securityControls[risk.risk_id] || []).filter(control => control.status === tabStatus[activeTab]).map(control => (
                          <TableRow key={control.controlId}>
                            <TableCell component="th" scope="row">
                              {control.name}
                            </TableCell>
                            <TableCell align="right">
                              <IconButton onClick={() => handleSaveControl(control, risk.risk_id)}><SaveIcon /></IconButton>
                              <IconButton><EditIcon /></IconButton>
                              <IconButton><DeleteIcon /></IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </Collapse>
                </TableCell>
              </TableRow>
            )}
          </React.Fragment>
        ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Snackbar open={isLoading} autoHideDuration={6000} onClose={() => {}}>
        <Alert severity="info">
          Fetching controls...
        </Alert>
      </Snackbar>
    </div>
  );
}

export default SecurityActionPlan;

