import { createReducer } from '@reduxjs/toolkit';
import { setThemes, addTheme, updateTheme, deleteTheme } from '../actions/themeActions.js';

const themeReducer = createReducer([], {
    [setThemes]: (state, action) => {
        return action.payload;
    },
    [addTheme]: (state, action) => {
        state.push(action.payload);
    },
    [updateTheme]: (state, action) => {
        const index = state.findIndex(theme => theme.id === action.payload.id);
        if (index !== -1) {
            state[index] = action.payload;
        }
    },
    [deleteTheme]: (state, action) => {
        const index = state.findIndex(theme => theme.id === action.payload);
        if (index !== -1) {
            state.splice(index, 1);
        }
    },
});

export default themeReducer;
