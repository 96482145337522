import React from 'react';
import { Box } from '@mui/material';

const colors = {
  'critical': '#FF1744',  // Red
  'high': '#FFC107',      // Amber
  'significant': '#FF9800',  // Orange
  'moderate': '#9E9E9E',  // Grey
  'low': '#2196F3',      // Blue
  'negligible': '#4CAF50'  // Green
};

const RiskCounters = ({ riskCounts }) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      {Object.entries(riskCounts).map(([priority, count]) => {
        if (count > 0) {
          return (
            <Box key={priority} sx={{ display: 'inline-flex', alignItems: 'center', mr: 1 }}>
              <Box sx={{
                width: 24,
                height: 24,
                borderRadius: '50%',
                backgroundColor: colors[priority] || '#E0E0E0', // Default to light grey for undefined priorities
                color: '#fff',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: '0.75rem'
              }}>
                {count}
              </Box>
            </Box>
          );
        }
        return null;
      })}
    </Box>
  );
};

export default RiskCounters;
