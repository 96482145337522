import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, IconButton, Modal, Box } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useDispatch, useSelector } from 'react-redux';
import { createTheme, editTheme, removeTheme, fetchThemes } from './redux/actions/themeActions.js';
import OutlinedInput from '@mui/material/OutlinedInput';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CancelIcon from '@mui/icons-material/Cancel';

const RiskThemes = () => {
    const [newTheme, setNewTheme] = useState('');
    const [editingTheme, setEditingTheme] = useState(null);
    const [editedThemeValue, setEditedThemeValue] = useState('');
    const [modalOpen, setModalOpen] = useState(false);
    //const { modelId } = useParams();
    const dispatch = useDispatch();
    const themes = useSelector(state => state.themes);
    console.log(themes);

    useEffect(() => {
        dispatch(fetchThemes());
    }, [dispatch]);
    
    useEffect(() => {
        if (editingTheme !== null) {
            setEditedThemeValue(themes.find(theme => theme.id === editingTheme)?.name || '');
        }
    }, [editingTheme, themes]);

    const handleAddTheme = () => {
        dispatch(createTheme(newTheme));
        setNewTheme('');  // Reset the input field
        setModalOpen(false);
    };

    const handleEditTheme = (themeId) => {
        console.log(`Editing theme: ${themeId}`);
        dispatch(editTheme(themeId, editedThemeValue));
        setEditingTheme(null);  // Reset the editing state
    };

    const handleDeleteTheme = (themeId) => {
        console.log(`Editing theme: ${themeId}`);
        dispatch(removeTheme(themeId));
    };

 


    const modalBody = (
        <Box
            sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 400,
                bgcolor: 'background.paper',
                border: '2px solid #000',
                boxShadow: 24,
                p: 4,
            }}
        >
            <h2>Add a New Risk Theme</h2>
            <OutlinedInput
                value={newTheme}
                onChange={e => setNewTheme(e.target.value)}
                placeholder="New Risk Theme"
                sx={{ height: 30, marginBottom: 2 }}  // Adjust height and margin as needed
            />
            <IconButton onClick={handleAddTheme} color="primary">
                <AddCircleOutlineIcon />
            </IconButton>
            <IconButton onClick={() => setModalOpen(false)}>
                <CancelIcon />
            </IconButton>
        </Box>
    );
    console.log(themes);
    return (
        <div>
            <h1>Risk Themes</h1>
            <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                <Button variant="contained" color="primary" onClick={() => setModalOpen(true)}>Add Theme</Button>
            </div>
            
            <Modal
                open={modalOpen}
                onClose={() => setModalOpen(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                {modalBody}
            </Modal>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Edit</TableCell>
                            <TableCell>Delete</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {themes.map(theme => (
                            <TableRow key={theme.theme_id}>
                                <TableCell>
                                    {editingTheme === theme.theme_id ? (
                                        <input type="text" value={editedThemeValue} onChange={e => setEditedThemeValue(e.target.value)} />
                                    ) : (
                                        theme.name
                                    )}
                                </TableCell>
                                <TableCell>
                                    {editingTheme === theme.theme_id  ? (
                                        <IconButton onClick={() => handleEditTheme(theme.theme_id)}>
                                            <EditIcon />
                                        </IconButton>
                                    ) : (
                                        <IconButton onClick={() => {
                                            //setEditingTheme(null);
                                            setEditingTheme(theme.theme_id );
                                            setEditedThemeValue(theme.name);
                                        }}>
                                            <EditIcon />
                                        </IconButton>
                                    )}
                                </TableCell>
                                <TableCell>
                                    <IconButton onClick={() => handleDeleteTheme(theme.theme_id)}> 
                                        <DeleteIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}

export default RiskThemes;