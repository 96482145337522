  import React, { useState, useMemo, useEffect} from 'react';
  import RiskEvaluationForm from './RiskEvaluationForm.js';
  import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
  import ExpandLessIcon from '@mui/icons-material/ExpandLess';
  import {Typography, Collapse, IconButton, Button,Tooltip, Popover, Grow, Select, MenuItem} from '@mui/material';
  import {

      Paper,
      TableContainer,
      Table,
      TableHead,
      TableBody,
      TableCell,
      TableRow,
     
    
  } from '@mui/material';
  import SmartToySharpIcon from '@mui/icons-material/SmartToySharp';
  
  import {fetchRisksForModel,setRiskEvaluation, setRiskPriority,  updateRiskPriority} from './redux/actions/riskActions.js';
  import { useSelector, useDispatch } from 'react-redux';
  import AutosaveIndicator from './AutoSaveIndicator';
  import useSaveOperation from './useSaveOperation';
  import IconWithProgress from './IconWithProgress.js';


  const RiskEvaluation = ({ modelId, assessmentUuid, sectionName }) => {
   // const themes = useSelector((state) => state.themes);
    const dispatch = useDispatch();
    const sectionRisks = useSelector(state => state.risks.models[modelId]?.riskFormData[sectionName]?.sectionRisks || []);
    const { isSaving, isSaved, handleSaveOperation } = useSaveOperation();
         
    const savedRisks = useMemo(() => {
      return sectionRisks.filter(risk => risk.status === 'Saved');
    }, [sectionRisks]);
   
  
    
    useEffect(() => {
      if (modelId && assessmentUuid) {
        
        dispatch(fetchRisksForModel({ modelId, assessmentUuid, sectionName }));
      }
    }, [dispatch, modelId, assessmentUuid, sectionName]);
    
     if (!sectionRisks.length) {
      return <div><Typography variant="h6">No Risks Saved</Typography></div>;
    }

    return (
      <div style={{ margin: '16px' }}>
        <Paper elevation={2} style={{ padding: '20px', marginBottom: '16px' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
        <Typography variant="h6" style={{ flexGrow: 0, marginRight: '16px' }}>Risk Analysis & Evaluation</Typography>

          <AutosaveIndicator isSaving={isSaving} isSaved={isSaved} />
          
        </div>
        </Paper>
        <TableContainer component={Paper} style={{ overflowX: 'auto' }}>
          <Table>
            <TableHead style={{ backgroundColor: '#e0e0e0' }}>
              <TableRow>
                <TableCell style={{ fontWeight: 'bold' }}>Risk Name</TableCell>
                <TableCell style={{ fontWeight: 'bold' }}>Suggested Evaluation</TableCell>
                <TableCell style={{ fontWeight: 'bold' }}>Rationale</TableCell>
                <TableCell style={{ fontWeight: 'bold' }}>Update Priority</TableCell>
                <TableCell></TableCell> {/* Empty for expand/collapse icon */}
              </TableRow>
            </TableHead>
            <TableBody>
              {savedRisks.map((risk) => (
                <RiskAccordion
                  key={`${risk.category}-${risk.riskName}`}
                  risk={risk}
                  modelId={modelId}
                  assessmentUuid={assessmentUuid}
                  sectionName={sectionName}
                  handleSaveOperation={handleSaveOperation}
            />
              ))}
              
            </TableBody>
          </Table>
        </TableContainer>
      
    </div>
    );
  };


  const RiskAccordion = ({risk, modelId, assessmentUuid,handleSaveOperation, sectionName }) => {
    const [expanded, setExpanded] = useState(false);
    const themes = useSelector((state) => state.themes);
    const dispatch = useDispatch();
    //const [loading, setLoading] = useState(false); 
    const isLoading = useSelector(state => state.risks.models[modelId]?.riskFormData[sectionName]?.sectionRisks.find(r => r.riskId === risk.riskId)?.loading);

    const currentRisk = useSelector(state => state.risks.models[modelId]?.riskFormData[sectionName]?.sectionRisks.find(r => r.riskId === risk.riskId) || {}
    );



    const [anchorEl, setAnchorEl] = useState(null);

    const handlePriorityClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };
    const handleExpandClick = () => {
      setExpanded(!expanded);
      if (!expanded) {
        themes.forEach(theme => {
          // Dispatch the action without checking for existing evaluations
          dispatch(setRiskEvaluation(modelId, risk.riskId,risk.riskName, theme.theme_id, {}, sectionName));
          //dispatch(fetchRiskEvaluations(modelId, assessmentUuid));
        });
      }
    };

const open = Boolean(anchorEl);
const id = open ? 'simple-popover' : undefined;


const handleSetPriority = async (risk) => {
  console.log('Evaluating risk:', risk);
  //isLoading();  // Set loading to true when the operation starts

  const themeIds = Object.keys(risk.evaluationData || {});
  try {
    await Promise.all(themeIds.map(async (themeId) => {
      const numericThemeId = parseInt(themeId, 10);
      const themeName = themes.find(theme => theme.theme_id === numericThemeId)?.name;
      const evaluationData = risk.evaluationData[themeId].impact;

      // Dispatch and wait for each risk priority setting to complete
      dispatch(setRiskPriority(modelId, risk.riskId, sectionName, risk.riskName, numericThemeId, themeName, evaluationData));
    }));
  } catch (error) {
    console.error('Error setting priority:', error);
  } finally {
    //isLoading();  // Ensure loading is set to false when operation finishes
  }
};


const handlePriorityChange = (e) => {
  const newPriority = e.target.value;
      const riskId=risk.riskId
      dispatch(updateRiskPriority(modelId,riskId,sectionName, newPriority));
};

    const getPriorityColor = (priority) => {
      const colors = {
        'critical': 'red',
        'high': 'amber',
        'significant': 'orange',
        'moderate': 'grey',
        'low': 'blue',
        'negligible': 'green'
      };
      return colors[priority.toLowerCase()] || 'black';
    };
    
  
    const capitalizeFirstLetter = (string) => {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }
    

     return (
      <React.Fragment>
         <TableRow>
          <TableCell style={{ width: 'calc(100% - 40px)' }}>
          <Typography variant="body1">{risk.riskName}</Typography>
        </TableCell>

          <TableCell style={{ width: '150px' }}>
                    {isLoading ? (
                        <IconWithProgress isLoading={isLoading} />
                    ) : risk.priority ? (
                        <Typography style={{ color: getPriorityColor(risk.priority) }}>
                            {capitalizeFirstLetter(risk.priority)}
                        </Typography>
                    ) : (
                      <Button 
                      variant="outlined" 
                      startIcon={<SmartToySharpIcon />}
                      color="primary" 
                      onClick={() => handleSetPriority(risk)}
                      sx={{ mt: 2, ml: 1 }}
                    >
                     Evaluate Risk
                    </Button>
                    )}
                </TableCell>


        <TableCell style={{ width: '250px' }}>
            {risk.rationale && (
             <Tooltip title={risk.priority ? "Priority explained" : "You should first evaluate the risk, so I can generate a priority for you"}>
             <IconButton onClick={handlePriorityClick} disabled={!risk.priority}>
               <SmartToySharpIcon color={risk.priority ? "primary" : "disabled"} />
             </IconButton>
           </Tooltip>
            )}
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              TransitionComponent={Grow}
            >
              <div style={{ padding: '16px' }}>
                <Typography variant="h6">Rationale</Typography>
                <Typography>{currentRisk.rationale}</Typography>
              </div>
            </Popover>
          </TableCell>
        <TableCell style={{ width: '150px' }}>
  {risk.priority ? (
    <Select
      value={currentRisk.priority || "Select"} // Default value to "Select"
      onChange={handlePriorityChange}
      style={{ color: getPriorityColor(currentRisk.priority) }}
    >
            <MenuItem value={"Select"} disabled>Select</MenuItem> 
            <MenuItem value={'critical'}>Critical</MenuItem>
            <MenuItem value={'high'}>High</MenuItem>
            <MenuItem value={'significant'}>Significant</MenuItem>
            <MenuItem value={'moderate'}>Moderate</MenuItem>
            <MenuItem value={'low'}>Low</MenuItem>
            <MenuItem value={'negligible'}>Negligible</MenuItem>
            </Select>
                ) : (
                  <Typography variant="body1">Select</Typography> // Default text
                )}
              </TableCell>
       
        <TableCell style={{ width: '40px', textAlign: 'right' }}>
          <IconButton 
            onClick={handleExpandClick}
          >
            {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </TableCell>
        </TableRow>
        <TableRow>
          <TableCell colSpan={6}>
             <Collapse in={expanded} timeout="auto">
             <Paper elevation={0} style={{ padding: '16px' }}>
              {expanded && (
                <RiskEvaluationForm
                riskId={risk.riskId}  
                riskName={risk.riskName}
                sectionName={sectionName}
                  modelId={modelId}
                  assessmentUuid={assessmentUuid}
                  handleSaveOperation={handleSaveOperation}
                  />
              )}
                  <div style={{ textAlign: 'right', paddingTop: '16px'}}>
                   
                </div>
            </Paper>
           </Collapse>
          </TableCell>
        </TableRow>
 
      </React.Fragment>
    );
  }
  export default RiskEvaluation