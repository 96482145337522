import { createReducer } from '@reduxjs/toolkit';
import { setUser, clearUser } from '../actions/authActions';

const initialState = {
  user: null,
};

const authReducer = createReducer(initialState, {
  [setUser]: (state, action) => {
    console.log('setUser action payload:', action.payload); // Log the action payload
    return { ...state, user: action.payload };
  },
  [clearUser]: () => ({ user: null }),
});

export default authReducer;
