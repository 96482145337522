import React, { useState, useEffect } from 'react';

//
import {
  Paper, Table, TableBody, TableCell, TableContainer,
  TableHead, TableRow, Typography, Box, Grid, Collapse, Button
} from '@mui/material';
import RiskEvaluationForm from './RiskEvaluationForm';
import ControlEvaluationForm from './ControlEvaluation';
import { useSelector } from 'react-redux';
import useSaveOperation from './useSaveOperation';

const RisksBySource = ({ model, sectionName }) => {
  const [selectedRisk, setSelectedRisk] = useState(null);
  const [openControl, setOpenControl] = useState({}); // To handle collapsible sections for each control
  const savedControls = useSelector(state => state.controls.savedControls);
  const {  handleSaveOperation } = useSaveOperation();


  useEffect(() => {
    console.log('Current risks data to risks by source:', model.risks);
  }, [model.risks]);

  console.log ('selected risk in risk by source:', selectedRisk)
 //console.log ('sectionname received at risk by source', sectionName)

  const toggleControl = (controlId) => {
    setOpenControl(prev => ({
      ...prev,
      [controlId]: !prev[controlId]
    }));
  };

  const paperStyle = {
    elevation: 2,
    sx: {
      margin: '8px',
      border: '1px solid #e0e0e0',
      padding: '8px',
      borderRadius: '4px',
      overflow: 'hidden',
    }
  };

  const rowStyle = (isSelected) => ({
    backgroundColor: isSelected ? '#f0f0f0' : 'inherit',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#e0e0e0',
    },
  });

  return (
    <Paper elevation={2} sx={{ margin: '16px', padding: '8px', borderRadius: '4px' }}>
      <Typography variant="h5" sx={{ mb: 1 }}>{model.modelName} Risk Report</Typography>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight: 'bold' }}>Risk Name</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>Priority</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {model.risks.map((risk) => (
              <TableRow 
                key={risk.riskId} 
                hover 
                onClick={() => setSelectedRisk(risk)}
                sx={rowStyle(selectedRisk?.riskId === risk.riskId)}
              >
               <TableCell>{risk.riskName || "No name available"}</TableCell>
                <TableCell>{risk.priority || "Not set"}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {selectedRisk && (
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Paper {...paperStyle}>
              <Typography variant="h6">Risk Evaluation - {selectedRisk.riskName}</Typography>
              <Box sx={{ p: 2 }}>

                <RiskEvaluationForm
                  riskId={selectedRisk.riskId}
                  riskName={selectedRisk.riskName}
                  sectionName={sectionName}
                  modelId={model.modelId}
                  assessmentUuid={model.assessmentUuid}
                  handleSaveOperation={handleSaveOperation}
                />


              </Box>
            </Paper>
          </Grid>
          <Grid item xs={6}>
            <Paper {...paperStyle}>
              <Typography variant="h6">Controls</Typography>
              <TableContainer component={Paper}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ fontWeight: 'bold' }}>Control Name</TableCell>
                      <TableCell style={{ fontWeight: 'bold' }}>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                  {savedControls[selectedRisk.riskId]?.map((control, idx) => (
                      <React.Fragment key={idx}>
                        <TableRow hover onClick={() => toggleControl(control.controlId)}>
                          <TableCell>{control.name}</TableCell>
                          <TableCell>
                            <Button size="small">
                              {openControl[control.controlId] ? 'Hide Details' : 'Show Details'}
                            </Button>
                          </TableCell>
                        </TableRow>
                        {openControl[control.controlId] && (
                          <TableRow>
                            <TableCell colSpan={2}>
                              <Collapse in={openControl[control.controlId]} timeout="auto" unmountOnExit>
                                <ControlEvaluationForm
                                  riskId={selectedRisk.riskId}
                                  controlId={control.controlId}
                                  modelId={control.modelUuid}
                                  assessmentUuid={model.assessmentUuid}
                                  handleSaveOperation={handleSaveOperation}
                                />
                              </Collapse>
                            </TableCell>
                          </TableRow>
                        )}
                      </React.Fragment>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Grid>
        </Grid>
      )}
    </Paper>
  );
};

export default RisksBySource;



