  import React, { useState, useEffect } from 'react';
  import { useSelector, useDispatch } from 'react-redux';
  import ModelCardForm from './ModelCardForm.js';
  import RiskCardForm from './RiskCardForm.js';
  import RiskCatalogue from './RiskCatalogue.js';
  import RiskEvaluation from './RiskEvaluation.js';
  import RiskLandscape from './RiskLandscape.js';
  import ModelInventory from './ModelInventory.js';
  import ModelDetailsModal from './ModelDetailsModal.js';
  import AssessmentCompleted from './AssessmentCompleted.js';
  import RiskFormManager from './riskFormManager'; // Adjust path if necessary
  //import { BrowserRouter as Router, Route, Routes, useNavigate, Navigate } from 'react-router-dom';
  import { Route, Routes, useNavigate} from 'react-router-dom';

  import { AppBar, Toolbar, Typography, IconButton, Box, Grid, Hidden } from '@mui/material';
  import AccountCircle from '@mui/icons-material/AccountCircle';
  import Settings from '@mui/icons-material/Settings';
  import MenuIcon from '@mui/icons-material/Menu';
  import { styled } from '@mui/material/styles';
  import RiskThemes from './RiskThemes.js';
  import Audits from './Audits.js';
  import SideMenu from './SideMenu';
  import Logo from './images/logomain.png'; 
  import { Snackbar, Alert } from '@mui/material';
  //import { Provider } from 'react-redux';
  //import store ,{ persistor } from './redux/store';
  //import CircularProgress from '@mui/material/CircularProgress';
 // import { PersistGate } from 'redux-persist/integration/react';
  import ContextAssessment from './contextAssessment.js';

  //Auth
  import { initializeAuth, login, logout } from './redux/actions/authActions';
  //import AuthPage from './authPage'; 



  function App() {
    const [riskCardData, setRiskCardData] = useState(null);
    const [selectedModel, setSelectedModel] = useState(null);
    const user = useSelector((state) => state.auth.user);
    const dispatch=useDispatch(); 
    const navigate = useNavigate();
    const [selectedModelId, setSelectedModelId] = useState(null);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success'); // Could be 'error', 'warning', etc.
    const handleSnackbarClose = () => {
              setSnackbarOpen(false);
          };

          useEffect(() => {
            console.log ('initilizing auth....')
            dispatch(initializeAuth());
          }, [dispatch]);

          useEffect(() => {
            console.log('User state in App:', user);
          }, [user]);
    
      

        
    
    const handleFormSubmit = async (formData, formType, modelId) => {
      console.log("Submitting data:", formData);
      
      let response;
      try {
        if (formType === 'create') {
          console.log('entering create');
          response=await fetch('/.netlify/functions/SaveModelCard', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
          });
        } else if (formType === 'update'&& modelId) {
          console.log('model ID inside update condition',modelId);
          console.log('entering update');
          response=await fetch(`/.netlify/functions/SaveModelCard/${modelId}`, { // Change to your update endpoint
            method: 'PUT', 
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
          });
        } else if (formType === 'risk') {
          setRiskCardData(formData); 
          const payload = {
            ...formData,
            modelDescription: {
              ...formData.modelDescription,
              model_uuid: modelId,
            },
          };
          response=await fetch('/.netlify/functions/SaveRiskCard', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
          });
        }
        const responseBody = await response.json();

        if (responseBody.success) {
          setSnackbarMessage(responseBody.message);
          setSnackbarSeverity('success');
        } else {
          setSnackbarMessage(responseBody.message);
          setSnackbarSeverity('error');
        }
      } catch (error) {
        setSnackbarMessage(`An error occurred: ${error.message}`);
        setSnackbarSeverity('error');
      }
      setSnackbarOpen(true);
    };
    

    const handleRiskEvaluate = async (risk, evaluationData) => {
    console.log('Evaluating risk:', risk, 'with data:', evaluationData);
    };


    const handleSelectModel = (model) => {
      console.log('Model:', model);
      setSelectedModel(model);
      setSelectedModelId(model.model_uuid);
      
      console.log('Selected model:', selectedModel);
      console.log('Selected model ID:', selectedModelId);
    };



    const handleBackToInventory = () => {
      setSelectedModel(null);
      setRiskCardData(null);
      
    };
    const SettingsButton = () => {
       // This is correct because `SettingsButton` will be rendered within Router
  
      return (
        <IconButton color="#f1cf94" onClick={() => navigate('/risk-form-manager')}>
          <Settings />
        </IconButton>
      );
    };
  
    //ethereal ivory #E4E4DE, sophisticated sage #C4C5BA, timeless noir #cacacf, chatgpt noir #1f2123, muted moss #595f39
    const TopAppBar = styled(AppBar)({
      background: '#fbfbfe', 
      boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)'
    });
    
    
    const SideBar = styled(Box)({
      flexGrow: 1,
      backgroundColor: '#42424a', 
      //pt: '300px', // Adjusted to AppBar height
      pr: 1,
      //paddingLeft: '10px', // Add this
      minHeight: 'calc(100vh - 60px)',
      position: 'sticky', 
      top: 0,
      overflow: 'auto',
      color: '#cacacf',  
    });
    const PageTitle = styled(Typography)({
      fontSize: '1.3rem',
      fontWeight: '600',
      color: '#1f2123',  // light gray
      marginLeft: '10px' // Aligns the title to the left
    });

   
    return (
      <>
      {/* // <Provider store={store}>
      // <PersistGate loading={<CircularProgress />} persistor={persistor}>
      // <Router> */}
      <TopAppBar position="sticky" sx={{ height: '60px' }}>
          <Toolbar style={{
            //padding: '10px',
            minHeight: '60px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}>
            <Hidden smUp>
              <IconButton edge="start" color="inherit" aria-label="menu">
                <MenuIcon />
              </IconButton>
            </Hidden>
            <Box display="flex" alignItems="center">
              <img src={Logo} alt="logo" style={{ width: '30px', height: '30px', marginRight: '10px' }} />
              <PageTitle>
                RiskGPT
              </PageTitle>
            </Box>
            <Box>
            {user ? (
                <IconButton color="inherit" onClick={() => dispatch(logout())}>
                  <AccountCircle />
                </IconButton>
              ) : (
                <IconButton color="inherit" onClick={() => dispatch(login())}>
                  <AccountCircle />
                </IconButton>
              )}
            
                <SettingsButton />
            </Box>
          </Toolbar>
        </TopAppBar>
        <Grid container>
          <Grid item xs={12} sm={3} md={2}>

            <SideBar component="sidebar">
            <SideMenu />
            </SideBar>
          </Grid>
          <Grid item xs={12} sm={9} md={10}>
            <Box component="main" sx={{ flexGrow: 1, p: 3, backgroundColor: '#FAFAFA' }}>
              
             
            <Routes>
            
            <Route path="/model-form" element={<ModelCardForm onSubmit={(formData) => handleFormSubmit(formData, 'create')} />} />
            <Route path="/model-form/update/:modelId" element={<ModelCardForm onSubmit={(formData, modelId) => handleFormSubmit(formData, 'update', modelId)} onBackToInventory={handleBackToInventory} />} />
            <Route path="/model-details/:modelId" element={<ModelDetailsModal />} /> 
            <Route path="/risk-themes" element={<RiskThemes />} />
            <Route path="/risk-landscape" element={<RiskLandscape />} />
            <Route path="/assessment-completed/:modelId/:modelName/:assessmentUuid" element={<AssessmentCompleted />} />
            <Route path="/risk-form-manager" element={<RiskFormManager />} />
            <Route path="/risk-themes/:modelId" element={<RiskThemes />} /> 
            <Route path="/context-evaluation" element={<ContextAssessment />} /> 
            <Route path="/risk-form/:modelId/:modelName/:assessmentUuid" element={<RiskCardForm onSubmit={handleFormSubmit} onBackToInventory={handleBackToInventory} selectedModel={selectedModel} selectedModelId={selectedModelId}/>} />
              <Route path="/" element={<ModelInventory onSelectModel={(model) => handleSelectModel(model)} />} /> 
              {/* <Route path="/" element={user ? <ModelInventory onSelectModel={(model) => handleSelectModel(model)}/> : <Navigate to="/auth" />} /> */}
              <Route path="/risk-catalogue/:modelId/:modelName" element={<RiskCatalogue riskCardData={riskCardData} onBackToInventory={handleBackToInventory} />} />
              <Route path="/risk-evaluation" element={<RiskEvaluation onRiskEvaluate={handleRiskEvaluate} modelId={selectedModelId} />} />
              <Route path="/audits" element={<Audits />} /> 
              <Route path="*" element={<div>Not Found</div>} />
            </Routes>
          
            <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        >
          <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity} variant="filled">
            {snackbarMessage}
          </Alert>
        </Snackbar>

          </Box>
        
      </Grid>
    </Grid>
    
   {/* </Router>
 </PersistGate>
  </Provider> */}
   </>
  );
  
  }
  export default App
