import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Tabs, Tab, Table, TableBody, TableCell, TableHead, TableRow, Paper,
  TextField, Select, MenuItem, FormControl, InputLabel, Typography, Snackbar, Alert
} from '@mui/material';
import { updateControlEvaluationField } from './redux/actions/controlActions';
import controlEvaluationCriteria from './controlEvaluationForm.json';
import { debounce } from 'lodash';

const ControlEvaluationForm = ({ riskId, controlId, modelId, assessmentUuid, handleSaveOperation }) => {
  const [tabValue, setTabValue] = useState(0);
  const dispatch = useDispatch();
  const savedEvaluationData = useSelector(state => state.controls.savedControlEvaluations?.[riskId]?.[controlId] ?? []);
  const [currentEvaluationData, setCurrentEvaluationData] = useState({});
  const [saving, setSaving] = useState(false);
  

  
  useEffect(() => {
    if (savedEvaluationData && savedEvaluationData.length > 0) {
      setCurrentEvaluationData(savedEvaluationData[0]);
    } else {
      setCurrentEvaluationData({});
    }
  }, [savedEvaluationData]);

  const saveData = useMemo(() => debounce(async (data) => {
    
      setSaving(true);
      try {
        const response = await fetch('/.netlify/functions/saveControlEvaluation', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ modelId, assessmentUuid, controlId, data })
        });
        if (!response.ok) throw new Error('Failed to save control evaluation');
      } catch (error) {
        console.error('Error saving control evaluation:', error);
      } finally {
        setSaving(false);
      }
    
  }, 500), [modelId, assessmentUuid, controlId]);


  const handleFieldChange = (fieldName, value) => {
    const updatedData = { ...currentEvaluationData, [fieldName]: value };
    setCurrentEvaluationData(updatedData);
    dispatch(updateControlEvaluationField(riskId, controlId, fieldName, value));
    handleSaveOperation(() => saveData(currentEvaluationData));
  };

  function toTitleCase(str) {
    return str.replace(/([A-Z])/g, ' $1').trim().toLowerCase().replace(/(?:^|\s)\S/g, char => char.toUpperCase());
  }

  const renderField = (field, fieldName) => {
    const snakeCaseFieldName = fieldName.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);
    const fieldValue = currentEvaluationData[snakeCaseFieldName] ?? '';

    switch (field.fieldType) {
      case 'select':
        return (
          <FormControl fullWidth variant="outlined" size="small">
            <InputLabel>{toTitleCase(fieldName)}</InputLabel>
            <Select
              label={toTitleCase(fieldName)}
              name={fieldName}
              value={fieldValue}
              onChange={(e) => handleFieldChange(fieldName, e.target.value)}
            >
              {field.options.map(option => (
                <MenuItem key={option} value={option}>{option}</MenuItem>
              ))}
            </Select>
          </FormControl>
        );
      case 'text':
      case 'date':
      case 'file':
        return (
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            type={field.fieldType === 'file' ? 'file' : (field.fieldType === 'date' ? 'date' : 'text')}
            name={fieldName}
            value={fieldValue}
            onChange={(e) => handleFieldChange(fieldName, e.target.value)}
            InputLabelProps={field.fieldType === 'date' ? { shrink: true } : undefined}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      <form style={{ padding: '16px' }}>
        <Tabs value={tabValue} onChange={(e, newValue) => setTabValue(newValue)} centered>
          {controlEvaluationCriteria.map((attribute, index) => (
            <Tab key={index} label={<Typography variant="subtitle2" fontWeight="bold">{toTitleCase(attribute.attribute)}</Typography>} />
          ))}
        </Tabs>
        {controlEvaluationCriteria.map((attribute, index) => (
          <Paper key={index} elevation={0} hidden={tabValue !== index} style={{ margin: '16px 0', padding: '16px' }}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell><Typography variant="subtitle2" fontWeight="bold">Field Name</Typography></TableCell>
                  <TableCell><Typography variant="subtitle2" fontWeight="bold">Value</Typography></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {attribute.properties.map(field => (
                  <TableRow key={field.name}>
                    <TableCell><Typography variant="subtitle2" fontWeight="bold">{toTitleCase(field.name)}</Typography></TableCell>
                    <TableCell>{renderField(field, field.name)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Paper>
        ))}
        <Snackbar open={saving} autoHideDuration={6000}>
          <Alert severity="info">Saving changes...</Alert>
        </Snackbar>
      </form>
    </>
  );
};

export default ControlEvaluationForm;
