import { createAction } from '@reduxjs/toolkit';
import netlifyIdentity from 'netlify-identity-widget';

export const setUser = createAction('SET_USER');
export const clearUser = createAction('CLEAR_USER');

export const initializeAuth = () => (dispatch) => {
  netlifyIdentity.init();
  netlifyIdentity.on('init', (user) => {
    if (user) {
      dispatch(setUser(user));
    } else {
      dispatch(clearUser());
    }
  });

  const user = netlifyIdentity.currentUser();
  if (user) {
    dispatch(setUser(user));
  } else {
    dispatch(clearUser());
  }
};

export const login = () => (dispatch) => {
  netlifyIdentity.open('login');
  netlifyIdentity.on('login', (user) => {
    dispatch(setUser(user));
    netlifyIdentity.close();
  });
};

export const logout = () => (dispatch) => {
  netlifyIdentity.logout();
  netlifyIdentity.on('logout', () => {
    dispatch(clearUser());
  });
};
