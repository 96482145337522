import React, { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Card, CardContent,  Grid, Typography,  Paper} from '@mui/material';
import { useParams } from 'react-router-dom';
import RiskCard from './riskCard.js';
import SecurityRiskCatalogue from './securityRiskCatalogue.js';
import ContextAssessment from './contextAssessment.js';
import SecurityRiskAssessment from './securityRiskAssessment.js';
import TabStepper from './TabStepper';
import MasterStepper from './masterStepper.js';
import AssessmentCompleted from './AssessmentCompleted.js';
import { useDispatch} from 'react-redux';
import { fetchThemes } from './redux/actions/themeActions'; 
import { fetchRiskCardData, updateAssessmentStep, updateAssessmentStatus } from './redux/actions/riskActions';
import { fetchContextData } from './redux/actions/contextActions';
//import { getNarratives } from './GetNarratives.js';
import SecurityActionPlan from './securityActionPlan.js';
//import { current } from '@reduxjs/toolkit';




function RiskCardForm () {

  //const [isLoading, setIsLoading] = useState(true);
  let { modelId, modelName, assessmentUuid } = useParams();
  const dispatch = useDispatch();
  //const riskFormData = useSelector(state => state.risks.models[modelId].riskFormData || {});  

  //const step = useSelector(state => state.risks.models[modelId].step);
  const steps = ['contextAssessment', 'modelRisks', 'securityAssessment', 'completed'];
  const securitySteps = ['threatsAndVulnerabilities', 'securityRisks', 'securityControls'];

  const [activeStep, setActiveStep] = useState(0);
  const [securityStep, setSecurityStep] = useState(0);
  const contextData = useSelector(state => state.context.data[modelId] || {});

  const narratives = useMemo(() => {
    return Object.keys(contextData).reduce((acc, section) => {
        const narrative = contextData[section]?.narrative ? contextData[section].narrative : `No narrative for ${section}`;
        acc[section] = narrative;
        return acc;
    }, {});
}, [contextData]);




  
  useEffect(() => {
    dispatch(fetchThemes());
  }, [dispatch]);
  
  useEffect(() => {
    dispatch(fetchContextData(modelId, assessmentUuid));
}, [dispatch, modelId, assessmentUuid]);

    
    useEffect(() => {
    if (modelId && assessmentUuid) {
       dispatch(fetchRiskCardData(modelId, assessmentUuid))
       } 
  }, [dispatch, modelId, assessmentUuid]);
  


    
   console.log ('step in rcf', activeStep)

const handleSecurityStepChange = (index) => {
  setSecurityStep(index);
};


const handleNext = () => {
  const nextIndex = activeStep + 1;
  if (nextIndex < steps.length) {
    setActiveStep(nextIndex);
    const nextStep = steps[nextIndex];

    
    dispatch(updateAssessmentStep(modelId, assessmentUuid, nextStep));
    
      const assessmentStatus = nextStep === 'completed' ? 'Completed' : 'On-Going';

      console.log(`Dispatching status update for ${assessmentStatus}`);
      dispatch(updateAssessmentStatus(modelId, assessmentUuid,assessmentStatus));
    }
  }



const handleBack = () => {
  const prevIndex = activeStep - 1;
  if (prevIndex >= 0) {
    setActiveStep(prevIndex);
    const currentStep = steps[prevIndex];
    // Determine the status based on the step index
   
    dispatch(updateAssessmentStep(modelId, assessmentUuid, currentStep));

    const assessmentStatus = (prevIndex === 0 || currentStep !== 'completed') ? 'On-Going' : 'Completed';
    dispatch(updateAssessmentStatus(modelId, assessmentUuid,assessmentStatus));
  }
};

const toTitleCase = (str) => {
  // Split the string into words and handle camelCase by inserting spaces before capital letters
  return str
    .replace(/([A-Z])/g, ' $1') // Adds space before capital letters
    .replace(/([A-Z]+)/g, (match) => match.toUpperCase()) // Ensure each word is capitalized
    .trim()
    .split(/\s+/) // Split based on whitespace
    .map(word => word.charAt(0).toUpperCase() + word.substr(1).toLowerCase())
    .join(' '); // Join words back with space
};

const titleCasedSecuritySteps = securitySteps.map(step => toTitleCase(step));
  
return (

  <div>

                <Grid container spacing={2}>
                <Paper elevation={2} style={{ width: '100%' }}>
                    <Grid item xs={12}  style={{ padding: '8px 24px' }}>
                        <Typography variant="h5" style={{ padding: '8px 16px', display: 'flex',  alignItems: 'center' }}>
                            Risk assessment for {toTitleCase(modelName)} <span style={{ marginLeft: 10 }}>({activeStep + 1}/{steps.length})</span>
                        </Typography>
                    </Grid>
                </Paper>
      {/* {isLoading ? (
          <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: 50 }}>
            <CircularProgress /> 
          </Grid>
        ) : ( */}
          

        <Grid item xs={12}>
      <Card elevation={2} style={{ height: '100%' }}>

           <CardContent>
       
         {activeStep === 0 && <ContextAssessment modelId={modelId} assessmentUuid={assessmentUuid} />}
         {activeStep === 1 && <RiskCard modelId={modelId} assessmentUuid={assessmentUuid} narratives={narratives} />}
        {activeStep === 2 && (
            <div>
              <Paper elevation={2} style={{ width: '100%', padding: '8px 24px' }}>
            <Typography variant="h5">
                Security Risk Assessment
            </Typography>
            <TabStepper
                labels={titleCasedSecuritySteps}
                activeTab={securityStep}
                onStepChange={handleSecurityStepChange}
            />
        </Paper>
        
            {securityStep === 0 && (
                <SecurityRiskAssessment modelId={modelId} assessmentUuid={assessmentUuid} />
            )}
            {securityStep === 1 && (
                // <RiskCatalogue modelId={modelId} sectionName="Security Assessment" assessmentUuid={assessmentUuid} />
                <SecurityRiskCatalogue modelId={modelId} assessmentUuid={assessmentUuid} />
                // narratives={narratives}
            )}
            {securityStep === 2 && (
                <div>
                   <SecurityActionPlan modelId={modelId} assessmentUuid={assessmentUuid}/>
                </div>
            )}
        </div>
            
          )}
       
        {activeStep === 3 && <AssessmentCompleted modelId={modelId} modelName={modelName} assessmentUuid={assessmentUuid} />}
      {/* </Grid> */}
      <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 20 }}>
                <MasterStepper steps={steps} activeStep={activeStep} handleNext={handleNext} handleBack={handleBack} />
            </Grid>

        </CardContent>
   
          </Card>
        </Grid>
        
 </Grid>
       </div>
    ); 
 }

export default RiskCardForm;
