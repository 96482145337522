
import {
  RESET_SECURITY_STATE,
  UPDATE_SECURITY_ASSESSMENT,
  //SAVE_SECURITY_ASSESSMENT_SUCCESS,
  //SAVE_SECURITY_ASSESSMENT_FAILURE,
  LOAD_SECURITY_ASSESSMENT,
  SET_ACTIVE_IDS,
  GENERATE_SECURITY_RISKS_START,
  GENERATE_SECURITY_RISKS_SUCCESS,
  GENERATE_SECURITY_RISKS_FAILURE,
  FETCH_SECURITY_ASSESSMENT_START,
  FETCH_SECURITY_ASSESSMENT_SUCCESS,
  FETCH_SECURITY_ASSESSMENT_FAILURE,
  SAVE_SECURITY_RISK_START, 
  SAVE_SECURITY_RISK_SUCCESS, 
  SAVE_SECURITY_RISK_FAILURE,
  FETCH_SECURITY_RISKS_START,
  FETCH_SECURITY_RISKS_SUCCESS,
  FETCH_SECURITY_RISKS_FAILURE,
  DISCARD_SECURITY_RISK_START,
  DISCARD_SECURITY_RISK_SUCCESS,
  DISCARD_SECURITY_RISK_FAILURE,
  GENERATE_SECURITY_CONTROLS_START,
  GENERATE_SECURITY_CONTROLS_SUCCESS,
  GENERATE_SECURITY_CONTROLS_FAILURE,
  FETCH_SECURITY_CONTROLS_START, 
  FETCH_SECURITY_CONTROLS_SUCCESS, 
  FETCH_SECURITY_CONTROLS_FAILURE, 
  SAVE_SECURITY_CONTROL_START, 
  SAVE_SECURITY_CONTROL_SUCCESS,
  SAVE_SECURITY_CONTROL_FAILURE,
  
} from './actionTypes';

import loadSecurityRisks from '../../loadSecurityRisks'

export const resetSecurityState = () => ({
  type: RESET_SECURITY_STATE
});

export const updateSecurityAssessment = (modelId, assessmentData) => ({
  type: UPDATE_SECURITY_ASSESSMENT,
  payload: { modelId, assessmentData }
});

// export const saveSecurityAssessment = (modelId, assessmentUuid, assessmentData) => async (dispatch) => {
//   try {
//       const response = await fetch('/.netlify/functions/saveSecurityAssessment', {
//           method: 'POST',
//           headers: { 'Content-Type': 'application/json' },
//           body: JSON.stringify({ modelId, assessmentUuid, assessmentData }),
//       });

//       if (response.ok) {
//           //const data = await response.json();
//           //dispatch({ type: SAVE_SECURITY_ASSESSMENT_SUCCESS, payload: { modelId, assessmentData: data } });
//       } else {
//           throw new Error('Failed to save assessment');
//       }
//   } catch (error) {
//       dispatch({ type: SAVE_SECURITY_ASSESSMENT_FAILURE, payload: error.message });
//   }
// };

export const loadSecurityAssessment = (modelId, assessmentData) => ({
  type: LOAD_SECURITY_ASSESSMENT,
  payload: { modelId, assessmentData }
});

export const setActiveIds = (modelId, assessmentUuid) => ({
  type: SET_ACTIVE_IDS,
  payload: { modelId, assessmentUuid }
});

// export const generateSecurityRisks = (modelId,assessmentData, securityRisks) => {
//   console.log ('sec risks sent from front', securityRisks)
//   return async (dispatch) => {
//       dispatch({ type: GENERATE_SECURITY_RISKS_START });
//       try {
//           const response = await fetch('/.netlify/functions/generateSecurityRisks', {
//               method: 'POST',
//               headers: {
//                   'Content-Type': 'application/json',
//               },
//               body: JSON.stringify({ assessmentData, securityRisks }),
              
//           });

//           if (!response.ok) {
//               throw new Error(`Error fetching security risks: ${response.status} ${response.statusText}`);
//           }

//           const securityRisksResponse = await response.json();

//           dispatch({ type: GENERATE_SECURITY_RISKS_SUCCESS, payload: { modelId, securityRisks:securityRisksResponse } });
//       } catch (error) {
//           console.error('Fetch error:', error.message);
//           dispatch({ type: GENERATE_SECURITY_RISKS_FAILURE, payload: error.message });
//       }
//   };
// };
// Redux actions/securityActions.js

export const generateSecurityRisks = (modelId) => async (dispatch, getState) => {
  dispatch({ type: GENERATE_SECURITY_RISKS_START });

  const existingRisks = getState().security.models[modelId]?.securityRisks;
  
  // Check if security risks already exist and are not empty
  if (existingRisks && existingRisks.length > 0) {
      console.log("Security risks already exist, no need to generate.");
      return; // Exit the function if risks are already present
  }

  // If no risks are present, proceed to generate them
  console.log("No security risks found, generating new ones...");
  const assessmentData = getState().security.models[modelId]?.assessmentData || {};
  const securityRiskCatalogue = loadSecurityRisks(); 

  
  try {
      const response = await fetch('/.netlify/functions/generateSecurityRisks', {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify({ modelId,assessmentData, securityRiskCatalogue }),
      });

      if (!response.ok) {
          throw new Error(`Error generating security risks: ${response.status} ${response.statusText}`);
      }

      const securityRisksResponse = await response.json();

      dispatch({
          type: GENERATE_SECURITY_RISKS_SUCCESS,
          payload: {
              modelId,
              securityRisks: securityRisksResponse
          }
      });
  } catch (error) {
      console.error('Error generating security risks:', error.message);
      dispatch({
          type: GENERATE_SECURITY_RISKS_FAILURE,
          payload: error.message
      });
  }
};

export const fetchSecurityAssessment = (modelId, assessmentUuid) => async (dispatch) => {
  dispatch({ type: FETCH_SECURITY_ASSESSMENT_START });
  try {
    const response = await fetch(`/.netlify/functions/fetchSecurityAssessment`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ modelId, assessmentUuid })
    });

    if (!response.ok) {
      throw new Error('Failed to fetch assessment data');
    }
    const { assessmentData } = await response.json();
    console.log ('sec asmt data received from DB to dispatch to reducer', assessmentData)
    dispatch({ type: FETCH_SECURITY_ASSESSMENT_SUCCESS, payload: { modelId, assessmentData } });
  } catch (error) {
    dispatch({ type: FETCH_SECURITY_ASSESSMENT_FAILURE, payload: error.message });
  }
};
// In your actions.js file
export const saveSecurityRisk = (modelId, assessmentUuid, riskData) => async (dispatch) => {

  console.log ('security risks to dispatch to save', riskData)
  dispatch({ type: SAVE_SECURITY_RISK_START });
  try {
    const response = await fetch('/.netlify/functions/saveSecurityRisks', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ risk: riskData, modelId, assessmentUuid }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const { risk } = await response.json();

    console.log ('sec risks returned from DB to reducer', risk)
    dispatch({ type: SAVE_SECURITY_RISK_SUCCESS, payload: { modelId, risk } });
  } catch (error) {
    console.error('Error saving risk:', error);
    dispatch({ type: SAVE_SECURITY_RISK_FAILURE, payload: error.message });
  }
};
// export const fetchSecurityRisks = (modelId, assessmentUuid) => async (dispatch) => {
//   dispatch({ type: FETCH_SECURITY_RISKS_START });
//   try {
//     const response = await fetch('/.netlify/functions/fetchSecurityRisks', {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//       },
//       body: JSON.stringify({ modelId, assessmentUuid })
//     });

//     if (!response.ok) {
//       throw new Error('Failed to fetch security risks');
//     }
    
//     const result = await response.json();
//     const risks =result.risks;
//     dispatch({ type: FETCH_SECURITY_RISKS_SUCCESS, payload: { modelId, risks } });
//     console.log ('payload to send to security risks reducer', risks)
//   } catch (error) {
//     dispatch({ type: FETCH_SECURITY_RISKS_FAILURE, payload: error.message });
//   }
// };

export const fetchSecurityRisks = (modelId, assessmentUuid,allowGeneration = false ) => async (dispatch, getState) => {
  dispatch({ type: FETCH_SECURITY_RISKS_START });

  try {
      const response = await fetch('/.netlify/functions/fetchSecurityRisks', {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify({ modelId, assessmentUuid })
      });

      if (!response.ok) {
          throw new Error('Failed to fetch security risks');
      }
      
      const result = await response.json();
      const risks = result.risks;
      
      dispatch({ type: FETCH_SECURITY_RISKS_SUCCESS, payload: { modelId, risks } });
      console.log('Fetched security risks:', risks);

      // Check if risks are empty and trigger generation if needed
      if (risks.length === 0 && allowGeneration) {
          console.log("No security risks found, generating new ones...");
          dispatch(generateSecurityRisks(modelId));
      }
  } catch (error) {
      console.error('Error fetching security risks:', error.message);
      dispatch({ type: FETCH_SECURITY_RISKS_FAILURE, payload: error.message });
  }
};


export const discardSecurityRisk = (modelId, riskId) => async (dispatch) => {
  dispatch({ type: DISCARD_SECURITY_RISK_START });
  try {
    const response = await fetch('/.netlify/functions/discardSecurityRisk', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ riskId })
    });

    if (!response.ok) {
      throw new Error('Failed to discard security risk');
    }
    
    await response.json();
    dispatch({ type: DISCARD_SECURITY_RISK_SUCCESS, payload: { modelId, riskId } });
  } catch (error) {
    dispatch({ type: DISCARD_SECURITY_RISK_FAILURE, error: error.toString() });
  }
};


export const checkAndGenerateSecurityControls = (modelId) => (dispatch, getState) => {
  const { security } = getState();
  const model = security.models[modelId];
  if (!model) {
    console.error("Model not found:", modelId);
    return;
  }

  const { securityRisks, securityControls } = model;

  // Identify risks that lack controls
  const risksWithoutControls = securityRisks.filter(risk =>
    !securityControls[risk.risk_id] || securityControls[risk.risk_id].length === 0
  );

  if (risksWithoutControls.length > 0) {
    console.log("Generating controls for risks without controls in model:", modelId);
    dispatch(generateSecurityControls(modelId, risksWithoutControls));
  } else {
    console.log("All risks have controls, no generation needed.");
  }
};

export const generateSecurityControls = (modelId, risksWithoutControls) => async (dispatch) => {
  dispatch({ type: GENERATE_SECURITY_CONTROLS_START, modelId });
  try {
    const risksData = risksWithoutControls.map(risk => ({ risk_id: risk.risk_id }));
    const response = await fetch('/.netlify/functions/generateSecurityControls', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ modelId, risks: risksData })
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const controlsPerRisk = await response.json();
    dispatch({ type: GENERATE_SECURITY_CONTROLS_SUCCESS, modelId, controlsPerRisk });
  } catch (error) {
    console.error("Error generating security controls:", error);
    dispatch({ type: GENERATE_SECURITY_CONTROLS_FAILURE, modelId, error: error.message });
  }
};






export const fetchSecurityControls = (modelId, riskId) => async (dispatch) => {
  dispatch({ type: FETCH_SECURITY_CONTROLS_START });
  try {
    const response = await fetch('/.netlify/functions/fetchSecurityControls', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ modelId, riskId })
    });

    if (!response.ok) {
      throw new Error('Failed to fetch security controls');
    }
    
    const { controls } = await response.json();
    dispatch({ type: FETCH_SECURITY_CONTROLS_SUCCESS, payload: { modelId, riskId, controls } });
  } catch (error) {
    dispatch({ type: FETCH_SECURITY_CONTROLS_FAILURE, payload: error.message });
  }
};

// Save Control
export const saveSecurityControl = (controlData, modelId, riskId, assessmentUuid) => async (dispatch) => {
  dispatch({ type: SAVE_SECURITY_CONTROL_START });
  try {
    const response = await fetch('/.netlify/functions/saveSecurityControls', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ controlData, modelId, riskId, assessmentUuid })
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const { control } = await response.json();

    console.log ('saved sec control returned from db save', control)
    dispatch({ type: SAVE_SECURITY_CONTROL_SUCCESS, payload: { control, modelId, riskId } });
  } catch (error) {
    console.error('Error saving control:', error);
    dispatch({ type: SAVE_SECURITY_CONTROL_FAILURE, payload: error.message });
  }
};