import React, { useState, useEffect, useRef } from 'react';
import { Paper,Typography, Grid, Card, CardContent, Tabs, Tab, Box, TextField, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';

function ModelCardForm({ onSubmit}) {
  
  const { modelId } = useParams();
  const formType = modelId ? 'update' : 'create';

  const [formData, setFormData] = useState({
    
    modelCard: {
    
      modelSummary: {
      'Model Name': '',
      'Use Case Short Description': '',
      'Model Short Description': '',
      'Developed By': '',
      'Model Uuid': '',
    },
    contactInformation: {
      'Business Owner': '',
      'Technical Owner': '',
      'Risk & Audit Contact': '',
      'Model Card Authors': '',
      'Model Card Contact': '',
    },
  
      generalInformation: {
        'Development Language': '',
        'License': '',
        'Code Repository': '',
        'Demo Link': '',
        'Glossary of Terms': '',
        'More Information': '',
              
      },
   
      infrastructureAndHosting: {
        'Cloud Provider': '',
        'Cloud Region': '',
        'CO2 Emitted': '',
        'Compute Infrastructure': '',
        'Dedicated Hardware': '',
        'Software Dependencies': '',
      },
      citationInformation: {
        'Paper': '',
        'Citation BibTex': '',
        'Citation APA': '',
      },
    
    },
    });
  
       
    const formDataRef = useRef();
  formDataRef.current = formData;

  useEffect(() => {
    const mapModelCard = (modelCard) => {
      return {
          
        modelSummary: {
          'Model Name': modelCard.model_name || '',
          'Use Case Short Description': modelCard.use_case_short_description || '',
          'Model Short Description': modelCard.model_short_description || '',
          'Developed By': modelCard.developed_by || '',
          'Model Uuid': modelCard.model_uuid || '',
        },
        contactInformation: {
          'Business Owner': modelCard.business_owner || '',
          'Technical Owner': modelCard.technical_owner || '',
          'Risk & Audit Contact': modelCard.risk_audit_contact || '',
          'Model Card Authors': modelCard.model_card_authors || '',
          'Model Card Contact': modelCard.model_card_contact || '',
       
      },
        generalInformation: {
          'Development Language': modelCard.development_language || '',
          'License': modelCard.license || '',
          'Code Repository': modelCard.code_repository || '',
          'Demo Link': modelCard.demo_link || '',
          'Glossary of Terms': modelCard.glossary_of_terms || '',
          'More Information': modelCard.more_information || '',
        },
       
        infrastructureAndHosting: {
          'Cloud Provider': modelCard.cloud_provider || '',
          'Cloud Region': modelCard.cloud_region || '',
          'CO2 Emitted': modelCard.co2_emitted || '',
          'Compute Infrastructure': modelCard.compute_infrastructure || '',
          'Dedicated Hardware': modelCard.dedicated_hardware || '',
          'Software Dependencies': modelCard.software_dependencies || '',
        },
        citationInformation: {
          'Paper': modelCard.paper || '',
          'Citation BibTex': modelCard.citation_bibtex || '',
          'Citation APA': modelCard.citation_apa || '',
        },

     };  
    };
    const fetchModelDetails = async (modelId) => {
      try {
        const response = await fetch(`/.netlify/functions/FetchModelDetails?model_uuid=${modelId}`);
        const data = await response.json();
                    
        if (response.ok && data.success) {
          
          const modelCardData = data.data.modelCards;
          const mappedModelCard = mapModelCard(modelCardData);
          
           setFormData(prevState => ({
            ...prevState,
            modelCard: mappedModelCard
          }));
          
        } else {
          
        }
      } catch (error) {
        console.error('Error fetching model details:', error);
      }
    };
    if (modelId) {
      fetchModelDetails(modelId);
    } else {
      console.log('modelId is undefined'); // Log if modelId is undefined
    }
  }, [modelId]);
 
  const addSpacesToCamelCase = (str) => {
    return str.replace(/([a-z])([A-Z])/g, '$1 $2');
  }
  
  const toTitleCase = (str) => {
    str = addSpacesToCamelCase(str);
    return str.replace(/\w\S*/g, function(txt){
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

  const handleSectionChange = (section) => (event) => {
    const { name, value } = event.target;
  
    setFormData(prevState => ({
      ...prevState,
      modelCard: {
        ...prevState.modelCard,
        [section]: {
          ...prevState.modelCard[section],
          [name]: value
        }
      }
    }));
  };
  
  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log("Handling form submission");

    const lowerCasedFormData = Object.fromEntries(
      Object.entries(formData).map(([key, value]) => {
        return [
          key,
          Object.fromEntries(
            Object.entries(value).map(([subKey, subValue]) => [
              subKey.toLowerCase().replace(/ /g, '_'),
              subValue,
            ])
          ),
        ];
      })
    );

     // Pass formType and modelId as arguments
     await onSubmit(lowerCasedFormData, formType, modelId);
  };
        
  const [selectedTab, setSelectedTab] = useState(0);
  const handleChange = ( event,newValue) => {
    setSelectedTab(newValue);
  };
  
  const renderSectionFields = (section) => (
    <>
      {formData.modelCard && Object.keys(formData.modelCard[section]).map((key) => (
        <TextField
          fullWidth
          margin="normal"
          id={key}
          label={key}
          variant="outlined" 
          multiline 
          rows={4}  
          name={key}
          value={formData.modelCard[section][key]}
          onChange={handleSectionChange(section)}
          key={key}
        />
      ))}
    </>
  );
 
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h4" gutterBottom>
          Model Card
        </Typography>
      </Grid>
      <Grid item xs={12} sm={4} md={3}>
        <Paper elevation={3} style={{ overflow: 'auto', height: '100%' }}>
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={selectedTab}
            onChange={handleChange}
            aria-label="Model Sections"
            sx={{ borderRight: 1, borderColor: 'divider' }}
          >
            {formData.modelCard && Object.keys(formData.modelCard).map((section, index) => (
              <Tab label={toTitleCase(section)} key={index} />
            ))}
          </Tabs>
        </Paper>
      </Grid>
      <Grid item xs={12} sm={8} md={9}>
        <Card elevation={3} style={{ height: '100%' }}>
          <Box display="flex" flexDirection="column" height="100%">
            <Box flexGrow={1}>
            <form onSubmit={handleSubmit}>
                <CardContent>
                    {Object.keys(formData.modelCard).map((section, index) => (
                      <div hidden={selectedTab !== index} key={index}>
                          {renderSectionFields(section, formData.modelCard[section])}
                      </div>
                    ))}
                </CardContent>
              </form>
            </Box>
          </Box>
        </Card>
      </Grid>
      {/* Buttons */}
      <Grid item xs={12} sm={8} md={9} style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '16px' }}>
        <Link to="/" style={{ marginRight: '8px', textDecoration: 'none', color: '#2196f3' }}>
          Back to Model Inventory
        </Link>
        <Button variant="contained" color="primary" type="submit" onClick={handleSubmit}>Save</Button>
      </Grid>
    </Grid>
  );
}

export default ModelCardForm;


