import React, { useState } from 'react';
import {
  Paper, Table, TableBody, TableCell, TableContainer,
  TableHead, TableRow, Typography, Box, Grid, Button
} from '@mui/material';

const SecurityRisksReport = ({ model }) => {
  const [selectedRisk, setSelectedRisk] = useState(null);
  const [openControl, setOpenControl] = useState({}); // To handle collapsible control sections

  const toggleControl = (controlId) => {
    setOpenControl(prev => ({
      ...prev,
      [controlId]: !prev[controlId]
    }));
  };

  const rowStyle = (isSelected) => ({
    backgroundColor: isSelected ? '#f0f0f0' : 'inherit',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#e0e0e0',
    },
  });

  return (
    <Paper elevation={2} sx={{ margin: '16px', padding: '8px', borderRadius: '4px' }}>
      <Typography variant="h5" sx={{ mb: 1 }}>Security Risks-{model.modelName} </Typography>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight: 'bold' }}>Risk Category</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>Risk Name</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>Threat Level</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>Vulnerability Level</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {model.risks.map((risk) => (
              <TableRow 
                key={risk.riskId} 
                hover 
                onClick={() => setSelectedRisk(risk)}
                sx={rowStyle(selectedRisk?.riskId === risk.riskId)}
              >
                <TableCell>{risk.category}</TableCell>
                <TableCell>{risk.risk_name}</TableCell>
                <TableCell>{risk.threat_level}</TableCell>
                <TableCell>{risk.vulnerability_level}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {selectedRisk && (
        <Grid container spacing={2} sx={{ mt: 2 }}>
          <Grid item xs={12}>
            <Paper elevation={2} sx={{ padding: '8px' }}>
              <Typography variant="h6">Risk Details for {selectedRisk.riskName}</Typography>
              <Box sx={{ p: 2 }}>
                <Typography><strong>Justification:</strong> {selectedRisk.justification}</Typography>
                <Typography><strong>Vulnerability Level:</strong> {selectedRisk.vulnerabilityLevel}</Typography>
                <Typography><strong>Asset Value:</strong> {selectedRisk.assetValue}</Typography>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper elevation={2} sx={{ padding: '8px' }}>
              <Typography variant="h6">Controls</Typography>
              <TableContainer>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ fontWeight: 'bold' }}>Control Name</TableCell>
                      <TableCell style={{ fontWeight: 'bold' }}>Status</TableCell>
                      <TableCell style={{ fontWeight: 'bold' }}>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {selectedRisk.controls.map((control, idx) => (
                      <React.Fragment key={idx}>
                        <TableRow hover onClick={() => toggleControl(control.controlId)}>
                          <TableCell>{control.name}</TableCell>
                          <TableCell>{control.status}</TableCell>
                          <TableCell>
                            <Button size="small">
                              {openControl[control.controlId] ? 'Hide Details' : 'Show Details'}
                            </Button>
                          </TableCell>
                        </TableRow>
                        {openControl[control.controlId] && (
                          <TableRow>
                            <TableCell colSpan={3} sx={{ backgroundColor: '#f9f9f9' }}>
                              <Typography variant="body2" sx={{ p: 2 }}>{control.description || 'No additional details provided.'}</Typography>
                            </TableCell>
                          </TableRow>
                        )}
                      </React.Fragment>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Grid>
        </Grid>
      )}
    </Paper>
  );
};

export default SecurityRisksReport;
