// src/redux/actions/contextActions.js

import { SET_CONTEXT_DATA, UPDATE_CONTEXT_DATA, SAVE_CONTEXT_DATA_START,SAVE_CONTEXT_DATA_SUCCESS, SAVE_CONTEXT_DATA_FAILURE,
    FETCH_CONTEXT_DATA_START, FETCH_CONTEXT_DATA_SUCCESS, FETCH_CONTEXT_DATA_FAILURE,FETCH_NARRATIVE_START,FETCH_NARRATIVE_SUCCESS,FETCH_NARRATIVE_FAILURE } from './actionTypes';

export const setContextData = (modelId, data) => ({
    type: SET_CONTEXT_DATA,
    payload: { modelId, data }
  });

  export const updateContextData = (modelId, section, fieldName, value) => ({
    type: UPDATE_CONTEXT_DATA,
    payload: { modelId, section, fieldName, value }
  });

export const saveContextDataSuccess = (data) => ({
    type: SAVE_CONTEXT_DATA_SUCCESS,
    payload: data
});

export const saveContextDataFailure = (error) => ({
    type: SAVE_CONTEXT_DATA_FAILURE,
    payload: error
});



export const saveContextData = (modelId, assessmentUuid, section, sectionData) => async (dispatch) => {
    console.log ('section in save context action', section)
    dispatch({ type: SAVE_CONTEXT_DATA_START });
    try {
        const response = await fetch('/.netlify/functions/saveContext', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ modelId, assessmentUuid, section, sectionData })
        });
        const data = await response.json();
        if (!response.ok) throw new Error(data.message || 'Failed to save data');
        //dispatch({ type: SAVE_CONTEXT_DATA_SUCCESS, payload: { modelId, section, sectionData: result.sectionData } });
    } catch (error) {
        dispatch({ type: SAVE_CONTEXT_DATA_FAILURE, payload: error.message });
        throw error;
    }
};

export const fetchNarrativeStart = () => ({
    type: FETCH_NARRATIVE_START
  });
  
  export const fetchNarrativeSuccess = (modelId,section, narrative) => ({
    type: FETCH_NARRATIVE_SUCCESS,
    payload: { modelId, section, narrative }
  });
  
  export const fetchNarrativeFailure = (error) => ({
    type: FETCH_NARRATIVE_FAILURE,
    payload: error
  });
  
  // Action to request narrative generation
  export const generateNarrative = (modelId,assessmentUuid, section, contextData) => {

    console.log ('section and context received to action dispatch', section)
    console.log ('section and context received to action dispatch', contextData)
    return async (dispatch) => {
      dispatch(fetchNarrativeStart());
      try {
        const response = await fetch('/.netlify/functions/generateNarrative', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ modelId, section, contextData })
        });
        const data = await response.json();
        dispatch(fetchNarrativeSuccess(modelId, section, data.narrative));
        dispatch(saveContextData(modelId, assessmentUuid, section, { ...contextData, narrative: data.narrative }));
      } catch (error) {
        dispatch(fetchNarrativeFailure(error.toString()));
      }
    };
  };
  export const fetchContextDataStart = () => ({
    type: FETCH_CONTEXT_DATA_START
});

export const fetchContextDataSuccess = (modelId, data) => ({
    type: FETCH_CONTEXT_DATA_SUCCESS,
    payload: { modelId, data }
});

export const fetchContextDataFailure = (error) => ({
    type: FETCH_CONTEXT_DATA_FAILURE,
    payload: error
});

export const fetchContextData = (modelId, assessmentUuid) => async (dispatch) => {
    dispatch(fetchContextDataStart());
    try {
        const response = await fetch ('/.netlify/functions/fetchContext', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ modelId, assessmentUuid })
          });
        
           
        
        if (!response.ok) throw new Error('Failed to fetch data');
        const data = await response.json();
        dispatch(fetchContextDataSuccess(modelId, data));
    } catch (error) {
        dispatch(fetchContextDataFailure(error.message));
    }
};