
import React, {useEffect} from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button, Typography, Paper, Box } from '@mui/material';
import {setStep} from './redux/actions/riskActions'

function AssessmentCompleted({ modelId, assessmentUuid, sectionName }) {
  const navigate = useNavigate();
  const dispatch=useDispatch();

  const handleViewReport = () => {
    navigate('/risk-landscape', { state: { modelId, assessmentUuid, expandReport: true } });
  };
  
  useEffect(() => {
    dispatch(setStep(modelId, sectionName, 'Completed'));
  }, [dispatch, modelId, sectionName]);

  const handleReturnToInventory = () => navigate('/');

  return (
    <Paper elevation={0} style={{ padding: '40px', margin: '40px auto', maxWidth: '800px' }}>
      <Typography variant="h4" gutterBottom component="div" style={{ fontWeight: 600 }}>
        Assessment Completed
      </Typography>
      <Typography variant="subtitle1" gutterBottom component="div">
        What would you like to do next?
      </Typography>
      <Box display="flex" justifyContent="space-between" mt={4}>
        <Button 
          onClick={handleViewReport} 
          variant="contained" 
          color="primary"
          style={{
            textTransform: 'none',
            backgroundColor: '#fff',
            color: '#1976d2',
            boxShadow: 'none',
            border: '1px solid #1976d2',
            padding: '10px 20px',
            fontSize: '1rem'
          }}>
          View Risk Report
        </Button>
        <Button 
          onClick={handleReturnToInventory} 
          variant="contained" 
          color="secondary"
          style={{
            textTransform: 'none',
            backgroundColor: '#fff',
            color: '#dc004e',
            boxShadow: 'none',
            border: '1px solid #dc004e',
            padding: '10px 20px',
            fontSize: '1rem'
          }}>
          Return to Model Inventory
        </Button>
      </Box>
    </Paper>
  );
}

export default AssessmentCompleted;
