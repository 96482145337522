
import React from 'react';
import { Stepper, Step, StepLabel } from '@mui/material';

const TabStepper = ({ labels, activeTab, onStepChange }) => {
    return (
        <Stepper alternativeLabel activeStep={activeTab} sx={{ paddingTop: '20px', paddingBottom: '20px' }}>
            {labels.map((label, index) => (
                <Step key={label} onClick={() => onStepChange(index)}>
                    <StepLabel>{label}</StepLabel>
                </Step>
            ))}
        </Stepper>
    );
};

export default TabStepper;
