import React, { useEffect } from 'react';
import netlifyIdentity from 'netlify-identity-widget';
import { Button, Box, Typography } from '@mui/material';

const AuthPage = () => {
  useEffect(() => {
    netlifyIdentity.init();
    const user = netlifyIdentity.currentUser();
    if (user) {
      window.location.href = '/';
    }

    netlifyIdentity.on('login', (user) => {
      console.log('User logged in:', user);
      window.location.href = '/'; // redirect after login
    });

    netlifyIdentity.on('logout', () => {
      console.log('User logged out');
    });

    return () => {
      netlifyIdentity.off('login');
      netlifyIdentity.off('logout');
    };
  }, []);

  const handleSignIn = () => {
    netlifyIdentity.open('login');
  };

  const handleSignUp = () => {
    netlifyIdentity.open('signup');
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="100vh">
      <Typography variant="h4" gutterBottom>
        Welcome to RiskGPT
      </Typography>
      <Typography variant="body1" gutterBottom>
        Please sign in or sign up to continue.
      </Typography>
      <Box mt={2}>
        <Button variant="contained" color="primary" onClick={handleSignIn} style={{ marginRight: '10px' }}>
          Sign In
        </Button>
        <Button variant="contained" color="secondary" onClick={handleSignUp}>
          Sign Up
        </Button>
      </Box>
    </Box>
  );
};

export default AuthPage;
