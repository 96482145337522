import React, { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Paper, Typography, FormControl, InputLabel, Select, MenuItem,
  Chip, Box, CircularProgress, Table, TableBody,
  TableCell, TableContainer, TableHead, TableRow, OutlinedInput, Snackbar, Alert, IconButton, Tooltip
} from '@mui/material';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import _ from 'lodash';
import debounce from 'lodash/debounce';
import AutosaveIndicator from './AutoSaveIndicator.js';

import {
  updateSecurityAssessment, setActiveIds, fetchSecurityAssessment
} from './redux/actions/securityActions';  
import securityAssessment from './securityForm.json';



const SecurityRiskAssessment = ({ modelId, assessmentUuid }) => {
  const dispatch = useDispatch();
  const securityData = useSelector(state => state.security.models[modelId]?.assessmentData || {});
  const [isLoading, setIsLoading] = useState(true);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('info');
  const [isSaving, setIsSaving] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [hoverStates, setHoverStates] = useState({});

  
  

 
  useEffect(() => {
    dispatch(setActiveIds(modelId, assessmentUuid));
    dispatch(fetchSecurityAssessment(modelId, assessmentUuid))

    .finally(() => setIsLoading(false)); // Stop loading if data is already present
    // }
  }, [modelId, assessmentUuid,dispatch]); // Removed securityData from dependency array

  function initializeFormData() {
    const initialData = {};
    Object.keys(securityAssessment.security).forEach(categoryKey => {
        const category = securityAssessment.security[categoryKey];
        
        // Handling categories with subcategories
        if (category.type === 'category' && category.subcategories) {
            initialData[categoryKey] = {
                description: category.description,
                
            };
         
        }
        // Special handling for 'Threat Agents' category
        else if (categoryKey === 'Threat Agents') {
            initialData[categoryKey] = {};
         
        }
        // Handle general multiselect categories
        else if (category.type === 'multiselect') {
            initialData[categoryKey] = {
                description: category.description,
                 
            };
        }
    });
    return initialData;
}


  
  
  const initialFormData = initializeFormData();

  console.log('initial sec form', initialFormData)
  console.log ('sec data from state', securityData)
  // Using lodash's merge function to combine data
  const assessmentData = _.merge(initialFormData, securityData);
  const debouncedSave = useMemo(() => debounce(async (formData) => {
    setIsSaving(true);
    try {
      const response = await fetch('/.netlify/functions/saveSecurityAssessment', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          modelId,
          assessmentUuid,
          assessmentData: formData
        })
      });
      if (response.ok) {
        setIsSaving(false);
        setIsSaved(true);
        setTimeout(() => setIsSaved(false), 2000);
      } else {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Network response was not ok');
      }
    } catch (error) {
      console.error('Error:', error);
      setSnackbarMessage(error.message);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } finally {
      setIsSaving(false);
    }
  }, 1500), [modelId, assessmentUuid]);

  
   const handleAgentChange = (value) => {
    const newData = { ...assessmentData, 'Threat Agents': {} };

    console.log ('Agent data', newData)
    value.forEach(agent => {
      newData['Threat Agents'][agent] = {};
      Object.keys(securityAssessment.security['Threat Agents'].attributes).forEach(attr => {
        newData['Threat Agents'][agent][attr] = assessmentData['Threat Agents'][agent] ? assessmentData['Threat Agents'][agent][attr] : [];
      });
    });

   // setAssessmentData(newData);
    dispatch(updateSecurityAssessment(modelId, newData));
    debouncedSave(newData);
  };

  


  const handleAttributeChange = (agent, attr, value) => {
   
    const newData = { ...assessmentData };
    newData['Threat Agents'][agent][attr] = value;

    console.log ('agent attrib change data:', newData)
   // setAssessmentData(newData);
    dispatch(updateSecurityAssessment(modelId, newData));
    debouncedSave(newData);
  };

  const handleChange = (categoryKey, subcategoryKey, value) => {

    console.log(`Updating ${categoryKey} - ${subcategoryKey} with value:`, value);
  

    const newData = { ...assessmentData };

    console.log('New data structure in handle change:', newData);

    if (!newData[categoryKey]) {
      newData[categoryKey] = {};
    }
    if (subcategoryKey) {
      if (!newData[categoryKey][subcategoryKey]) {
        newData[categoryKey][subcategoryKey] = [];
      }
      newData[categoryKey][subcategoryKey] = value;
    } else {
      newData[categoryKey] = value;
    }

    //setAssessmentData(newData);
    dispatch(updateSecurityAssessment(modelId, newData));
    debouncedSave(newData);
  };


  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };
  

  // const handleSave = async () => {

  //  console.log('asmt data to send to security save', JSON.stringify(assessmentData, null, 2))
  //   dispatch(saveSecurityAssessment(modelId, assessmentUuid, assessmentData));
  // };

  // useEffect(() => {
  //   const handleBeforeUnload = (event) => {
  //     debouncedSave.flush();
  //     event.preventDefault();
  //     event.returnValue = '';
  //   };

  //   window.addEventListener('beforeunload', handleBeforeUnload);

  //   return () => {
  //     debouncedSave.cancel();
  //     window.removeEventListener('beforeunload', handleBeforeUnload);
  //   };
  // }, [debouncedSave]);

  const handleMouseEnter = (fieldName) => {
    setHoverStates(prev => ({ ...prev, [fieldName]: true }));
  };

  const handleMouseLeave = (fieldName) => {
    setHoverStates(prev => ({ ...prev, [fieldName]: false }));
  };

  return (
    <Paper style={{ padding: '20px', margin: '20px' }}>

<Paper elevation={2} style={{ padding: '20px', marginBottom: '16px' }}>
     <Typography variant="h6" style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
          Threats & Vulnerability Assessment

          {isLoading ? (
                  <CircularProgress />
          ) : null}

                  <div style={{ marginLeft: '10px' }}>
                    <AutosaveIndicator isSaving={isSaving} isSaved={isSaved} />
                  </div>
</Typography>
</Paper>
      <Box sx={{ mt: 3 }}>
        {Object.keys(assessmentData).map(categoryKey => {
          const category = securityAssessment.security[categoryKey];
          if (categoryKey === 'Threat Agents') {
            return (
              <Box key={categoryKey} sx={{ mb: 4 }}>
                <Typography variant="h6">{categoryKey}</Typography>
                <Typography variant="subtitle1">{category.description}</Typography>

                <FormControl fullWidth margin="normal" variant="outlined">
                  <InputLabel>Threat Agents</InputLabel>
                                  
                  <Select
                    multiple
                    //value={Object.keys(assessmentData[categoryKey].selectedAgents)}
                    value={Object.keys(assessmentData[categoryKey] || [])}
                     //value={selectedAgents}
                    onChange={e => handleAgentChange(e.target.value)}
                    input={<OutlinedInput label={categoryKey} id={"select-" + categoryKey} />}
                    renderValue={selected => (
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '5px' }}>
                        {selected.map(value => <Chip key={value} label={value} />)}
                      </Box>
                    )}
                  >
                    {category.options.map(option => (
                      <MenuItem key={option} value={option}>{option}</MenuItem>
                    ))}
                  </Select>
                </FormControl>

                {Object.keys(assessmentData[categoryKey]).length > 0 && (
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="attributes table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Agent</TableCell>
                          {Object.keys(category.attributes).map(attr => (
                            <TableCell key={attr}>{attr}</TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {Object.keys(assessmentData[categoryKey]).map(agent => (
                          <TableRow key={agent}>
                            <TableCell component="th" scope="row">
                              {agent}
                            </TableCell>
                            {Object.keys(category.attributes).map(attr => (
                              <TableCell key={attr}>
                                <Select
                                  multiple
                                  value={Array.isArray(assessmentData[categoryKey][agent][attr]) ? assessmentData[categoryKey][agent][attr] : []}
                                  onChange={e => handleAttributeChange(agent, attr, e.target.value)}
                                  renderValue={selected => (
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '5px' }}>
                                      {selected.map(value => <Chip key={value} label={value} />)}
                                    </Box>
                                    )}
                                    onMouseEnter={() => handleMouseEnter(attr)}
                                    onMouseLeave={() => handleMouseLeave(attr)}
                                    endAdornment={
                                      hoverStates[attr] && (
                                        <Tooltip title={category.attributes[attr].description || 'No additional information available'}>
                                          <IconButton edge="end" style={{ color: '#1976d2', marginRight: '12px' }}>
                                            <HelpOutlineOutlinedIcon />
                                          </IconButton>
                                        </Tooltip>
                                      )
                                    }
                                   >
                                  {category.attributes[attr].options.map(option => (
                                    <MenuItem key={option} value={option}>{option}</MenuItem>
                                  ))}
                                </Select>
                              </TableCell>
                            ))}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
              </Box>
            );
          }

          return (
            <Box key={categoryKey} sx={{ mb: 4 }}>
              <Typography variant="h6">{categoryKey}</Typography>
              <Typography variant="subtitle1">{category.description}</Typography>
              {category.type === 'category' && category.subcategories.map(subcat => (
              
                <FormControl fullWidth margin="normal" variant="outlined" key={subcat.name}>
                  <InputLabel>{subcat.name}</InputLabel>
                  
                  <Select
                    multiple
                    input={<OutlinedInput label={subcat.name} id={"select-" + categoryKey + "-" + subcat.name} />}
                    value={Array.isArray(assessmentData[categoryKey][subcat.name]) ? assessmentData[categoryKey][subcat.name] : []}
                    onChange={e => handleChange(categoryKey, subcat.name, e.target.value)}
                    renderValue={selected => (
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '5px' }}>
                        {selected.map(value => <Chip key={value} label={value} />)}
                      </Box>
                    )}
                
                  onMouseEnter={() => handleMouseEnter(subcat.name)}
                  onMouseLeave={() => handleMouseLeave(subcat.name)}
                  endAdornment={
                    hoverStates[subcat.name] && (
                      <Tooltip title={subcat.description || 'No additional information available'}>
                        <IconButton edge="end" style={{ color: '#1976d2', marginRight: '12px' }}>
                          <HelpOutlineOutlinedIcon />
                        </IconButton>
                      </Tooltip>
                    )
                  }
                >
                
                    {subcat.options.map(option => (
                      <MenuItem key={option} value={option}>{option}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              ))}
            </Box>
          );
        })}
      </Box>

      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
{/* 
      <Button variant="contained" color="primary" onClick={handleSave} sx={{ mt: 3 }}>
        Submit Assessment
      </Button> */}
    </Paper>
  );
};

export default SecurityRiskAssessment;

