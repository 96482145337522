import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { initializeAuth } from './redux/actions/authActions';
import AuthPage from './authPage';
import App from './App';

const AuthWrapper = () => {
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(initializeAuth());
  }, [dispatch]);

  return (
    <Router>
      <Routes>
        <Route path="/auth" element={<AuthPage />} />
        <Route path="/*" element={user ? <App /> : <Navigate to="/auth" />} />
      </Routes>
    </Router>
  );
};

export default AuthWrapper;
