
import React from 'react';
import { Typography, CircularProgress, IconButton } from '@mui/material';
import CloudIcon from '@mui/icons-material/Cloud';
import CloudDoneIcon from '@mui/icons-material/CloudDone';
import './index.css'


const AutosaveIndicator = ({ isSaving, isSaved }) => {
  //console.log('AutosaveIndicator props:', { isSaving, isSaved });
  if (isSaving) {
    return (
      <Typography component="div" sx={{ display: 'flex', alignItems: 'center', color: '#1976d2' }}>
        <CircularProgress size={20} sx={{ color: '#1976d2', marginRight: '10px', marginLeft:'10px' }} />
        Saving
        <span className="dot">.</span>
        <span className="dot">.</span>
        <span className="dot">.</span>
      </Typography>
    );
  }
  if (isSaved) {
    return (
      <Typography component="div" sx={{ display: 'flex', alignItems: 'center', color: '#4caf50' }}>
        <CloudDoneIcon sx={{ marginRight: '10px' , marginLeft:'10px'}} />
        Saved
      </Typography>
    );
  }
  return (
    <IconButton color="default">
      <CloudIcon sx={{ marginRight: '10px' , marginLeft:'10px'}}/>
    </IconButton>
  );
};

export default AutosaveIndicator;
