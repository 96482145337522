import React, { useEffect, useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  CircularProgress, Paper, Table, TableBody, TableCell, TableContainer,
  TableHead, TableRow, IconButton, Collapse, FormControlLabel, Tooltip, FormControl, Typography,Radio, RadioGroup, Box
} from '@mui/material';
import AnalyticsOutlinedIcon from '@mui/icons-material/AnalyticsOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { fetchAllModels } from './redux/actions/riskActions';
import RisksBySource from './risksBySource';
import SecurityRisksReport from './securityRiskReport';
import RiskCounters from './riskCounters';





const RiskLandscape = () => {
  const dispatch = useDispatch();
  const models = useSelector(state => state.risks.models);
  const securityState = useSelector(state => state.security);
  const [expandedSection, setExpandedSection] = useState({});
  const [selectedModel, setSelectedModel] = useState(null);
  const [isLoading, setLoading] = useState(true);
  const [viewType, setViewType] = useState('modelView'); 
  const loadingMessage = useSelector(state => state.risks.loadingMessage);

  useEffect(() => {
    console.log("Selected Model Updated:", selectedModel);
  }, [selectedModel]);

  useEffect(() => {
    dispatch(fetchAllModels()).then(() => setLoading(false));
  }, [dispatch]);

//console.log ('Models in RL', models)
  
  const reportData = useMemo(() => {
    const sections = {};
    Object.entries(models).forEach(([modelUuid, model]) => {
        Object.entries(model.riskFormData || {}).forEach(([sectionName, sectionData]) => {
            if (!sections[sectionName]) {
                sections[sectionName] = { models: {} };
            }
            // Initialize the model only once per section with assessment attributes.
            if (!sections[sectionName].models[model.modelName]) {
                sections[sectionName].models[model.modelName] = {
                    risks: [],
                    modelId: modelUuid,
                    modelName: model.modelName,
                    assessmentUuid: model.assessmentUuid,
                    assessmentStatus: model.assessmentStatus
                };
            }
            // Populate risks array for the model with full risk objects.
            sectionData.sectionRisks?.forEach(risk => {
                sections[sectionName].models[model.modelName].risks.push({
                    ...risk,
                    riskName: risk.riskName // Push the entire risk object
                });
            });
        });
    });
    return sections;
}, [models]);

//console.log ('Report Data', reportData)




const modelReportData = useMemo(() => {
  const models = {};
  Object.entries(reportData).forEach(([sectionName, sectionDetails]) => {
    Object.entries(sectionDetails.models).forEach(([modelName, modelDetails]) => {
      if (!models[modelName]) {
        models[modelName] = {
          modelId: modelDetails.modelId,
          modelName: modelDetails.modelName,
          assessmentUuid: modelDetails.assessmentUuid,
          assessmentStatus: modelDetails.assessmentStatus,
          sections: {}
        };
      }
      // Adjust here to map riskName to name
      models[modelName].sections[sectionName] = modelDetails.risks.map(risk => ({
        ...risk,
        riskName: risk.riskName,  // Ensuring consistency by using the expected key
        priority: risk.priority,
        riskId: risk.riskId,
        rationale: risk.rationale,
        status: risk.status
      }));
    });
  });
  return models;
}, [reportData]);


//console.log ('model report data', modelReportData)

const securityReportData= useMemo (()=>  {
  
  const dataByModel = {};
  const dataByCategory = {};

  // Iterate over each model
  Object.entries(securityState.models ?? {}).forEach(([modelUuid, modelDetails]) => {
      const { securityRisks, securityControls } = modelDetails;

      // Organize data by model
      dataByModel[modelUuid] = {
        modelUuid,
        risks: securityRisks.map(risk => ({
            ...risk,
            controls: securityControls[risk.risk_id] || [] // Attach controls directly to the risk
        })),
        controls: securityControls
    };

      // Organize risks by category
      securityRisks.forEach(risk => {
          const category = risk.category.toLowerCase(); // Normalize the category for consistent access
          if (!dataByCategory[category]) {
              dataByCategory[category] = [];
          }
          // Add risk and its associated controls to the category
          dataByCategory[category].push({
              ...risk,
              modelUuid: modelUuid,
              controls: securityControls[risk.risk_id] || [], // Attach controls directly to the risk
          });
      });
  });
  //console.log("Security Report Data:", { dataByModel, dataByCategory });
  return { dataByModel,dataByCategory };
},[securityState]);



  const handleSelectModel = (modelName, sectionName) => {
    if (modelName in modelReportData) {
      const modelData = modelReportData[modelName];
      //console.log ('sectionName in handleselectmodel', sectionName)
      if (sectionName === 'security') {
        // Handle security risks separately
        const securityRisks = securityReportData.dataByModel[modelData.modelId]?.risks || [];
        const selectedData = {
          model: {
            modelId: modelData.modelId,
            modelName: modelData.modelName,
            risks: securityRisks,
            sectionName: 'security'
          }
        };
        setSelectedModel(selectedData);
      } else if (sectionName in modelData.sections) {
        // Handle regular risk sections
        const selectedData = {
          model: {
            modelId: modelData.modelId,
            assessmentUuid: modelData.assessmentUuid,
            modelName: modelData.modelName,
            risks: modelData.sections[sectionName],
            sectionName
          }
        };
        setSelectedModel(selectedData);
      } else {
        console.error("Selected section not found within the model");
      }
    } else {
      console.error("Model not found");
    }
  };
  



const handleSelectSecurityRisk = (category, modelUuid) => {
  const modelDetails = models[modelUuid];
  const risks = securityReportData.dataByCategory[category]?.filter(risk => risk.modelUuid === modelUuid);

  if (!risks.length) {
    console.error("No risks found for this model in the selected category.");
    return;
  }

  const securitySelectedModel = {
    model: {
      modelId: modelUuid,
      modelName: modelDetails.modelName,
      risks,
      sectionName: 'security'
    }
  };

  setSelectedModel(securitySelectedModel);
  console.log("Selected model for security risk:", securitySelectedModel);
 console.log("SectionName set to:", securitySelectedModel.model.sectionName);
};

  
  
  const toggleView = () => {
    setViewType(prevType => (prevType === 'sectionView' ? 'modelView' : 'sectionView'));
  };

  const toggleExpanded = (key) => {
    setExpandedSection(prev => ({ ...prev, [key]: !prev[key] }));
  };
  const calculateRiskCounts = (models) => {
    const counts = {
      critical: 0,
      high: 0,
      significant: 0,
      moderate: 0,
      low: 0,
      negligible: 0,
      none: 0 // For risks without a defined priority
    };
    
    Object.values(models).forEach(model => {
      model.risks.forEach(risk => {
        counts[risk.priority] = (counts[risk.priority] || 0) + 1;
      });
    });
  
    return counts;
  };


    const toTitleCase = (str) => {
      // Split the string into words and handle camelCase by inserting spaces before capital letters
      return str
        .replace(/([A-Z])/g, ' $1') // Adds space before capital letters
        .replace(/([A-Z]+)/g, (match) => match.toUpperCase()) // Ensure each word is capitalized
        .trim()
        .split(/\s+/) // Split based on whitespace
        .map(word => word.charAt(0).toUpperCase() + word.substr(1).toLowerCase())
        .join(' '); // Join words back with space
    };

  
  const SectionViewTable = () => (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontWeight: 'bold' }}>Risk Source</TableCell>
            <TableCell sx={{ fontWeight: 'bold' }}>Risks By Priority</TableCell>
            <TableCell sx={{ fontWeight: 'bold' }}>Affected Models</TableCell>
            
          </TableRow>
        </TableHead>
        <TableBody>
          {/* Existing risk sections */}
          {Object.entries(reportData).map(([sectionName, sectionData]) => {
             const riskCounts = calculateRiskCounts(sectionData.models);
             return (
            <React.Fragment key={sectionName}>
              <TableRow>
                <TableCell >
                  <IconButton onClick={() => setExpandedSection(prev => ({ ...prev, [sectionName]: !prev[sectionName] }))}>
                    {expandedSection[sectionName] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </IconButton>
                  {toTitleCase(sectionName)}
                </TableCell>
                <TableCell>
                  <RiskCounters riskCounts={riskCounts} />
                </TableCell>
                <TableCell>{Object.keys(sectionData.models).length}</TableCell>
              </TableRow>
              {expandedSection[sectionName] && (
              <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={3}>
                  <Table size="small" style={{ width: '100%' }}>
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ fontWeight: 'bold' }}>Model Name</TableCell>
                        <TableCell sx={{ fontWeight: 'bold' }}>Risks</TableCell>
                        <TableCell sx={{ fontWeight: 'bold' }}>Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {Object.entries(sectionData.models).map(([modelName, modelData]) => (
                        <TableRow key={modelName}>
                          <TableCell>{modelName}</TableCell>
                          <TableCell>{modelData.risks.length}</TableCell>
                          <TableCell>
                            <Tooltip title="Show Risks & Controls">
                              <IconButton onClick={() => setSelectedModel({ model: modelData, sectionName })}>
                                <AnalyticsOutlinedIcon />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableCell>
              </TableRow>
            )}
          </React.Fragment>
          );
        })}
        
        
        <TableRow>
          <TableCell sx={{ fontWeight: 'bold' }} onClick={() => setExpandedSection(prev => ({ ...prev, security: !prev.security }))}>
            <IconButton>
              {expandedSection.security ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
            Security Risks
          </TableCell>
          <TableCell>{Object.entries(securityReportData.dataByModel).length}</TableCell>
        <TableCell>{Object.values(securityReportData.dataByModel).reduce((acc, model) => acc + model.risks.length, 0)}</TableCell>
        </TableRow>
        {expandedSection.security && (
          <Collapse in={expandedSection.security} timeout="auto" unmountOnExit>
            {["confidentiality", "integrity", "availability"].map((category) => (
              <React.Fragment key={category}>
                <TableRow>
                  <TableCell>
                    <IconButton onClick={() => setExpandedSection(prev => ({ ...prev, [category]: !prev[category] }))}>
                      {expandedSection[category] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </IconButton>
                    {toTitleCase(category)} Risks
                  </TableCell>
                  <TableCell colSpan={2}>Click to view models</TableCell>
                </TableRow>
                {expandedSection[category] && (
                  <Collapse in={expandedSection[category]} timeout="auto" unmountOnExit>
                    {Object.entries(securityReportData.dataByModel).map(([modelUuid, modelDetails]) => {
                      const risksInCategory = modelDetails.risks.filter(risk => risk.category.toLowerCase() === category);
                      if (risksInCategory.length > 0) {
                        return (
                          <TableRow key={modelUuid}>
                             <TableCell>{models[modelUuid]?.modelName || "Unknown Model"}</TableCell>
                            <TableCell>{risksInCategory.length}</TableCell>
                            <TableCell>
                              <Tooltip title="Show Security Risks & Controls">
                                <IconButton onClick={() => handleSelectSecurityRisk(category, modelUuid)}>
                                  <AnalyticsOutlinedIcon />
                                </IconButton>
                              </Tooltip>
                            </TableCell>
                          </TableRow>
                        );
                      }
                      return null;
                    })}
                  </Collapse>
                )}
              </React.Fragment>
            ))}
          </Collapse>
        )}
      </TableBody>
    </Table>
  </TableContainer>
);
  
  const ModelViewTable = () => (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontWeight: 'bold' }}>Model Name</TableCell>
            <TableCell sx={{ fontWeight: 'bold' }}>Assessment Status</TableCell>
            <TableCell sx={{ fontWeight: 'bold' }}>Total Risks</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.entries(modelReportData).map(([modelName, modelData]) => {
            const modelUuid=modelData.modelId;
            const securityRisks = securityReportData.dataByModel[modelUuid]?.risks || [];
            //console.log(`${modelName} Security Risks:`, securityRisks);

            return (
              <React.Fragment key={modelName}>
                <TableRow>
                  <TableCell>
                    <IconButton onClick={() => toggleExpanded(modelName)}>
                      {expandedSection[modelName] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </IconButton>
                    {modelName}
                  </TableCell>
                  <TableCell>{modelData.assessmentStatus}</TableCell>
                  <TableCell>{Object.values(modelData.sections).reduce((acc, risks) => acc + risks.length, 0) + securityRisks.length}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={3}>
                    <Collapse in={expandedSection[modelName]} timeout="auto" unmountOnExit>
                      <Table size="small">
                        <TableHead>
                          <TableRow>
                            <TableCell style={{ fontWeight: 'bold' }}>Risk Source</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }}>Risks</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }}>View Details</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {Object.entries(modelData.sections).map(([sectionName, risks]) => (
                            <TableRow key={sectionName}>
                              <TableCell component="th" scope="row">{toTitleCase(sectionName)}</TableCell>
                              <TableCell>{risks.length}</TableCell>
                              <TableCell>
                                <Tooltip title="Show Risks & Controls">
                                  <IconButton onClick={() => handleSelectModel(modelName, sectionName)}>
                                    <AnalyticsOutlinedIcon />
                                  </IconButton>
                                </Tooltip>
                              </TableCell>
                            </TableRow>
                          ))}
                          {securityRisks.length > 0 && (
                            <TableRow key="Security">
                              <TableCell component="th" scope="row">Security</TableCell>
                              <TableCell>{securityRisks.length}</TableCell>
                              <TableCell>
                                <Tooltip title="Show Security Risks & Controls">
                                  <IconButton onClick={() => handleSelectModel(modelName, "security")}>
                                    <AnalyticsOutlinedIcon />
                                  </IconButton>
                                </Tooltip>
                              </TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </Collapse>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
  
console.log("Current model selection:", selectedModel);
console.log("Is 'security' section:", selectedModel?.model.sectionName === 'security');

return (
  <>
    <Paper sx={{ p: 2, mb: 2 }}>
    <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box>
          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Risk Landscape</Typography>
        </Box>
        {isLoading && (
          <>
            <Box sx={{ mr: 2 }}>
              {loadingMessage && <Typography variant="caption">{loadingMessage}</Typography>}
            </Box>
            <Box>
              <CircularProgress size={24} />
            </Box>
          </>
        )}
      </Box>
      
      <FormControl component="fieldset">
        <RadioGroup row aria-label="view type" name="viewType" value={viewType} onChange={(e) => {
            toggleView(e.target.value); // Ensure view type is toggled
            setSelectedModel(null); // Optionally reset selectedModel to allow view type switch
        }}>
          <FormControlLabel value="modelView" control={<Radio />} label="Risks By Model" />
          <FormControlLabel value="sectionView" control={<Radio />} label="Risks By Source" />
        </RadioGroup>
      </FormControl>
    </Paper>
    {!selectedModel ? (
      viewType === 'sectionView' ? <SectionViewTable reportData={reportData} /> : <ModelViewTable modelReportData={modelReportData} />
    ) : (
      selectedModel.model.sectionName === 'security' ? <SecurityRisksReport model={selectedModel.model} /> : <RisksBySource model={selectedModel.model}  sectionName={selectedModel.sectionName ? selectedModel.sectionName : selectedModel.model.sectionName} />
    )}
  </>
);


}
export default RiskLandscape;
