
import React from 'react';
import { CircularProgress } from '@mui/material';
import SmartToyOutlinedIcon from '@mui/icons-material/SmartToyOutlined';

const IconWithProgress = ({ onClick, isLoading }) => {
  const iconStyle = {
    color: 'secondary.light', // Adjust this color to make the icon visible against the spinner
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    fontSize: '1rem' // Adjust size as needed
  };

  if (!isLoading) {
    return null;  // Don't render anything if not loading
  }

  return (
    <div style={{ position: 'relative', display: 'inline-flex', cursor: onClick ? 'pointer' : 'default' }} onClick={onClick}>
      <CircularProgress
        size={24}
        thickness={5}
        sx={{
          color: 'primary.main', // You can adjust the color to fit your design
        }}
      />
      <SmartToyOutlinedIcon style={iconStyle} />
    </div>
  );
};

export default IconWithProgress;
