
import React from 'react';
import { Grid, List, ListItemText, Divider, ListItemButton, Typography } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom'; // Import useLocation
import { styled } from '@mui/material/styles';

const SideMenuContainer = styled(Grid)({
  background: '#42424a', // dark indigo
  minHeight: 'calc(100vh - 60px)',
  color: '#1B1B1B', // light gray
});

// Adjust CustomListItemButton to accept and use the 'selected' prop
const CustomListItemButton = styled(ListItemButton)(({ theme, selected }) => ({
  color: '#ECECEC',  // light gray
  backgroundColor: selected ? 'rgba(255, 255, 255, 0.2)' : 'transparent', // Highlight if selected
  '&:hover': {
    background: 'rgba(255, 255, 255, 0.1)', // Gentle white overlay on hover
    transition: '0.3s',
  },
}));

function SideMenu() {
  const navigate = useNavigate();
  const location = useLocation(); // Use the useLocation hook here

  return (
    <SideMenuContainer>
      <Typography variant="h6" color='#cacacf' style={{ paddingLeft: '10px', paddingTop: '10px' }}>
      </Typography>
      <Typography variant="subtitle1" color='#cacacf' style={{ paddingLeft: '12px' }}>
          The AI Risk Co-Pilot
      </Typography>
      <Divider sx={{ marginY: 2, backgroundColor: 'rgba(255, 255, 255, 0.1)' }} />
      <List component="nav">
        <CustomListItemButton
          selected={location.pathname === '/'}
          onClick={() => navigate('/')}
        >
          <ListItemText primary="Model Inventory" />
        </CustomListItemButton>

        <CustomListItemButton
          selected={location.pathname === '/risk-landscape'}
          onClick={() => navigate('/risk-landscape')}
          
        >
          <ListItemText primary="Risk Landscape" />
        </CustomListItemButton>


        <CustomListItemButton
          selected={location.pathname === '/risk-themes'}
          onClick={() => navigate('/risk-themes')}
        >
          <ListItemText primary="Risk Themes" />
        </CustomListItemButton>

        <CustomListItemButton
          selected={location.pathname === '/audits'}
          onClick={() => navigate('/audits')}
        >
          <ListItemText primary="Conformity Assessments" />
        </CustomListItemButton>
      </List>
    </SideMenuContainer>
  );
}

export default SideMenu;
