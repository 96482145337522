import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import MobileStepper from '@mui/material/MobileStepper';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';

export default function MasterStepper({ steps, activeStep, handleNext, handleBack }) {
  const theme = useTheme();

  const getNextStepLabel = () => {
    return activeStep < steps.length - 1 ? formatLabel(steps[activeStep + 1]) : "Finish";
  };
  
  const getBackStepLabel = () => {
    return activeStep > 0 ? formatLabel(steps[activeStep - 1]) : "Start";
  };
  const formatLabel = (label) => {
    // Properly formats camelCase to Title Case for display
    return label
      .replace(/([A-Z])/g, ' $1')  // Inserts space before each uppercase letter
      .replace(/^./, str => str.toUpperCase())  // Capitalizes the first letter of the string
      .trim();
  };

  return (
    <MobileStepper
      variant="dots"
      steps={steps.length}
      position="static"
      activeStep={activeStep}
      sx={{ maxWidth: 400, flexGrow: 1 }}
      nextButton={
        <Button size="small" onClick={handleNext} disabled={activeStep === steps.length - 1}>
          {getNextStepLabel()}
          {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </Button>
      }
      backButton={
        <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
          {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
          {getBackStepLabel()}
        </Button>
      }
    />
  );
}
