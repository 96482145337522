import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { useParams, useNavigate } from 'react-router-dom';

function ModelDetailsModal() {
  const { modelId } = useParams();  // Get modelId from route params
  const [modelCard, setModelCard] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchModelDetails = async () => {
      try {
        const response = await fetch(`/.netlify/functions/FetchModelDetails?model_uuid=${modelId}`);
        const result = await response.json();

        if (result.success) {
          console.log('fetchmodalresults', result.data);
          setModelCard(result.data.modelCards);
        } else {
          console.error('Error fetching model details:', result.error);
        }
      } catch (error) {
        console.error('Error fetching model details:', error);
      }
    };

    if (modelId) {
      fetchModelDetails();
    }
  }, [modelId]);

  const closeModal = () => {
    setModelCard(null);
    navigate('/');  // Navigate back to the base path or another suitable path
  };

  const renderTable = (data, title) => (
    <div>
      <h3>{title}</h3>
      <table>
        <tbody>
          {Object.entries(data).map(([key, value]) => (
            <tr key={key}>
              <td>{key}</td>
              <td>{value}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );

  return (
    <Modal isOpen={!!modelId} onRequestClose={closeModal}>  {/* Display modal based on presence of modelId */}
      {modelCard && (
        <div>
          <h2>Model Card</h2>
          {renderTable(modelCard, 'Model Card')}
        </div>
      )}
      <button onClick={closeModal}>Close</button>
    </Modal>
  );
}

export default ModelDetailsModal;
