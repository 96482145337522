import { useState, useCallback } from 'react';

const useSaveOperation = () => {
  const [isSaving, setIsSaving] = useState(false);
  const [isSaved, setIsSaved] = useState(false);

  const handleSaveOperation = useCallback(async (saveFunction) => {
    setIsSaving(true);
    
    try {
      // Start the operation
      const result = await saveFunction();
      
      // Artificial delay to ensure `isSaving` is visible for at least 800ms
      await new Promise(resolve => setTimeout(resolve, 800));
      
      setIsSaving(false);
      setIsSaved(true);
      
      // Optionally reset isSaved after some time
      setTimeout(() => setIsSaved(false), 2000);
      
      return result;
    } catch (error) {
      console.error('Failed to save:', error);
      setIsSaving(false);
      throw error;
    }
  }, []);

  return { isSaving, isSaved, handleSaveOperation };
};

export default useSaveOperation;
